import React from "react";
import "../dashboard.scss";
import CaptureRate from "components/captureRate";
import ChurnRate from "components/churnRate";
import ChurnRateByWashPackageTable from "components/churnRateByWashPackageTable";
import HistoricalChurnRateChart from "../../components/historicalChurnRateChart";
import HistoricalCaptureRateChart from "../../components/historicalCaptureRateChart/historicalCaptureRateChart";
import CurrentMonthChurnPaceTable from "../../components/currentMonthChurnPaceTable/currentMonthChurnPaceTable";

class Dashboard extends React.Component {
  render() {
    return (
      <div className="dashboard-outer">
        <div className="dashboard-inner">
          <div className="dashboard-item-3 stacked-outer">
            <div className="stacked-inner">
              <div className="dashboard-item-2">
                <CaptureRate />
              </div>
              <div className="dashboard-item-2">
                <ChurnRate />
              </div>
            </div>
          </div>
          <div className="dashboard-item-3">
            <CurrentMonthChurnPaceTable />
          </div>
        </div>
        <div className="dashboard-inner">
          <div className="dashboard-item-3">
            <HistoricalChurnRateChart />
          </div>
          <div className="dashboard-item-3">
            <HistoricalCaptureRateChart />
          </div>
        </div>
        <div className="dashboard-inner">
          <div className="dashboard-item">
            <ChurnRateByWashPackageTable />
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
