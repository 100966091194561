export const ACTIONS = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  LOGIN_CALLBACK: "LOGIN_CALLBACK",
  REQUEST_LOGIN_CALLBACK: "REQUEST_LOGIN_CALLBACK",
  SET_USER: "SET_USER",
  INIT: "INIT",
};

/*
 * action creators
 */
export function login() {
  return {
    type: ACTIONS.LOGIN,
    payload: undefined,
    meta: {},
  };
}
export function logout() {
  return {
    type: ACTIONS.LOGOUT,
    payload: undefined,
    meta: {},
  };
}
export function loginCallback(callback) {
  return {
    type: ACTIONS.LOGIN_CALLBACK,
    payload: callback,
    meta: {},
  };
}
export function requestLoginCallback() {
  return {
    type: ACTIONS.REQUEST_LOGIN_CALLBACK,
    payload: undefined,
    meta: {},
  };
}
export function setUser(user) {
  return {
    type: ACTIONS.SET_USER,
    payload: user,
    meta: {},
  };
}
export function init() {
  return {
    type: ACTIONS.INIT,
    payload: undefined,
    meta: {},
  };
}
