import { takeLatest, put, select } from "redux-saga/effects";
import {
  selectAuth,
  checkLoggedIn,
  generateSelectDataState,
  generateSelectedSiteIds,
} from "utils/utils";
import { actions, actionCreators } from "./reducers";
import { DepositsAPI } from "../../api";
import moment from "moment";
import { getAPIOptionsFromDataState } from "utils/filterUtils";

function mapChart(totalDepositsInterval) {
  const translateDate = deposit => {
    const dateTokens = deposit.date.split("-");
    const year = parseInt(dateTokens[0], 10);
    const month = parseInt(dateTokens[1], 10) - 1;

    // Default to day to 1
    // period_unit = 'month_full' doesn't return the days.
    // example: '2019-08'
    let day = 1;
    if (dateTokens[2]) {
      day = parseInt(dateTokens[2], 10);
    }

    deposit.date = new Date(year, month, day, 0, 0, 0);
  };

  totalDepositsInterval.current.forEach(translateDate);
  totalDepositsInterval.past.forEach(translateDate);

  return totalDepositsInterval;
}

function* requestData(action) {
  // check logged in
  const auth = yield select(selectAuth);
  const loggedIn = checkLoggedIn(auth);

  if (loggedIn) {
    try {
      const dataState = yield select(
        generateSelectDataState("totalDepositsInterval")
      );
      const selectedSiteIds = yield select(generateSelectedSiteIds());
      const options = getAPIOptionsFromDataState(dataState, selectedSiteIds);

      if (!options.hasOwnProperty("end_date")) {
        options["end_date"] = moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD");
      }

      const totalDepositsInterval = mapChart(
        yield DepositsAPI.getTotalDeposits(auth.access_token, {
          type: "interval",
          ...options,
        })
      );
      yield put(actionCreators.dataLoaded(totalDepositsInterval));
    } catch (e) {
      yield put(actionCreators.dataFailed(e.toString()));
    }
  }
}

function* onSetFilterId(action) {
  yield put(actionCreators.requestData());
}

function* requestDataSaga() {
  yield takeLatest(actions.REQUEST_DATA, requestData);
  yield takeLatest(actions.SET_FILTER_ID, onSetFilterId);
}

export const SAGAS = [requestDataSaga()];
