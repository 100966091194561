import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { checkLoggedIn, checkIsStaff } from "utils/utils";
import Header from "../header";
import Sidebar from "../sidebar";

/**
 *
 * @param {object} props
 * @param {React.Component} props.component
 * @param {boolean} props.loggedIn
 * @param {boolean} [props.staffOnly]
 * @param {boolean} [props.isStaff]
 */
function PrivateRoute({
  component: Component,
  loggedIn,
  isStaff,
  staffOnly,
  ...rest
}) {
  function showComponent() {
    if (!loggedIn) {
      return false;
    }

    if (staffOnly === true) {
      return isStaff === true;
    } else {
      return true;
    }
  }

  return (
    <Route
      {...rest}
      render={props => {
        return showComponent() ? (
          <>
            <div className="left-sidebar">
              <Sidebar />
            </div>
            <div className="main-content">
              <Header />
              <Component {...props} />
            </div>
          </>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        );
      }}
    />
  );
}

export default connect(state => ({
  loggedIn: checkLoggedIn(state.auth.auth),
  isStaff: checkIsStaff(state.auth.user),
}))(PrivateRoute);
