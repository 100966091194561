import { createDataActions } from "utils/storeUtils";
import * as d3 from "d3";

/**
 * @typedef {import("../../utils/storeUtils").FilterConfigOption} FilterConfigOption
 */

/**
 * @type {FilterConfigOption[]}
 */
const CHURN_RATE_INTERVAL_FILTERS = [
  {
    title: "Last 7 Days",
    options: {
      period: 7,
      period_unit: "day",
    },
    xTickFormat: d3.timeFormat("%m/%d/%y"),
    xTicksInterval: d3.timeDay.filter(d => d3.timeDay.count(0, d) % 1 === 0),
  },
  {
    title: "Last 30 Days",
    options: {
      period: 30,
      period_unit: "day",
    },
    xTickFormat: d3.timeFormat("%m/%d/%y"),
    xTicksInterval: d3.timeDay.filter(d => d3.timeDay.count(0, d) % 7 === 0),
  },
];

export const { actions, actionCreators, reducer } = createDataActions(
  "churn_rate_interval",
  CHURN_RATE_INTERVAL_FILTERS
);
