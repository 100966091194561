import React from "react";
import "../dashboard.scss";
import "./customerNotifications.scss";
import { connect } from "react-redux";
import CustomerNotificationsList from "../../components/customerNotificationsList";

function CustomerNotifications({ access_token }) {
  return (
    <div className="dashboard-outer customer-notifications-page">
      <div className="dashboard-inner">
        <CustomerNotificationsList access_token={access_token} />
      </div>
    </div>
  );
}

export default connect(function(state) {
  return {
    access_token: state.auth.auth.access_token,
  };
})(CustomerNotifications);
