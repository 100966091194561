import { fetchAndDispatch } from "../utils/apiUtils";
/**
 *
 * @param {string} accessToken Auth0 Access Token
 */
export async function getSites(accessToken) {
  const data = await fetchAndDispatch(
    `${process.env.REACT_APP_API_URL}/sites/`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return data;
}
