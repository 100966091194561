import { fetchAndDispatch } from "../utils/apiUtils";

/**
 *
 * @typedef {object} AlertProfileLocation
 * @property {number} id
 * @property {number} site_sitewatch_id
 * @property {number} alert_profile
 */

/**
 *
 * @typedef {object} NewAlertProfileLocation
 * @property {number} site_sitewatch_id
 * @property {number} alert_profile
 */

/**
 *
 * @typedef {object} AlertProfileFrequency
 * @property {number} id
 * @property {string} frequency
 * @property {number} alert_profile
 */

/**
 *
 * @typedef {object} NewAlertProfileFrequency
 * @property {string} frequency
 * @property {number} alert_profile
 */

/**
 *
 * @typedef {object} AlertProfile
 * @property {number} id
 * @property {boolean} do_all_locations
 * @property {boolean} do_text
 * @property {boolean} do_email
 * @property {number} profile profile id
 */

/**
 *
 * @typedef {object} NewAlertProfile
 * @property {boolean} do_all_locations
 * @property {boolean} do_text
 * @property {boolean} do_email
 * @property {number} profile profile id
 */

/**
 *
 * @typedef {object} Profile
 * @property {number} id
 * @property {string} first_name
 * @property {string} last_name
 * @property {string} email
 * @property {string} phone_number
 * @property {AlertProfile} [alert_profile]
 */

/**
 *
 * @typedef {object} NewProfile
 * @property {string} first_name
 * @property {string} last_name
 * @property {string} email
 * @property {string} phone_number
 */

/**
 *
 * @param {string} accessToken Auth0 Access Token
 * @returns {Promise<Profile[]>}
 */
export async function getProfiles(accessToken) {
  const data = await fetchAndDispatch(
    `${process.env.REACT_APP_API_URL}/profiles/`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return data;
}

/**
 *
 * @param {string} accessToken Auth0 Access Token
 * @param {integer} profileId Profile id
 * @returns {Promise<Profile[]>}
 */
export async function getProfile(accessToken, profileId) {
  const data = await fetchAndDispatch(
    `${process.env.REACT_APP_API_URL}/profiles/${profileId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return data;
}

/**
 *
 * @param {string} accessToken Auth0 Access Token
 * @param {NewProfile} profile
 * @returns {Promise<Profile?>}
 */
export async function addProfile(accessToken, profile) {
  const data = await fetchAndDispatch(
    `${process.env.REACT_APP_API_URL}/profiles/`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(profile),
    }
  );
  return data;
}

/**
 *
 * @param {string} accessToken Auth0 Access Token
 * @param {Profile} profile
 * @returns {Promise<Profile?>}
 */
export async function updateProfile(accessToken, profile) {
  const data = await fetchAndDispatch(
    `${process.env.REACT_APP_API_URL}/profiles/${profile.id}/`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(profile),
    }
  );
  return data;
}

/**
 *
 * @param {string} accessToken Auth0 Access Token
 * @param {number} profile_id
 * @returns {Promise<Boolean>}
 */
export async function deleteProfile(accessToken, profile_id) {
  const data = await fetchAndDispatch(
    `${process.env.REACT_APP_API_URL}/profiles/${profile_id}/`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return data;
}

/**
 *
 * @param {string} accessToken Auth0 Access Token
 * @param {NewAlertProfile} alert_profile
 * @returns {Promise<AlertProfile?>}
 */
export async function addAlertProfile(accessToken, alert_profile) {
  const data = await fetchAndDispatch(
    `${process.env.REACT_APP_API_URL}/alertprofiles/`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(alert_profile),
    }
  );

  return data;
}

/**
 *
 * @param {string} accessToken Auth0 Access Token
 * @param {number} alert_profile_id
 * @returns {Promise<boolean>}
 */
export async function deleteAlertProfile(accessToken, alert_profile_id) {
  const data = await fetchAndDispatch(
    `${process.env.REACT_APP_API_URL}/alertprofiles/${alert_profile_id}/`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return data;
}

/**
 *
 * @param {string} accessToken Auth0 Access Token
 * @param {NewAlertProfileLocation} alert_profile_location
 * @returns {Promise<AlertProfileLocation?>}
 */
export async function addAlertProfileLocation(
  accessToken,
  alert_profile_location
) {
  const data = await fetchAndDispatch(
    `${process.env.REACT_APP_API_URL}/alertprofilelocations/`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(alert_profile_location),
    }
  );

  return data;
}

/**
 *
 * @param {string} accessToken Auth0 Access Token
 * @param {NewAlertProfileFrequency} alert_profile_frequency
 * @returns {Promise<AlertProfileFrequency?>}
 */
export async function addAlertProfileFrequency(
  accessToken,
  alert_profile_frequency
) {
  const data = await fetchAndDispatch(
    `${process.env.REACT_APP_API_URL}/alertprofilefrequencies/`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(alert_profile_frequency),
    }
  );

  return data;
}
