import React from "react";
import { connect } from "react-redux";
import { requestLoginCallback } from "store/auth";

class LoginCallbackContainerComponent extends React.Component {
  componentDidMount() {
    this.props.requestLoginCallback();
  }

  render() {
    return <div>Loading...</div>;
  }
}

export default connect(undefined, dispatch => ({
  requestLoginCallback: () => dispatch(requestLoginCallback()),
}))(LoginCallbackContainerComponent);
