import React from "react";
import { connect } from "react-redux";
import "./changelog.scss";

function ChangeLog({ access_token }) {
  return (
    <div className="dashboard-outer changelog-page-container">
      <div className="dashboard-inner">
        <h1>Changelog</h1>
        <div className="changelog-version-container">
          <div className="changelog-version">
            <h2 className="changelog-version-title">v7.3.0</h2>
            <div className="changelog-date">08-02-22</div>
          </div>
          <div className="changelog-description">
            <div className="changelog-section-title">Features</div>
            <ul>
              <li>Added performance improvements to Churn Burner.</li>
            </ul>
          </div>
          <hr />
        </div>
        <div className="changelog-version-container">
          <div className="changelog-version">
            <h2 className="changelog-version-title">v7.2.0</h2>
            <div className="changelog-date">06-07-22</div>
          </div>
          <div className="changelog-description">
            <div className="changelog-section-title">Features</div>
            <ul>
              <li>Changing DB query max limit to be 10 minutes.</li>
            </ul>
          </div>
          <hr />
        </div>
        <div className="changelog-version-container">
          <div className="changelog-version">
            <h2 className="changelog-version-title">v7.1.0</h2>
            <div className="changelog-date">06-05-22</div>
          </div>
          <div className="changelog-description">
            <div className="changelog-section-title">Features</div>
            <ul>
              <li>
                Added slow DB query logging and DB query timeout to prevent
                large data pulls from causing server performance problems.
              </li>
            </ul>
            <div className="changelog-section-title">Bugs</div>
            <ul>
              <li>Fix issue with oil change notifications.</li>
            </ul>
          </div>
          <hr />
        </div>
        <div className="changelog-version-container">
          <div className="changelog-version">
            <h2 className="changelog-version-title">v7.0.0</h2>
            <div className="changelog-date">05-16-22</div>
          </div>
          <div className="changelog-description">
            <div className="changelog-section-title">Features</div>
            <ul>
              <li>New design.</li>
              <li>
                Churn Burner page charts now obey the location dropdown at the
                top of the page.
              </li>
              <li>
                Churn Burner page charts have time filter dropdowns now, several
                charts also start at a shorter initial time interval to help
                with initial page load times.
              </li>
              <li>Improved performance of Churn Burner page charts.</li>
              <li>Added Loyalty webhook events for ARM Plan Sold/Recharge.</li>
            </ul>
          </div>
          <hr />
        </div>
        <div className="changelog-version-container">
          <div className="changelog-version">
            <h2 className="changelog-version-title">v6.14.0</h2>
            <div className="changelog-date">03-16-22</div>
          </div>
          <div className="changelog-description">
            <div className="changelog-section-title">Features</div>
            <ul>
              <li>
                Improving build on hosts to pull latest code more
                reliably/consistently.
              </li>
            </ul>
          </div>
          <hr />
        </div>
        <div className="changelog-version-container">
          <div className="changelog-version">
            <h2 className="changelog-version-title">v6.13.0</h2>
            <div className="changelog-date">03-16-22</div>
          </div>
          <div className="changelog-description">
            <div className="changelog-section-title">Features</div>
            <ul>
              <li>Improved performance of Churn Burner page charts.</li>
              <li>Added webhook system for integrating with Loyalty.</li>
            </ul>
            <div className="changelog-section-title">Bugs</div>
            <ul>
              <li>Fixed Recharge Total chart from being blank.</li>
            </ul>
          </div>
          <hr />
        </div>
        <div className="changelog-version-container">
          <div className="changelog-version">
            <h2 className="changelog-version-title">v6.12.0</h2>
            <div className="changelog-date">10-20-21</div>
          </div>
          <div className="changelog-description">
            <div className="changelog-section-title">Features</div>
            <ul>
              <li>
                Added option to leave SITEWATCH_SITES blank to run all sites.
              </li>
            </ul>
          </div>
          <hr />
        </div>
        <div className="changelog-version-container">
          <div className="changelog-version">
            <h2 className="changelog-version-title">v6.11.0</h2>
            <div className="changelog-date">10-19-21</div>
          </div>
          <div className="changelog-description">
            <div className="changelog-section-title">Features</div>
            <ul>
              <li>
                Added summary table into cacher to make many API calls much
                faster.
              </li>
              <li>
                Improved customer codes caching to be in smaller intervals to
                make more stable
              </li>
            </ul>
          </div>
          <hr />
        </div>
        <div className="changelog-version-container">
          <div className="changelog-version">
            <h2 className="changelog-version-title">v6.10.0</h2>
            <div className="changelog-date">10-03-21</div>
          </div>
          <div className="changelog-description">
            <div className="changelog-section-title">Features</div>
            <ul>
              <li>Enabling machine to machine API calls to stats dashboard.</li>
              <li>
                Added auto-tagger into code base to allow for scaling out where
                it is run.
              </li>
            </ul>
          </div>
          <hr />
        </div>
        <div className="changelog-version-container">
          <div className="changelog-version">
            <h2 className="changelog-version-title">v6.9.0</h2>
            <div className="changelog-date">06-01-21</div>
          </div>
          <div className="changelog-description">
            <div className="changelog-section-title">Features</div>
            <ul>
              <li>
                Add Churn Burner credit card update recharge recovery in
                dollars. This is the amount recovered from credit card updates
                (due to Churn Burner) multiplied by the recharge average amount
                over past 30 days.
              </li>
              <li>
                Added an info tooltip description to the rest of the charts that
                didn't have them. These pull in categories/items dynamically so
                you know exactly how metrics are calculated.
              </li>
              <li>
                Changed Churn Rate calcuation. Before it was Churn Rate =
                (discontinued + terminated) / (sold + recharged). Now Churn Rate
                = (discontinued + terminated) / (sold + recharged + discontinued
                - switched).
              </li>
            </ul>
            <div className="changelog-section-title">Bugs</div>
            <ul>
              <li>
                The Stacked checkbox on some charts had become hidden. Now it
                should be visible.
              </li>
            </ul>
          </div>
          <hr />
        </div>
        <div className="changelog-version-container">
          <div className="changelog-version">
            <h2 className="changelog-version-title">v6.8.0</h2>
            <div className="changelog-date">05-24-21</div>
          </div>
          <div className="changelog-description">
            <div className="changelog-section-title">Features</div>
            <ul>
              <li>
                Add Churn Burner credit card update percentage separate from
                overall recovery percentage.
              </li>
            </ul>
          </div>
          <hr />
        </div>
        <div className="changelog-version-container">
          <div className="changelog-version">
            <h2 className="changelog-version-title">v6.7.0</h2>
            <div className="changelog-date">05-23-21</div>
          </div>
          <div className="changelog-description">
            <div className="changelog-section-title">Features</div>
            <ul>
              <li>Sitewatch Active Campaign Sync/Auto-Tagger system</li>
              <li>
                Ability to exclude certain emails in cc/oil/tagging by putting
                in config json file
              </li>
            </ul>
          </div>
          <hr />
        </div>
        <div className="changelog-version-container">
          <div className="changelog-version">
            <h2 className="changelog-version-title">v6.6.0</h2>
            <div className="changelog-date">04-27-21</div>
          </div>
          <div className="changelog-description">
            <div className="changelog-section-title">Bugs</div>
            <ul>
              <li>Fix for cacher not caching current day</li>
            </ul>
          </div>
          <hr />
        </div>
      </div>
    </div>
  );
}

export default connect(function(state) {
  return {
    access_token: state.auth.auth.access_token,
  };
})(ChangeLog);
