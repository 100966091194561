import React, { Component } from "react";
import { PersistGate } from "redux-persist/integration/react";
import "normalize.css";
import "purecss/build/buttons.css";
import "purecss/build/menus.css";
import "purecss/build/forms.css";
import "purecss/build/grids-responsive.css";
import "purecss/build/tables.css";
import "./App.scss";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import PrivateRoute from "./components/privateRoute";
// import Footer from "./components/footer";
import {
  Sales,
  Members,
  Login,
  Callback,
  Packages,
  ChangePassword,
  ChurnAndCapture,
  Users,
  CustomReports,
  CreditCardStatus,
  CustomerNotifications,
  MonthlyKPI,
  Changelog,
} from "./pages";
import { Helmet } from "react-helmet";
import { pageTitle } from "./themes";
import Alerts from "./components/alerts";
import Reports from "./components/reports";

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <div className="dashboard-container">
              <Switch>
                <Redirect exact from="/" to="/sales" />
                <PrivateRoute path="/sales" exact component={Sales} />
                <PrivateRoute path="/members" exact component={Members} />
                <PrivateRoute path="/packages" exact component={Packages} />
                <PrivateRoute
                  path="/churn-and-capture"
                  exact
                  component={ChurnAndCapture}
                />
                <PrivateRoute
                  path="/alerts"
                  exact
                  component={Alerts}
                  staffOnly={true}
                />
                <PrivateRoute path="/reports" exact component={Reports} />
                <PrivateRoute
                  path="/users"
                  exact
                  component={Users}
                  staffOnly={true}
                />
                <PrivateRoute
                  path="/changepassword"
                  exact
                  component={ChangePassword}
                />
                <PrivateRoute
                  path="/custom-reports"
                  exact
                  component={CustomReports}
                />
                <PrivateRoute
                  path="/cc-status"
                  exact
                  component={CreditCardStatus}
                />
                <PrivateRoute
                  path="/customer_notifications"
                  exact
                  component={CustomerNotifications}
                />
                <PrivateRoute
                  path="/monthly-kpi"
                  exact
                  component={MonthlyKPI}
                />
                <PrivateRoute path="/changelog" exact component={Changelog} />
                {/* <PrivateRoute path="/dashboard" exact component={Dashboard} /> */}
                <Route path="/callback" exact component={Callback} />
                <Route path="/login" exact component={Login} />
              </Switch>
            </div>
          </Router>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
