import React from "react";
import SiteSelectedAndFiltered from "../siteSelectedAndFilter";
import TitleComponent from "../titleComponent";
import FilterSelect from "../filterSelect";
import PropTypes from "prop-types";
import "./singleContainerComponent.scss";

export class SingleContainerComponent extends SiteSelectedAndFiltered {
  render() {
    const {
      title,
      component: Component,
      componentData,
      filters,
      selectedFilterId,
      config,
    } = this.props;

    const { data, info } = componentData;

    const value = config.valueFormatter(data && data.value ? data.value : 0);
    const changeValue = data && data.change ? data.change : 0;
    const change = config.changeFormatter(changeValue);
    const SelectComponent = props => (
      <FilterSelect
        filters={filters}
        selectedFilterId={selectedFilterId}
        onChangeFilter={(id, dateRange, groupBy) => {
          super.onChangeFilter(id, dateRange, groupBy);
        }}
      />
    );

    return (
      <div className="single-container-component">
        <TitleComponent
          title={title}
          info={info}
          selectedFilterId={selectedFilterId}
          onClickTitle={this.requestData.bind(this)}
          selectComponent={<SelectComponent />}
        />

        <div className="">
          <h3 className="title">{componentData.valueChangeTitle}</h3>
          <div className="single-container-component-number value">{value}</div>
          <div
            className={`single-container-component-number change ${
              changeValue > 0 ? "positive" : "negative"
            }`}
          >
            {change}
          </div>
        </div>

        <Component
          filters={filters}
          selectedFilterId={selectedFilterId}
          config={config}
          {...componentData}
        />
      </div>
    );
  }
}
SingleContainerComponent.propTypes = {
  title: PropTypes.string.isRequired,
  config: PropTypes.object,
  component: PropTypes.func.isRequired,
  componentData: PropTypes.object,
  filters: PropTypes.array.isRequired,
  selectedFilterId: PropTypes.number.isRequired,
  setFilterId: PropTypes.func.isRequired,
};

export default SingleContainerComponent;
