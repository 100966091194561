import { stringifyQueryParams, fetchAndDispatch } from "../utils/apiUtils";
/**
 *
 * @param {string} accessToken Auth0 Access Token
 */
export async function getArmSalesLeaderboard(accessToken, options = {}) {
  const query = stringifyQueryParams(options);

  const data = await fetchAndDispatch(
    `${process.env.REACT_APP_API_URL}/armsalesleaderboard/?${query}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return data;
}
