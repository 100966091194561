import { takeLatest, put, select } from "redux-saga/effects";
import {
  selectAuth,
  checkLoggedIn,
  generateSelectDataState,
  generateSelectedSiteIds,
} from "utils/utils";
import { actions, actionCreators } from "./reducers";
import { ChurnRateAPI } from "../../api";
import { getAPIOptionsFromDataState } from "utils/filterUtils";

function* requestData(action) {
  // check logged in
  const auth = yield select(selectAuth);
  const loggedIn = checkLoggedIn(auth);

  if (loggedIn) {
    try {
      const dataState = yield select(generateSelectDataState("churnRate"));
      const selectedSiteIds = yield select(generateSelectedSiteIds());
      const options = getAPIOptionsFromDataState(dataState, selectedSiteIds);

      const churnRate = yield ChurnRateAPI.getChurnRate(auth.access_token, {
        type: "single",
        ...options,
      });
      yield put(
        actionCreators.dataLoaded({
          ...churnRate,
          data: churnRate.churn_rate,
        })
      );
    } catch (e) {
      yield put(actionCreators.dataFailed(e.toString()));
    }
  }
}

function* requestDataSaga() {
  yield takeLatest(actions.REQUEST_DATA, requestData);
}

function* onSetFilterId(action) {
  yield put(actionCreators.requestData());
}

function* setFilterSaga() {
  yield takeLatest(actions.SET_FILTER_ID, onSetFilterId);
}

export const SAGAS = [requestDataSaga(), setFilterSaga()];
