import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/browser";
const packageReleaseVersion = require("../package.json").version;

if (process.env.REACT_APP_SENTRY_DSN) {
  const releaseVersion = packageReleaseVersion
    ? `suds-dashboard@${packageReleaseVersion}`
    : `suds-dashboard@unknown`;
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: releaseVersion,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || "development",
  });
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
