import { takeLatest, put, select } from "redux-saga/effects";
import {
    selectAuth,
    checkLoggedIn,
    generateSelectDataState,
    generateSelectedSiteIds,
} from "utils/utils";
import { actions, actionCreators } from "./reducers";
import { CreditCardStatusListAPI } from "../../api";
import { getAPIOptionsFromDataState } from "utils/filterUtils";

function* requestData(action) {
    // check logged in
    const auth = yield select(selectAuth);
    const loggedIn = checkLoggedIn(auth);
    const dataState = yield select(
        generateSelectDataState("creditCardStatusListExpiring")
    );
    const selectedSiteIds = yield select(generateSelectedSiteIds());
    const options = getAPIOptionsFromDataState(dataState, selectedSiteIds);

    if (loggedIn) {
        try {

            const statusData = yield CreditCardStatusListAPI.getCreditCardStatusList(
                auth.access_token,
                {
                    ...options
                }
            );

            yield put(
                actionCreators.dataLoaded({
                    info: "Individuals in a specific problem status and when they were notified.  Table shows max of 50, use Export CSV to get full list.",
                    data: statusData,
                })
            );
        } catch (e) {
            yield put(actionCreators.dataFailed(e.toString()));
        }
    }
}

function* requestCSV(action) {
    // check logged in
    const auth = yield select(selectAuth);
    const loggedIn = checkLoggedIn(auth);
    const dataState = yield select(
        generateSelectDataState("creditCardStatusListExpiring")
    );
    const selectedSiteIds = yield select(generateSelectedSiteIds());
    const options = getAPIOptionsFromDataState(dataState, selectedSiteIds);

    if (loggedIn) {
        try {

            const statusData = yield CreditCardStatusListAPI.getCreditCardStatusListCSV(
                auth.access_token,
                {
                    ...options
                }
            );
        } catch (e) {
            yield put(actionCreators.dataFailed(e.toString()));
        }
    }
}

function* requestDataSaga() {
    yield takeLatest(actions.REQUEST_DATA, requestData);
}

function* requestCSVSaga() {
    yield takeLatest(actions.GENERATE_CSV, requestCSV);
}

function* onSetFilterId(action) {
    yield put(actionCreators.requestData());
}

function* setFilterSaga() {
    yield takeLatest(actions.SET_FILTER_ID, onSetFilterId);
}

export const SAGAS = [requestDataSaga(), setFilterSaga(), requestCSVSaga()];
