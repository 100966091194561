import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";

function InfoTooltip({ info }) {
  function renderInfoTooltip(props) {
    return <Tooltip {...props}>{info}</Tooltip>;
  }
  return (
    <div>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderInfoTooltip}
      >
        <span variant="success" className="chart-title-info">
          &#9432;
        </span>
      </OverlayTrigger>
    </div>
  );
}

export default InfoTooltip;
