import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./rankedTable.scss";
import { get } from "lodash";

/**
 * @typedef {object} SortConfigItem
 * @property {string} name
 * @property {string[]} path
 * @property {"asc" | "desc"} sort
 */

/**
 * @typedef {object} ColumnConfig
 * @property {string} name
 * @property {string[]} path
 * @property {boolean} sortable
 * @property {boolean} checked
 */

/**
 *
 * @param {Array} data
 * @param {SortConfigItem} [sort]
 */
function getSortedData(data, sort) {
  let sortedData = data;
  if (sort === undefined) {
  } else if (sort.sort === "asc") {
    sortedData = sortedData.sort((a, b) => {
      const av = get(a, sort.path);
      const bv = get(b, sort.path);
      if (av < bv) return -1;
      if (av > bv) return 1;

      return 0;
    });
  } else if (sort.sort === "desc") {
    sortedData = sortedData.sort((a, b) => {
      const av = get(a, sort.path);
      const bv = get(b, sort.path);
      if (av < bv) return 1;
      if (av > bv) return -1;

      return 0;
    });
  }

  return sortedData;
}

/**
 *
 * @param {ColumnConfig} col
 * @param {SortConfigItem} [sort]
 */
function toggleSort(col, sort) {
  if (!sort || sort.name !== col.name || sort.sort === undefined) {
    // Only one sort allowed at a time so we overwrite the sort object
    return {
      ...col,
      sort: "desc",
    };
  } else if (sort.sort === "desc") {
    return {
      ...col,
      sort: "asc",
    };
  } else {
    return {
      ...col,
      sort: undefined,
    };
  }
}

function RankedTableCore({ config, data, showRank = false }) {
  const [sort, setSort] = useState(undefined);
  const [sortedData, setSortedData] = useState([]);

  useEffect(() => {
    setSortedData(getSortedData(data, sort));
  }, [sort, data]);

  return (
    <table className="table">
      <thead>
        <tr>
          {showRank && (
            <th>
              <div>#</div>
            </th>
          )}
          {config.columns.map((col, i) => {
            let onClick = undefined;
            let cn = "";
            let sortArrow = "";

            if (col.sortable) {
              onClick = () => setSort(toggleSort(col, sort));
              cn = "sortable-column";

              if (sort && sort.name === col.name) {
                if (sort.sort === "asc") {
                  sortArrow = "▲";
                } else if (sort.sort === "desc") {
                  sortArrow = "▼";
                }
              }
            }
            return (
              <th className={cn} onClick={onClick} key={i}>
                {col.name} {sortArrow}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {sortedData &&
          sortedData.map((row, i) => (
            <tr key={get(row, config.rowIdPath)} className="body-row">
              {showRank && (
                <td>
                  <div>{i + 1}</div>
                </td>
              )}
              {config.columns.map((col, i) => {
                const value = get(row, col.path);
                return (
                  <td key={i}>
                    {col.checkmark && value && (
                      <div>
                        <span className="check-mark">&#10004;</span>
                      </div>
                    )}
                    {!col.checkmark &&
                      (col.formatter ? col.formatter(value) : value)}
                  </td>
                );
              })}
            </tr>
          ))}
      </tbody>
    </table>
  );
}

RankedTableCore.propTypes = {
  config: PropTypes.shape({
    columns: PropTypes.array.isRequired,
    /** array of column objects. Name of column,
     * path of key it references to the data,
     * and if it's sortable
     */
    rowIdPath: PropTypes.array.isRequired,
    /** The id of each row.
     * Usually set path to the id of each index of data object
     * */
    valuePath: PropTypes.array,
  }).isRequired,
  /**
   * shape of the overall data table. Defines columns and rows
   */
  data: PropTypes.array,
};

export default RankedTableCore;
