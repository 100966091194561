import React from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import "./lineChart.scss";
import { baseComponentPropTypes } from "utils/utils";
import TitleComponent from "../titleComponent";
import StackedCheckbox from "../stackedCheckbox";
import SiteSelectedAndFiltered from "../siteSelectedAndFilter";
import { GROUP_BY } from "utils/filterUtils";
import LineChartCore, { dateRangeToTimeInterval } from "./lineChartCore";

class LineChart extends SiteSelectedAndFiltered {
  state = { showAsStackedArea: false };

  lineChartViz = React.createRef();

  componentDidMount() {
    const options = this.props.filters[this.props.selectedFilterId].options;
    this.props.requestData(options);
  }

  getCurrentFilter() {
    const { filters, selectedFilterId, groupBy, dateRange } = this.props;
    const filter = { ...filters[selectedFilterId] };

    const groupByObj = GROUP_BY.hasOwnProperty(groupBy) && GROUP_BY[groupBy];

    if (
      filter.hasOwnProperty("custom") &&
      filter.custom === true &&
      groupByObj
    ) {
      // calculate xTickXXX props and add to filter
      if (groupByObj.hasOwnProperty("xTickFormat")) {
        filter["xTickFormat"] = groupByObj.xTickFormat;
      }
      if (groupByObj.hasOwnProperty("xTicksInterval")) {
        filter["xTicksInterval"] =
          groupByObj.id === "day"
            ? dateRangeToTimeInterval(dateRange.start_date, dateRange.end_date)
            : d3.timeMonth.filter(d => d3.timeMonth.count(0, d) % 1 === 0);
      }
      if (groupByObj.hasOwnProperty("timeInterval")) {
        filter["timeInterval"] = groupByObj.timeInterval;
      }
    }

    return filter;
  }

  getTimeInterval() {
    const currentFilter = this.getCurrentFilter();
    return currentFilter.hasOwnProperty("timeInterval")
      ? currentFilter.timeInterval
      : d3.timeDay;
  }

  onChangeShowAsStatckedArea() {
    this.setState(state => ({
      showAsStackedArea: !state.showAsStackedArea,
    }));
  }

  render() {
    const {
      title,
      filters,
      selectedFilterId,
      config,
      info,
      dateRange,
      groupBy,
      data,
      sites,
      groupKey = "site",
      loading,
    } = this.props;

    const currentFilter = this.getCurrentFilter();

    const xTicksInterval =
      currentFilter.xTicksInterval ||
      dateRangeToTimeInterval(dateRange.start_date, dateRange.end_date);

    return (
      <div className="line-chart-container-outer">
        <TitleComponent
          title={title}
          info={info}
          filters={filters}
          selectedFilterId={selectedFilterId}
          onChangeFilter={(id, dateRange, groupBy) => {
            return this.onChangeFilter(id, dateRange, groupBy);
          }}
          onClickTitle={this.requestData.bind(this)}
          dateRange={dateRange}
          groupBy={groupBy}
        >
          {config && config.canStack === true && (
            <StackedCheckbox
              value={this.state.showAsStackedArea}
              onChangeValue={v => this.onChangeShowAsStatckedArea(v)}
            />
          )}
        </TitleComponent>
        {data && (
          <LineChartCore
            data={data}
            loading={loading}
            stacked={this.state.showAsStackedArea}
            selectedGroupsIds={sites.selectedSiteIds}
            groupKey={groupKey}
            config={config}
            timeInterval={this.getTimeInterval()}
            xTicksInterval={xTicksInterval}
            filter={currentFilter}
          />
        )}
      </div>
    );
  }
}

LineChart.propTypes = {
  groupKey: PropTypes.string,
  config: PropTypes.shape({
    yFormatter: PropTypes.func,
    yTickArguments: PropTypes.array,
    canStack: PropTypes.bool,
  }).isRequired,
  ...baseComponentPropTypes,
};

export default LineChart;
