import React from "react";
import PropTypes from "prop-types";
import { baseComponentPropTypes } from "utils/utils";
import "./rankedTable.scss";
import TitleComponent from "../titleComponent";
import SiteSelectedAndFiltered from "../siteSelectedAndFilter";
import RankedTableCore from "./rankedTableCore";

class RankedTable extends SiteSelectedAndFiltered {
  render() {
    const { data, title, filters, selectedFilterId, config, info } = this.props;

    const showRank = config && config.showRank === true;

    return (
      <div className="table-container">
        <TitleComponent
          title={title}
          filters={filters}
          selectedFilterId={selectedFilterId}
          onChangeFilter={this.onChangeFilter.bind(this)}
          onClickTitle={this.requestData.bind(this)}
          info={info}
        />
        <RankedTableCore data={data} config={config} showRank={showRank} />
      </div>
    );
  }
}

RankedTable.propTypes = {
  config: PropTypes.shape({
    columns: PropTypes.array.isRequired,
    /** array of column objects. Name of column,
     * path of key it references to the data,
     * and if it's sortable
     */
    rowIdPath: PropTypes.array.isRequired,
    /** The id of each row.
     * Usually set path to the id of each index of data object
     * */
    valuePath: PropTypes.array,
  }).isRequired,
  /**
   * shape of the overall data table. Defines columns and rows
   */
  ...baseComponentPropTypes,
};

export default RankedTable;
