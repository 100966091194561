import { createDataActions } from "../../utils";

/**
 * @typedef {import("../../utils/storeUtils").FilterConfigOption} FilterConfigOption
 */

/**
 * @type {FilterConfigOption[]}
 */
const MONTHLY_KPI_FILTERS = [
  {
    title: "Last 24 Months",
    options: {
      period: 24,
      period_unit: "month_full",
    },
  },
  {
    title: "Last 2 Months",
    options: {
      period: 2,
      period_unit: "month_full",
    },
  },
];

export const { actions, actionCreators, reducer } = createDataActions(
  "monthly_kpi",
  MONTHLY_KPI_FILTERS
);
