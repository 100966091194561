import React, { useState, useEffect } from "react";
import * as ReportsAPI from "../../api/reports";
import { connect } from "react-redux";
import Icons from "./../icons";
import "./reports.scss";
import LoadingSpinner from "../loadingSpinner";

/**
 * @typedef {import("../../api/reports").Report} Report
 */
/**
 *
 * @param {object} props
 * @param {string} props.access_token
 */
function Reports({ access_token }) {
  let today = getTodayDate();

  // START COMPONENT STATE
  const [reportStart, setReportStart] = useState(today);
  const [reportEnd, setReportEnd] = useState(today);
  const [lastCached, setLastCached] = useState("");
  const [reportItems, setReportItems] = useState([]);
  const [loadingReport, setLoadingReport] = useState(false);
  const [sortSelection, setSortSelection] = useState({
    orderBy: "id",
    order: "asc",
  });
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [computedStart, setComputedStart] = useState(today);
  const [computedEnd, setComputedEnd] = useState(today);

  useEffect(() => {
    function sortTableByColumn() {
      if (reportItems.length > 0) {
        let value = sortSelection.orderBy;

        setReportItems(data =>
          [...data].sort((a, b) => {
            if (sortSelection.order === "asc") {
              return a[value] > b[value] ? 1 : -1;
            }
            return a[value] > b[value] ? -1 : 1;
          })
        );
      }
    }

    sortTableByColumn();
  }, [sortSelection, reportItems.length]);

  // END COMPONENT STATE

  let columnHeadings = [
    {
      name: "Location",
      value: "id",
      sortable: true,
    },
    {
      name: "Washes",
      value: "washes",
      sortable: true,
    },
    {
      name: "Redemptions",
      value: "redemptions",
      sortable: true,
    },
    {
      name: "Discounts",
      value: "discounts",
      sortable: true,
    },
    {
      name: "Sold",
      value: "sold",
      sortable: true,
    },
    {
      name: "Cancelled",
      value: "cancelled",
      sortable: true,
    },
    {
      name: "Cap rate",
      value: "cap_rate",
      sortable: true,
    },
    {
      name: "Revenue",
      value: "revenue",
      sortable: true,
    },
  ];

  async function getReport(startDate, endDate) {
    setLoadingReport(true);
    return ReportsAPI.getReport(access_token, {
      startDate,
      endDate,
    }).then(function(report) {
      // Add site id to object and convert to array
      let siteStats = Object.keys(report.transactions.sites).map(key => {
        report.transactions.sites[key].id = key;
        return report.transactions.sites[key];
      });
      setComputedStart(report.start_date);
      setComputedEnd(report.end_date);
      setLoadingReport(false);
      setReportItems(siteStats);
      console.log(siteStats);
      setLastCached(report.transactions.last_cached);
    });
  }

  function getTodayDate() {
    let today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    let strMonth = "";
    let strDay = "";

    if (month < 10) {
      strMonth = `0${month}`;
    }

    if (day < 10) {
      strDay = `0${day}`;
    }

    return `${year}-${strMonth || month}-${strDay || day}`;
  }

  function setSortOrder(value) {
    // Effect hook will call sortTableByColumn after sort order is set
    if (sortSelection.orderBy === value) {
      if (sortSelection.order === "desc") {
        setSortSelection({
          ...sortSelection,
          order: "asc",
        });
      } else {
        setSortSelection({
          ...sortSelection,
          order: "desc",
        });
      }
    } else {
      setSortSelection({
        orderBy: value,
        order: "desc",
      });
    }
  }

  const handleSubmit = e => {
    e.preventDefault();
    getReport(reportStart, reportEnd);
  };

  const getCSV = async (startDate, endDate) => {
    setLoadingCSV(true);
    try {
      await ReportsAPI.getReportCSV(access_token, {
        startDate,
        endDate,
      });
    } catch (e) {
      console.log("Error getting report csv:", e);
    }
    setLoadingCSV(false);
  };

  const handleCSVButtonClick = e => {
    getCSV(reportStart, reportEnd);
  };

  return (
    <div className="reports-wrapper">
      <h1>Reports</h1>
      <div className="date-range-form">
        <form onSubmit={handleSubmit}>
          <label htmlFor="report-start">
            <span className="label">Select start date</span>
            <input
              type="date"
              id="report-start"
              name="report-start"
              defaultValue={reportStart}
              required
              onChange={e => setReportStart(e.target.value)}
            />
          </label>
          <span className="text-separator">to</span>
          <label htmlFor="report-end">
            <span className="label">Select end date</span>
            <input
              type="date"
              id="report-end"
              name="report-end"
              defaultValue={reportStart}
              min={reportStart}
              required
              onChange={e => setReportEnd(e.target.value)}
            />
          </label>
          <button
            type="submit"
            disabled={reportStart > reportEnd || loadingReport}
          >
            Generate report
          </button>
        </form>
      </div>
      {!loadingReport && lastCached && (
        <p className="date-range-form-note">
          <b>NOTE:</b> Data last cached: {lastCached}
          <br />
          <b>NOTE:</b> This page is based on your text/email alert setup, which
          factors in your "end of day" config. See computed times below.
          <br />
          <b>Actual Start:</b> {computedStart} <b>Actual End:</b> {computedEnd}
        </p>
      )}
      {loadingReport && <LoadingSpinner />}
      {!loadingReport && reportItems.length > 0 && (
        <>
          <div className="report-item-stats">
            <table>
              <thead>
                <tr>
                  {columnHeadings.map((col, i) => {
                    let onClick = undefined;
                    let cn = "";
                    let sortArrow = "";

                    if (col.sortable) {
                      onClick = () => setSortOrder(col.value);
                      cn = "sortable-column";

                      if (sortSelection.orderBy === col.value) {
                        if (sortSelection.order === "asc") {
                          sortArrow = "▲";
                        } else if (sortSelection.order === "desc") {
                          sortArrow = "▼";
                        }
                      }
                    }

                    return (
                      <th className={cn} onClick={onClick} key={i}>
                        {col.name} {sortArrow}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {reportItems.map(site => {
                  return (
                    <tr className="report-item" key={site.id}>
                      <td className="report-item-stat">{site.id}</td>
                      <td className="report-item-stat">
                        {site.washes.toLocaleString()}
                      </td>
                      <td className="report-item-stat">
                        {site.redemptions.toLocaleString()}
                      </td>
                      <td className="report-item-stat">
                        {site.discounts.toLocaleString()}
                      </td>
                      <td className="report-item-stat">
                        {site.sold.toLocaleString()}
                      </td>
                      <td className="report-item-stat">
                        {site.cancelled.toLocaleString()}
                      </td>
                      <td className="report-item-stat">
                        {(site.cap_rate * 100).toFixed(1)}%
                      </td>
                      <td className="report-item-stat">
                        {site.revenue.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="export-report-options">
            <button
              disabled={loadingCSV}
              onClick={handleCSVButtonClick}
              className="export-button"
            >
              <span>
                <Icons name="csv" />
              </span>
              Export Report (CSV)
            </button>
            <p className="export-report-options-note">
              <b>NOTE:</b> The exported version will break down the data per-day
            </p>
          </div>
        </>
      )}
    </div>
  );
}

export default connect(function(state) {
  return {
    access_token: state.auth.auth.access_token,
  };
})(Reports);
