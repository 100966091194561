import { fetchAndDispatch } from "../utils/apiUtils";

/**
 *
 * @typedef {object} Report
 * @property {string} start_date
 * @property {string} end_date
 * @property {object} transactions
 */

/**
 *
 * @param {string} accessToken Auth0 Access Token
 * @param {object} options
 * @param {string} options.startDate Report start date
 * @param {string} options.endDate Report end date
 * @returns {Promise<Report[]>}
 */
export async function getReport(accessToken, { startDate, endDate }) {
  const data = await fetchAndDispatch(
    `${process.env.REACT_APP_API_URL}/alert_reports/?start_date=${startDate}&end_date=${endDate}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return data;
}

/**
 * @typedef {object} Item
 * @property {number} id
 * @property {number} sitewatch_id
 * @property {string} name
 */

/**
 * @typedef {object} ItemReportCategory
 * @property {number} id
 * @property {Item[]} items
 * @property {number} sitewatch_id
 * @property {string} name
 */

/**
 *
 * @param {string} accessToken Auth0 Access Token
 * @returns {Promise<object[]>}
 */
export async function getItemCategories(accessToken) {
  const data = await fetchAndDispatch(
    `${process.env.REACT_APP_API_URL}/item-report-categories/`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return data;
}

function mapChart(sales) {
  // sort groups by code (name)
  const sortedDataBySite = sales.sort((a, b) =>
    a.group.name > b.group.name ? 1 : -1
  );
  return sortedDataBySite.map(group => {
    return {
      ...group,
      data: group.data
        .map(d => {
          const dateTokens = d.date.split("-");
          const year = parseInt(dateTokens[0], 10);
          const month = parseInt(dateTokens[1], 10) - 1;
          const day = dateTokens[2] ? parseInt(dateTokens[2], 10) : 1;
          return {
            ...d,
            date: new Date(year, month, day, 0, 0, 0),
          };
        })
        .sort((a, b) => {
          if (a.date < b.date) {
            return -1;
          } else if (a.date > b.date) {
            return 1;
          } else {
            return 0;
          }
        }),
    };
  });
}

/**
 * @typedef {Object} CustomerReportOptions
 * @property {string} startDate YYYY-MM-DD
 * @property {string} endDate YYYY-MM-DD
 * @property {"YYYY-MM-DD" | "YYYY-MM"} groupBy
 * @property {number[]} items: selectedCategoriesItems.map(s => s.name.id),
 * @property {number[]} categories
 * @property {"amount" | "quantity"} value: value.name.toLowerCase()
 * @property {boolean} [allsites]: true,
 * @property {number[]} [sites]
 * @property {"site" | "item"} series
 */

/**
 *
 * @param {string} accessToken Auth0 Access Token
 * @param {CustomerReportOptions} options
 * @returns {Promise<object[]>}
 */
export async function getCustomReport(accessToken, options) {
  const data = await fetchAndDispatch(
    `${process.env.REACT_APP_API_URL}/item-report-categories/custom-report/`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(options),
    }
  ).then(dataObj => {
    return {
      ...dataObj,
      data: mapChart(dataObj.data),
    };
  });

  return data;
}

// Got from https://blog.logrocket.com/programmatic-file-downloads-in-the-browser-9a5186298d5c/
export function downloadBlob(blob, filename) {
  // Create an object URL for the blob object
  const url = URL.createObjectURL(blob);

  // Create a new anchor element
  const a = document.createElement("a");

  // Set the href and download attributes for the anchor element
  // You can optionally set other attributes like `title`, etc
  // Especially, if the anchor element will be attached to the DOM
  a.href = url;
  a.download = filename || "download";

  // Click handler that releases the object URL after the element has been clicked
  // This is required for one-off downloads of the blob content
  // const clickHandler = function () {
  //   setTimeout(() => {
  //     // Release the object URL
  //     URL.revokeObjectURL(url);

  //     // Remove the event listener from the anchor element
  //     this.removeEventListener('click', clickHandler);

  //     // Remove the anchor element from the DOM
  //     (this.remove && (this.remove(), 1)) ||
  //       (this.parentNode && this.parentNode.removeChild(this));
  //   }, 150);
  // };

  // Add the click event listener on the anchor element
  // a.addEventListener('click', clickHandler, false);

  // Programmatically trigger a click on the anchor element
  // Useful if you want the download to happen automatically
  // Without attaching the anchor element to the DOM
  a.click();

  // Return the anchor element
  // Useful if you want a reference to the element
  // in order to attach it to the DOM or use it in some other way
  return a;
}

/**
 *
 * @param {string} accessToken Auth0 Access Token
 * @param {object} options
 * @returns {Promise<Report[]>}
 */
export async function getReportCSV(accessToken, { startDate, endDate }) {
  const data = await fetchAndDispatch(
    `${process.env.REACT_APP_API_URL}/alert_reports/?start_date=${startDate}&end_date=${endDate}`,
    {
      headers: {
        Accept: "text/csv",
        Authorization: `Bearer ${accessToken}`,
      },
    },
    {
      json: false,
    }
  );

  const blob = await data.blob();
  downloadBlob(blob, `report-${startDate}-${endDate}.csv`);
  return data;
}

/**
 *
 * @param {string} accessToken Auth0 Access Token
 * @param {CustomerReportOptions} options
 * @returns {Promise<any>}
 */
export async function getCustomReportCSV(accessToken, options) {
  const data = await fetchAndDispatch(
    `${process.env.REACT_APP_API_URL}/item-report-categories/custom-report/`,
    {
      method: "POST",
      headers: {
        Accept: "text/csv",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(options),
    },
    {
      json: false,
    }
  );

  const blob = await data.blob();
  downloadBlob(blob, `report-${options.startDate}-${options.endDate}.csv`);
  return data;
}
