import * as WashSalesAPI from "./washSales";
import * as ArmSalesAPI from "./armSales";
import * as ArmSalesLeaderboardAPI from "./armSalesLeaderboard";
import * as CaptureRateAPI from "./captureRate";
import * as ChurnRateAPI from "./churnRate";
import * as DepositsAPI from "./deposits";
import * as NetMembersAPI from "./netMembers";
import * as RechargeAPI from "./recharge";
import * as AuthAPI from "./auth";
import * as SitesAPI from "./sites";
import * as InfoAPI from "./info";
import * as RechargeTicketAvgAPI from "./rechargeTicketAvg";
import * as SingleWashAvgAPI from "./singleWashTicketAvg";
import * as GrossTicketPerCarAPI from "./grossTicketPerCar";
import * as ChurnRateByWashPackageAPI from "./churnRateByWashPackage";
import * as WashMixAPI from "./washMix";
import * as ChangePasswordAPI from "./changePassword";
import * as ProfilesAPI from "./profiles";
import * as CancellationsAPI from "./cancellations";
import * as ChurnPaceAPI from "./churnPace";
import * as MemberStatusAPI from "./memberStatus";
import * as CreditCardStatusAPI from "./creditCardStatus";
import * as CreditCardStatusListAPI from "./creditCardStatusList";
import * as CustomerNotificationAPI from "./customerNotification";
import * as MonthlyKPIAPI from "./monthlyKPI";
import * as CCStatusChangedAPI from "./ccStatusChanged";
import "./dataTypes";

/**
 *
 * @param {Object | Array} data
 * @throws {TypeError}
 * @returns {DataTypes}
 */
function whichDataType(data) {
  if (typeof data === "number") {
    return "Number";
  } else if (Array.isArray(data)) {
    return "StandardDataList";
  } else if (
    data.hasOwnProperty("data") &&
    Array.isArray(data.data) &&
    data.hasOwnProperty("info") &&
    (typeof data.info === "string" || data.info instanceof String)
  ) {
    return "StandardInfoDataList";
  } else if (
    data.hasOwnProperty("change") &&
    typeof data.change === "number" &&
    data.hasOwnProperty("value") &&
    typeof data.value === "number"
  ) {
    return "IntervalData";
  } else {
    // we don't have rigid enough data restrictions to throw an error yet
    // just reutrn "Unknown" and keep moving along
    // throw TypeError(`Invalid data: ${data}`);
    return "Unknown";
  }
}

export {
  WashSalesAPI,
  ArmSalesAPI,
  ArmSalesLeaderboardAPI,
  AuthAPI,
  CaptureRateAPI,
  ChurnRateAPI,
  DepositsAPI,
  NetMembersAPI,
  RechargeAPI,
  SitesAPI,
  InfoAPI,
  RechargeTicketAvgAPI,
  SingleWashAvgAPI,
  GrossTicketPerCarAPI,
  ChurnRateByWashPackageAPI,
  WashMixAPI,
  ChangePasswordAPI,
  ProfilesAPI,
  CancellationsAPI,
  ChurnPaceAPI,
  whichDataType,
  MemberStatusAPI,
  CreditCardStatusAPI,
  CreditCardStatusListAPI,
  CustomerNotificationAPI,
  MonthlyKPIAPI,
  CCStatusChangedAPI,
};
