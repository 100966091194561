import { takeLatest, put, select } from "redux-saga/effects";
import {
  selectAuth,
  checkLoggedIn,
  generateSelectDataState,
  generateSelectedSiteIds,
} from "utils/utils";
import { actions, actionCreators } from "./reducers";
import { WashSalesAPI } from "../../api";
import "../../api/dataTypes";
import { getAPIOptionsFromDataState } from "utils/filterUtils";

function mapTrend(data) {
  return data.sort((b, a) => {
    if (a.value < b.value) {
      return -1;
    } else if (a.value > b.value) {
      return 1;
    } else {
      return 0;
    }
  });
}

function* requestData(action) {
  // check logged in
  const auth = yield select(selectAuth);
  const loggedIn = checkLoggedIn(auth);

  if (loggedIn) {
    try {
      const dataState = yield select(generateSelectDataState("washSalesTable"));
      const selectedSiteIds = yield select(generateSelectedSiteIds());

      const washSales = yield WashSalesAPI.getWashSales(auth.access_token, {
        type: "trend",
        ...getAPIOptionsFromDataState(dataState, selectedSiteIds),
      });

      yield put(
        actionCreators.dataLoaded({
          ...washSales,
          data: mapTrend(washSales.data),
        })
      );
    } catch (e) {
      yield put(actionCreators.dataFailed(e.toString()));
    }
  }
}

function* requestDataSaga() {
  yield takeLatest(actions.REQUEST_DATA, requestData);
}

function* onSetFilterId(action) {
  yield put(actionCreators.requestData());
}

function* setFilterSaga() {
  yield takeLatest(actions.SET_FILTER_ID, onSetFilterId);
}

export const SAGAS = [requestDataSaga(), setFilterSaga()];
