import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../store/sites";
import Select, { components } from "react-select";
import "./selectSite.scss";
import Icons from "../icons";
import { reduce } from "lodash";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#00C1DE",
    border: "none",
    borderRadius: "2px",
    color: "#fff",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    "svg": {
      fill: "white",
      path: {
        fill: "white"
      }
    }
  }),
  dropdownIndicator: (provided, state) => {
    return {
      ...provided,
    };
  },
  indicatorSeparator: (provided, state) => ({
    ...provided,
    backgroundColor: "none",
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#FFFFFF",
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: "#D8DFE1",
    color: "#FFFFFF",
    borderRadius: "2px",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: "#FFFFFF",
    fontWeight: "700",
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    ":hover": {
      backgroundColor: "#ffffff",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#00C1DE" : "#fff",
    color: state.isFocused ? "#fff" : "#000",
    padding: 20,
    fontWeight: 600,
    fontSize: "16px",
    borderBottom: state.isFocused ? "none" : "1px solid #D8DFE1",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#FFFFFF",
  }),
};

class SelectSite extends React.Component {
  componentDidMount() {
    this.props.requestData();
  }

  render() {
    const { options, selectedOptions } = this.props;

    const handleChange = newSelectedOptions => {
      const selectedSiteIds = mapOptionsToIdArray(newSelectedOptions);
      this.props.setSelectedSiteIdsFn(selectedSiteIds);
    };

    const DropdownIndicator = props => {
      return (
        <components.DropdownIndicator {...props}>
          <Icons name="downCaret" />
        </components.DropdownIndicator>
      );
    };

    return (
      <Select
        className="select-site"
        classNamePrefix="select-site"
        styles={customStyles}
        isMulti={true}
        value={selectedOptions}
        onChange={handleChange}
        options={options}
        components={{ DropdownIndicator }}
      />
    );
  }
}

SelectSite.propTypes = {
  requestData: PropTypes.func.isRequired,
  dataLoaded: PropTypes.func.isRequired,
  dataFailed: PropTypes.func.isRequired,
  setSelectedSiteIdsFn: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  lastUpdated: PropTypes.number,
  error: PropTypes.string,

  options: PropTypes.array,
  selectedOptions: PropTypes.array,
};

function mapOptionsToIdArray(options) {
  if (!options) return [];

  return options.map(option => option.value);
}

// We need to map site information to how react-select
// formats their options
//
// The selectedSiteIds is stored like as an array
// (e.g. selectedSiteIds = [1,2,3,7]),
//
// but 'react-select' wants options in the format of:
// { value: <value>, label: <label> }.
//
// So I need to find each site on the selectedSiteIds list
// and expand the ID to it's value and label components.
function mapSitesToOptions(sites) {
  if (sites.data) {
    return sites.data.map(site => ({ value: site.id, label: site.code }));
  }
}

function mapSelectedSiteIdsToOptions(sites) {
  if (sites.selectedSiteIds && !sites.error && sites.data) {
    return sites.selectedSiteIds.map(siteId => {
      const foundSite = sites.data.find(site => site.id === siteId);
      return { value: foundSite.id, label: foundSite.code };
    });
  }
}

export default connect(
  state => ({
    options: mapSitesToOptions(state.sites),
    selectedOptions: mapSelectedSiteIdsToOptions(state.sites),
    loading: state.sites.loading,
    error: state.sites.error,
  }),
  dispatch => ({
    requestData: options => dispatch(actionCreators.requestData(options)),
    dataLoaded: () => dispatch(actionCreators.dataLoaded()),
    dataFailed: () => dispatch(actionCreators.dataFailed()),
    setSelectedSiteIdsFn: selectedSiteIds =>
      dispatch(actionCreators.setSelectedSiteIds(selectedSiteIds)),
  })
)(SelectSite);
