import { createDataActions } from "utils/storeUtils";
import * as d3 from "d3";

/**
 * @type {FilterConfigOption[]}
 */
const RECHARGE_TICKET_AVG_CHART_FILTERS = [
  {
    title: "Last 30 Days",
    options: {
      period: 30,
      period_unit: "day",
    },
    xTickFormat: d3.timeFormat("%m/%d/%y"),
    xTicksInterval: d3.timeDay.filter(d => d3.timeDay.count(0, d) % 7 === 0),
  },
  {
    title: "Last 12 Months",
    options: {
      period: 12,
      period_unit: "month",
    },
    timeInterval: d3.timeMonth,
    xTickFormat: d3.timeFormat("%m/%y"),
    xTicksInterval: d3.timeMonth.filter(
      d => d3.timeMonth.count(0, d) % 3 === 0
    ),
  },
  {
    title: "Custom Dates",
    custom: true,
  },
];

export const { actions, actionCreators, reducer } = createDataActions(
  "recharge_ticket_avg_chart",
  RECHARGE_TICKET_AVG_CHART_FILTERS
);
