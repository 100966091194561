import React from "react";
import NumberComponent from "../numberComponent/numberComponent";
import { connect } from "react-redux";
import { actionCreators } from "../../store/netMembers";
import "./netMembers.scss";

const formatter = v => {
  return v.toLocaleString("en", {
    useGrouping: true,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

class NetMembers extends React.Component {
  render() {
    const {
      data,
      requestData,
      dataLoaded,
      dataFailed,
      filters,
      selectedFilterId,
      setFilterId,
      loading,
      error,
      sites,
    } = this.props;

    return (
      <div
        className={`dashboard-widget 
        ${error ? "error" : ""}        
        ${loading ? "loading" : ""} churn-rate-container`}
      >
        <NumberComponent
          requestData={requestData}
          dataLoaded={dataLoaded}
          dataFailed={dataFailed}
          title="NET MEMBERS"
          filters={filters}
          selectedFilterId={selectedFilterId}
          setFilterId={setFilterId}
          {...data}
          config={{
            formatter,
          }}
          sites={sites}
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    data: state.netMembers.data,
    lastUpdated: state.netMembers.lastUpdated,
    filters: state.netMembers.filters,
    selectedFilterId: state.netMembers.selectedFilterId,
    loading: state.netMembers.loading,
    error: state.netMembers.error,
    sites: state.sites,
  }),
  dispatch => ({
    requestData: options => dispatch(actionCreators.requestData(options)),
    dataLoaded: () => dispatch(actionCreators.dataLoaded()),
    dataFailed: () => dispatch(actionCreators.dataFailed()),
    setFilterId: id => dispatch(actionCreators.setFilterId(id)),
  })
)(NetMembers);
