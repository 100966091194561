import React, { useState, useEffect } from "react";
import { GROUP_BY } from "../../utils/filterUtils";
import { toDateInputValue } from "../../utils/utils";
// import "bootstrap/dist/css/bootstrap.css";
import "./filterSelect.scss";

/**
 * @typedef {import("../../utils/filterUtils").DateRange} DateRange
 */

/**
 *
 * @param {Object} params
 * @param {() => null} params.onClose
 * @param {(filters) => null} params.onSave
 * @param {Object[]} params.filters
 * @param {number} params.selectedFilterId
 * @param {DateRange} params.dateRange
 * @param {string} params.groupBy
 */
export default function FilterSelectModal({
  onClose,
  onSave,
  filters,
  selectedFilterId,
  dateRange,
  groupBy,
}) {
  const [newSelectedFilterId, setNewSelectedFilterId] = useState(
    selectedFilterId
  );

  const [custom, setCustom] = useState(false);

  /**
   * @type {[DateRange, (d: DateRange) => null]}
   */
  const [customDateRange, setCustomDateRange] = useState(
    dateRange || {
      start_date: new Date(),
      end_date: new Date(),
    }
  );

  const [customGroupBy, setCustomGroupBy] = useState(
    groupBy || GROUP_BY.day.id
  );

  const onChangeGroupBy = e => {
    setCustomGroupBy(e.target.value);
  };

  const onSetStartDate = e => {
    const date = new Date(e.target.value);

    setCustomDateRange({
      ...customDateRange,
      start_date: date,
    });
  };

  const onSetEndDate = e => {
    const date = new Date(e.target.value);

    setCustomDateRange({
      ...customDateRange,
      end_date: date,
    });
  };

  useEffect(() => {
    const filter = filters.find((f, i) => i === newSelectedFilterId);

    setCustom(filter && filter.custom === true);
  }, [newSelectedFilterId, filters]);

  const onClickSave = () => {
    onSave(
      newSelectedFilterId,
      custom ? customDateRange : undefined,
      custom ? customGroupBy : undefined
    );
    onClose();
  };

  return (
    <div className="filter-select-modal" tabIndex="-1">
      <div className="">
        <div className="">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            >
              <span className="close-icon" aria-hidden="true">
                &times;
              </span>
            </button>
          </div>
          <div className="title-block">
            <h3 className="title">Filter</h3>
          </div>
          <div className="modal-body">
            <div>
              {filters.map((filter, index) => (
                <div className="form-check" key={index}>
                  <label
                    className="form-check-label"
                    htmlFor={`filterSelect-${index}`}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name={index}
                      id={`filterSelect-${index}`}
                      value={index}
                      checked={index === newSelectedFilterId}
                      onClick={e => {
                        setNewSelectedFilterId(index);
                      }}
                      onChange={() => null}
                    />
                    {filter.title}
                  </label>
                </div>
              ))}
            </div>
            {custom && (
              <div className="pure-form pure-form-stacked">
                <fieldset>
                  <label htmlFor="start-date">Start Date</label>
                  <input
                    type="date"
                    id="start-date"
                    onChange={onSetStartDate}
                    value={toDateInputValue(customDateRange.start_date)}
                  />
                  <label htmlFor="end-date">End Date</label>
                  <input
                    type="date"
                    id="end-date"
                    onChange={onSetEndDate}
                    value={toDateInputValue(customDateRange.end_date)}
                  />
                  <label htmlFor="group-by">Group By</label>
                  <select
                    id="group-by"
                    onChange={onChangeGroupBy}
                    value={customGroupBy}
                  >
                    {Object.keys(GROUP_BY).map(id => {
                      const item = GROUP_BY[id];
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </fieldset>
              </div>
            )}
          </div>
          <div />
          <div className="modal-footer">
            <button
              type="button"
              className="pure-button btn btn-secondary"
              data-dismiss="modal"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="pure-button btn btn-primary"
              onClick={onClickSave}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
