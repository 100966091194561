import React from "react";
import { connect } from "react-redux";
import { actionCreators } from "../../store/currentMonthChurnPaceTable";
import RankedTable from "../rankedTable";

const formatter = v => {
  return v.toLocaleString("en", {
    useGrouping: true,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
};

class CurrentMonthChurnPaceTable extends React.Component {
  render() {
    const {
      data,
      requestData,
      dataLoaded,
      dataFailed,
      filters,
      selectedFilterId,
      setFilterId,
      loading,
      error,
      sites,
    } = this.props;

    return (
      <div
        className={`dashboard-widget
        ${error ? "error" : ""}
        ${loading ? "loading" : ""} arm-sales-leaderboard-container`}
      >
        <RankedTable
          requestData={requestData}
          dataLoaded={dataLoaded}
          dataFailed={dataFailed}
          title="CURRENT MONTH CHURN PACE"
          filters={filters}
          selectedFilterId={selectedFilterId}
          setFilterId={setFilterId}
          config={{
            columns: [
              {
                name: "Type",
                path: ["type"],
                sortable: false,
              },
              {
                name: "Monthly Pace",
                path: ["value"],
                sortable: false,
                formatter,
              },
            ],
            rowIdPath: ["type"],
          }}
          sites={sites}
          {...data}
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    data: state.currentMonthChurnPaceTable,
    lastUpdated: state.currentMonthChurnPaceTable.lastUpdated,
    filters: state.currentMonthChurnPaceTable.filters,
    selectedFilterId: state.currentMonthChurnPaceTable.selectedFilterId,
    loading: state.currentMonthChurnPaceTable.loading,
    error: state.currentMonthChurnPaceTable.error,
    sites: state.sites,
  }),
  dispatch => ({
    requestData: options => dispatch(actionCreators.requestData(options)),
    dataLoaded: () => dispatch(actionCreators.dataLoaded()),
    dataFailed: () => dispatch(actionCreators.dataFailed()),
    setFilterId: id => dispatch(actionCreators.setFilterId(id)),
  })
)(CurrentMonthChurnPaceTable);
