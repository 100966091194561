import React from "react";
import Icons from "../icons";
import "./exportCsvButton.scss";

function ExportCSVButton({ onClickButton, text = "Export CSV" }) {
  return (
    <button className="export-csv-btn" onClick={onClickButton}>
      <span>
        <Icons name="csv" />
      </span>{" "}
      {text}
    </button>
  );
}

export default ExportCSVButton;
