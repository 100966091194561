import React from "react";
import "../dashboard.scss";
import WashSales from "components/washSales";
import WashSalesTable from "components/washSalesTable";
import TotalDepositsInterval from "components/totalDepositsInterval";
import GrossTicketPerCarTable from "components/grossTicketPerCarChart";

class SalesDashboard extends React.Component {
  render() {
    return (
      <div className="dashboard-outer">
        <div className="dashboard-inner">
          <div className="dashboard-item-3">
            <WashSales />
          </div>
          <div className="dashboard-item-3">
            <WashSalesTable />
          </div>
        </div>
        <div className="dashboard-inner">
          <div className="dashboard-item-3">
            <GrossTicketPerCarTable />
          </div>
          <div className="dashboard-item-3">
            <TotalDepositsInterval />
          </div>
        </div>
      </div>
    );
  }
}

export default SalesDashboard;
