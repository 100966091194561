import { createDataActions } from "utils/storeUtils";
import * as d3 from "d3";

/**
 * @typedef {import("../../utils/storeUtils").FilterConfigOption} FilterConfigOption
 */

/**
 * @type {FilterConfigOption[]}
 */
const SINGLE_WASH_TICKET_AVG_CHART = [
  {
    title: "Last 30 Days",
    options: {
      period: 30,
      period_unit: "day",
      group: "site",
    },
    xTickFormat: d3.timeFormat("%m/%d/%y"),
    xTicksInterval: d3.timeDay.filter(d => d3.timeDay.count(0, d) % 7 === 0),
  },
  {
    title: "Last 90 Days",
    options: {
      period: 90,
      period_unit: "day",
      group: "site",
    },
    xTickFormat: d3.timeFormat("%m/%d/%y"),
    xTicksInterval: d3.timeDay.filter(d => d3.timeDay.count(0, d) % 15 === 0),
  },
];

export const { actions, actionCreators, reducer } = createDataActions(
  "single_wash_ticket_avg_chart",
  SINGLE_WASH_TICKET_AVG_CHART
);
