import { takeLatest, put, select } from "redux-saga/effects";
import {
  selectAuth,
  checkLoggedIn,
  generateSelectDataState,
  generateSelectedSiteIds,
} from "../../utils";
import { actions, actionCreators } from "./reducers";
import { MonthlyKPIAPI } from "../../api";
import { getAPIOptionsFromDataState } from "utils/filterUtils";

function* requestData(action) {
  // check logged in
  const auth = yield select(selectAuth);
  const loggedIn = checkLoggedIn(auth);

  if (loggedIn) {
    try {
      const dataState = yield select(
        generateSelectDataState("monthlyKPITable")
      );
      const selectedSiteIds = yield select(generateSelectedSiteIds());
      const options = getAPIOptionsFromDataState(dataState, selectedSiteIds);

      const monthlyKPIData = yield MonthlyKPIAPI.getMonthlyKPI(
        auth.access_token,
        {
          ...options,
        }
      );
      yield put(
        actionCreators.dataLoaded({
          ...monthlyKPIData,
          data: monthlyKPIData.data,
        })
      );
    } catch (e) {
      yield put(actionCreators.dataFailed(e.toString()));
    }
  }
}

function* requestDataCSV(action) {
  // check logged in
  const auth = yield select(selectAuth);
  const loggedIn = checkLoggedIn(auth);
  if (loggedIn) {
    try {
      const dataState = yield select(
        generateSelectDataState("monthlyKPITable")
      );
      const selectedSiteIds = yield select(generateSelectedSiteIds());
      const options = getAPIOptionsFromDataState(dataState, selectedSiteIds);
      yield MonthlyKPIAPI.getMonthlyKPICSV(auth.access_token, {
        ...options,
      });
    } catch (e) {
      console.error("Error loading csv for Expired Status:", e);
    }
  }
}

function* requestDataSaga() {
  yield takeLatest(actions.REQUEST_DATA, requestData);
  yield takeLatest(actions.GENERATE_CSV, requestDataCSV);
}

function* onSetFilterId(action) {
  yield put(actionCreators.requestData());
}

function* setFilterSaga() {
  yield takeLatest(actions.SET_FILTER_ID, onSetFilterId);
}

export const SAGAS = [requestDataSaga(), setFilterSaga()];
