import { takeLatest, put, select } from "redux-saga/effects";
import { selectAuth, checkLoggedIn } from "utils/utils";
import { actions, actionCreators } from "./reducers";
import { SitesAPI } from "../../api";

function* requestData(action) {
  // check logged in
  const auth = yield select(selectAuth);
  const loggedIn = checkLoggedIn(auth);

  if (loggedIn) {
    try {
      const sites = yield SitesAPI.getSites(auth.access_token);
      // sort sites by code
      const sortedSites = sites.sort((a, b) => (a.code > b.code ? 1 : -1));
      yield put(actionCreators.dataLoaded(sortedSites));
    } catch (e) {
      yield put(actionCreators.dataFailed(e.toString()));
    }
  }
}

function* requestDataSaga() {
  yield takeLatest(actions.REQUEST_DATA, requestData);
}

export const SAGAS = [requestDataSaga()];
