import { takeLatest, put, select } from "redux-saga/effects";
import { selectAuth, checkLoggedIn } from "utils/utils";
import { actions, actionCreators } from "./reducers";
import { SingleWashAvgAPI } from "../../api";

function* requestData(action) {
  // check logged in
  const auth = yield select(selectAuth);
  const loggedIn = checkLoggedIn(auth);

  if (loggedIn) {
    const singleWashTicketAvg = yield SingleWashAvgAPI.getSingleWashTicketAvg(
      auth.access_token,
      {
        type: "chart",
        ...action.payload,
      }
    );
    yield put(
      actionCreators.dataLoaded(singleWashTicketAvg.single_wash_ticket_avg)
    );
  }
}

function* requestDataSaga() {
  yield takeLatest(actions.REQUEST_DATA, requestData);
}

export const SAGAS = [requestDataSaga()];
