import React from "react";
import "../dashboard.scss";
import WashMixRechargeTable from "components/washMixRechargeTable";
import WashMixSoldTable from "components/washMixSoldTable";
import WashMixRetailTable from "components/washMixRetailTable";
import ChurnRateByWashPackageTable from "components/churnRateByWashPackageTable";

class Dashboard extends React.Component {
  render() {
    return (
      <div className="dashboard-outer">
        <div className="dashboard-inner">
          <div className="dashboard-item-2">
            <WashMixRetailTable />
          </div>
          <div className="dashboard-item-2">
            <WashMixSoldTable />
          </div>
        </div>
        <div className="dashboard-inner">
          <div className="dashboard-item-2">
            <WashMixRechargeTable />
          </div>
          <div className="dashboard-item-2">
            <ChurnRateByWashPackageTable />
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
