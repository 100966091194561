import { store } from "../store/index";
import { UnauthorizedError, checkStatus, ConflictError } from "./utils";
import { logout } from "../store/auth";
import { setVersion } from "../store/version";

export function stringifyQueryParams(params) {
  // 'siteid' or 'allsites' options are required. Option 'allsites'
  // trumps having any 'siteid'.
  if (params.allsites) {
    delete params.siteid;
  }

  // If no 'siteid' is specified, we need to pass 'allsite=true'
  if (!params.siteid || (params.siteid && params.siteid.length === 0)) {
    delete params.siteid;
    params.allsites = true;
  }

  let query = Object.keys(params)
    .map(k => {
      // 'siteid' is an array. We need to translate this:
      //   siteid: [1,2,3]
      // to this:
      //   siteid=1&siteid=2&siteid=3
      if (Array.isArray(params[k])) {
        return params[k]
          .map(l => encodeURIComponent(k) + "=" + encodeURIComponent(l))
          .join("&");
      } else return encodeURIComponent(k) + "=" + encodeURIComponent(params[k]);
    })
    .join("&");

  return query;
}

/**
 * @typedef {object} FetchAndDispatchOptions
 * @property {boolean} [json]
 * @property {int} [timeout]
 */

/**
 *
 * @param {RequestInfo} resource
 * @param {RequestInit?} init
 * @param {FetchAndDispatchOptions} [options]
 */
export async function fetchAndDispatch(resource, init, options = {}) {
  const { json = true, timeout = 55000 } = options;
  // cancel the fetch request if time out
  const controller = new AbortController();
  const reqTime = setTimeout(() => {
    controller.abort();
    // throw new Error("Response Timeout");
  }, timeout);
  try {
    init.signal = controller.signal;
    const res = await fetch(resource, init);

    // should throw an error on bad responses
    const checkedResponse = checkStatus(res);
    clearTimeout(reqTime); // if response is returned, no more timeout

    if (res.headers.has("x-version")) {
      const headerVersion = res.headers.get("x-version");
      const { version } = store.getState();
      if (version.version === undefined) {
        store.dispatch(setVersion(headerVersion));
      } else if (headerVersion !== version.version) {
        console.error(
          `Version mismatch - Current: ${headerVersion} - New Version: ${version.version}`
        );
        window.location.reload();
      }
      // const currentVersion =
    }
    if (init && init.method === "DELETE") {
      // Probably should do a better check
      return checkedResponse.status === 204;
    }
    if (json) {
      return await checkedResponse.json();
    } else {
      return checkedResponse;
    }
  } catch (e) {
    clearTimeout(reqTime); // if response is returned, no more timeout
    console.error("Error in fetchAndDispatch");
    console.error(e);
    if (e instanceof UnauthorizedError) {
      console.error("UnauthorizedError: Logging Out");
      store.dispatch(logout());
    } else if (e instanceof ConflictError) {
      console.error(e);
      throw e;
    } else {
      throw e;
    }
  }
}
