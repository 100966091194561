import { takeLatest, put, select } from "redux-saga/effects";
import {
  selectAuth,
  checkLoggedIn,
  generateSelectDataState,
  generateSelectedSiteIds,
} from "utils/utils";
import { actions, actionCreators } from "./reducers";
import { CCStatusChangedAPI } from "../../api";
import { getAPIOptionsFromDataState } from "utils/filterUtils";

const statusList = ["cc_declined", "cc_expired", "cc_expiring"];

function* requestData(action) {
  // check logged in
  const auth = yield select(selectAuth);
  const loggedIn = checkLoggedIn(auth);
  const dataState = yield select(
    generateSelectDataState("ccStatusChangedTable")
  );
  const selectedSiteIds = yield select(generateSelectedSiteIds());
  const options = getAPIOptionsFromDataState(dataState, selectedSiteIds);

  if (loggedIn) {
    try {
      let allPromises = [];
      for (let i = 0; i < statusList.length; i++) {
        allPromises.push(
          CCStatusChangedAPI.getCCStatusChangedSingleCached(auth.access_token, {
            ...options,
            status: statusList[i],
          })
        );
      }
      const fullResponses = yield Promise.all(allPromises);

      yield put(
        actionCreators.dataLoaded({
          info:
            "CC Updates represents customers we sent a notification to and they had a CC Updated event happen after we sent the notification.  Recharge revenue recaptured is count of CC Updates * Average Recharge.  Overall recovered not only looks at CC Updates, but also for a number of other reasons the customer is back in good status now after being in a bad status.",
          data: fullResponses,
        })
      );
    } catch (e) {
      yield put(actionCreators.dataFailed(e.toString()));
    }
  }
}

function* requestDataSaga() {
  yield takeLatest(actions.REQUEST_DATA, requestData);
}

function* onSetFilterId(action) {
  yield put(actionCreators.requestData());
}

function* setFilterSaga() {
  yield takeLatest(actions.SET_FILTER_ID, onSetFilterId);
}

export const SAGAS = [requestDataSaga(), setFilterSaga()];
