import { WebAuth } from "auth0-js";
import { fetchAndDispatch } from "../utils/apiUtils";

export const auth = new WebAuth({
  domain: process.env.REACT_APP_AUTH0_DOMAIN || "",
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID || "",
  redirectUri: process.env.REACT_APP_AUTH0_CALLBACK_URL || "",
  audience: process.env.REACT_APP_AUDIENCE || "",
  responseType: "token id_token",
  scope: "openid profile email",
});

/**
 * @typedef {object} UserInfo
 * @property {string} nickname "sudstest",
 * @property {string} name "sudstest@zerrtech.com",
 * @property {string} picture "https://s.gravatar.com/avatar/610593a6ec6532262a20f1dd177fa4fa?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fsu.png",
 * @property {string} updated_at "2020-02-25T17:25:53.868Z",
 * @property {string} email "sudstest@zerrtech.com",
 * @property {boolean} email_verified true,
 * @property {string} iss "https://suds-dashboard-test.auth0.com/",
 * @property {string} sub "auth0|5d895c6ac8315b0c0d0fcdc6",
 * @property {number} iat 1582666568,
 * @property {number} exp 1583530568,
 * @property {number} id 1,
 * @property {string} [last_login] null,
 * @property {boolean} is_superuser false,
 * @property {string} username "auth0.5d895c6ac8315b0c0d0fcdc6",
 * @property {string} first_name "",
 * @property {string} last_name "",
 * @property {boolean} is_staff true,
 * @property {boolean} is_active true,
 * @property {string} date_joined "2020-02-24T16:38:32.162500",
 * @property {any[]} groups [],
 * @property {any[]} user_permissions []
 */

/**
 *
 * @param {string} accessToken Auth0 Access Token
 * @returns {Promise<UserInfo>}
 */
export async function getUser(accessToken) {
  const data = await fetchAndDispatch(
    `${process.env.REACT_APP_API_URL}/userinfo/`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return data;
}
