import { takeLatest, put, select } from "redux-saga/effects";
import { selectAuth, checkLoggedIn } from "utils/utils";
import { actions, actionCreators } from "./reducers";
import { SingleWashAvgAPI } from "../../api";

function mapChart(sales) {
  return sales.map(site => {
    return {
      ...site,
      data: site.data
        .map(d => {
          const dateTokens = d.date.split("-");
          const year = parseInt(dateTokens[0], 10);
          const month = parseInt(dateTokens[1], 10) - 1;
          const day = dateTokens[2] ? parseInt(dateTokens[2], 10) : 1;
          return {
            ...d,
            date: new Date(year, month, day, 0, 0, 0),
          };
        })
        .sort((a, b) => {
          if (a.date < b.date) {
            return -1;
          } else if (a.date > b.date) {
            return 1;
          } else {
            return 0;
          }
        }),
    };
  });
}
function* requestData(action) {
  // check logged in
  const auth = yield select(selectAuth);
  const loggedIn = checkLoggedIn(auth);

  if (loggedIn) {
    const rechargeTicketAvg = mapChart(
      yield SingleWashAvgAPI.getSingleWashTicketAvg(auth.access_token, {
        type: "chart",
        allsites: "true",
        ...action.payload,
      })
    );
    yield put(actionCreators.dataLoaded(rechargeTicketAvg));
  }
}

function* requestDataSaga() {
  yield takeLatest(actions.REQUEST_DATA, requestData);
}

export const SAGAS = [requestDataSaga()];
