import React from "react";
import { connect } from "react-redux";
import { actionCreators } from "../../store/cancellations";
import LineChart from "../lineChart";
import "./cancellations.scss";

const formatter = v => {
  return v.toLocaleString("en", {
    useGrouping: true,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

class Cancellations extends React.Component {
  render() {
    const {
      data,
      requestData,
      dataLoaded,
      dataFailed,
      filters,
      selectedFilterId,
      setFilterId,
      loading,
      error,
      sites,
      dateRange,
    } = this.props;

    return (
      <div
        className={`dashboard-widget 
        ${error ? "error" : ""}        
        ${loading ? "loading" : ""} cancellations-container`}
      >
        <LineChart
          requestData={requestData}
          dataLoaded={dataLoaded}
          dataFailed={dataFailed}
          title="CANCELLATIONS"
          filters={filters}
          selectedFilterId={selectedFilterId}
          setFilterId={setFilterId}
          dateRange={dateRange}
          {...data}
          config={{
            yFormatter: formatter,
            canStack: true,
          }}
          sites={sites}
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    data: state.cancellations,
    lastUpdated: state.cancellations.lastUpdated,
    filters: state.cancellations.filters,
    selectedFilterId: state.cancellations.selectedFilterId,
    loading: state.cancellations.loading,
    error: state.cancellations.error,
    sites: state.sites,
    dateRange: state.cancellations.dateRange,
  }),
  dispatch => ({
    requestData: options => dispatch(actionCreators.requestData(options)),
    dataLoaded: () => dispatch(actionCreators.dataLoaded()),
    dataFailed: () => dispatch(actionCreators.dataFailed()),
    setFilterId: (id, dateRange, groupBy) =>
      dispatch(actionCreators.setFilterId(id, dateRange, groupBy)),
  })
)(Cancellations);
