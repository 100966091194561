import React, { useEffect, useState } from "react";
import "./customReports.scss";
import { connect } from "react-redux";
import {
  getItemCategories,
  getCustomReport,
  getCustomReportCSV,
} from "api/reports";
import LoadingSpinner from "../../components/loadingSpinner";
import Icons from "../../components/icons";
import LineChartCore, {
  dateRangeToTimeInterval,
  dateRangeToTimeIntervalMonth,
} from "../../components/lineChart/lineChartCore";
import * as d3 from "d3";
window.d3 = d3;
/**
 * @typedef {import("../../api/reports").Item} Item
 */

/**
 * @typedef {import("../../api/reports").ItemReportCategory} ItemReportCategory
 */

/**
 * @typedef {object} SelectedCategoryItem
 * @property {string} id
 * @property {ItemReportCategory} category
 * @property {Item} [item] if undefined, it means "All" for given category
 */

function categoryItemId(category, item) {
  return `${category.name}|${item.name}`;
}

function allCategoryName(category) {
  return `${category.name}|All`;
}
const ALL_CATEGORY_ITEMS_ID = -54321;

/**
 *
 * @param {object} params
 * @param {SelectedCategoryItem[]} params.selectedCategoriesItems
 * @param {(s: SelectedCategoryItem[]) => null} params.setSelectedCategoriesItems
 * @param {Array} params.selectedCategories
 * @param {(s: Array) => null} params.setSelectedCategories
 * @param {string} access_token
 */
function ItemCategories({
  selectedCategories,
  setSelectedCategories,
  selectedCategoriesItems,
  setSelectedCategoriesItems,
  access_token,
}) {
  const [selectedItemCategory, setSelectedItemCategories] = useState(-1);
  const [itemCategories, setItemCategories] = useState([]);
  function onSelectItemCategory(e) {
    setSelectedItemCategories(parseInt(e.target.value));
  }

  const [selectedItem, setSelectedItem] = useState(ALL_CATEGORY_ITEMS_ID);
  const [items, setItems] = useState([]);
  function onSelectItem(e) {
    setSelectedItem(parseInt(e.target.value));
  }

  useEffect(() => {
    const itemCategory = itemCategories.find(
      category => category.id === selectedItemCategory
    );
    if (itemCategory) {
      setItems([
        {
          id: ALL_CATEGORY_ITEMS_ID,
          name: allCategoryName(itemCategory),
        },
        ...itemCategory.items,
      ]);
    } else {
      setItems([]);
    }
    setSelectedItem(ALL_CATEGORY_ITEMS_ID);
  }, [selectedItemCategory, itemCategories]);

  useEffect(() => {
    getItemCategories(access_token).then(itemCategories => {
      setItemCategories(itemCategories);
    });
  }, [access_token]);

  function onClickAddCategoryItem() {
    if (selectedItem === ALL_CATEGORY_ITEMS_ID) {
      const category = itemCategories.find(c => c.id === selectedItemCategory);
      if (
        category !== undefined &&
        selectedCategories.find(c => c.id === selectedItemCategory) ===
          undefined
      ) {
        setSelectedCategories([
          ...selectedCategories,
          {
            ...category,
          },
        ]);
      }
    } else {
      const category = itemCategories.find(c => c.id === selectedItemCategory);
      const item = items.find(n => n.id === selectedItem);
      const existingItem = selectedCategoriesItems.find(
        ci =>
          ci.category.id === selectedItemCategory && ci.item.id === selectedItem
      );

      if (
        category !== undefined &&
        item !== undefined &&
        existingItem === undefined
      ) {
        const id = categoryItemId(category, item);
        setSelectedCategoriesItems([
          ...selectedCategoriesItems,
          {
            id,
            category,
            item,
          },
        ]);
      }
    }
  }

  /**
   *
   * @param {SelectedCategoryItem} categoryItem
   */
  function onClickRemoveCategoryItem(categoryItem) {
    setSelectedCategoriesItems(
      selectedCategoriesItems.filter(ci => ci.id !== categoryItem.id)
    );
  }

  function onClickRemoveCategory(category) {
    setSelectedCategories(
      selectedCategories.filter(ci => ci.id !== category.id)
    );
  }

  return (
    <div className="item-container">
      <div>
        <label>
          Item Categories<span className="required">*</span>
        </label>
        <select value={selectedItemCategory} onChange={onSelectItemCategory}>
          <option value={-1}></option>
          {itemCategories.map(category => (
            <option value={category.id} key={category.id}>
              {category.name}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label>
          Item Names<span className="required">*</span>
        </label>
        <select value={selectedItem} onChange={onSelectItem}>
          <option value={-1}></option>
          {items.map(items => (
            <option value={items.id} key={items.id}>
              {items.name}
            </option>
          ))}
        </select>
      </div>
      <div>
        <button className="add-item-btn" onClick={onClickAddCategoryItem}>
          <div className="add-item-btn-text">+</div>
        </button>
      </div>
      <div className="item-listing-box">
        {selectedCategoriesItems.map(ci => (
          <div key={ci.id}>
            <span
              role="button"
              onClick={onClickRemoveCategoryItem.bind(this, ci)}
              style={{
                marginRight: "10px",
                cursor: "pointer",
                fontWeight: "bold",
              }}
            >
              X
            </span>
            {ci.id}
          </div>
        ))}
        {selectedCategories.map(c => (
          <div key={c.id}>
            <span
              role="button"
              onClick={onClickRemoveCategory.bind(this, c)}
              style={{
                marginRight: "10px",
                cursor: "pointer",
                fontWeight: "bold",
              }}
            >
              X
            </span>
            {allCategoryName(c)}
          </div>
        ))}
      </div>
    </div>
  );
}

const VALUES = [
  {
    id: 0,
    name: "Amount",
  },
  {
    id: 1,
    name: "Quantity",
  },
];

const VALUE_UNITS = [
  {
    id: 0,
    name: "None",
    yTickArguments: [5, "s"],
    yFormatter: value => `${value}`,
  },
  {
    id: 1,
    name: "Dollar",
    /**
     *
     * @param {string} v
     */
    yTickArguments: [4, "$s"],
    yFormatter: value => "$" + value.toFixed(2).toString(),
  },
];

const GROUP_TIME_BY = [
  {
    id: "YYYY-MM-DD",
    name: "Day",
  },
  {
    id: "YYYY-MM",
    name: "Month",
  },
];

/**
 *
 * @param {string} name
 * @returns {string}
 */
const shortenName = (name, length = 3) => {
  if (
    process.env.REACT_APP_SHORTEN_SITE &&
    process.env.REACT_APP_SHORTEN_SITE === "true"
  ) {
    return name.substring(name.length - length, name.length);
  } else {
    return name;
  }
};

/**
 * @typedef {object} SeriesOption
 * @property {string} id
 * @property {string} name
 * @property {(name: string) => string} [legendNameFormatter]
 */

/**
 * @typedef {import("../../components/lineChart/lineChartCore").LineChartConfig} LineChartConfig
 */

/**
 * @type {SeriesOption[]}
 */
const SERIES_OPTIONS = [
  {
    id: "site",
    name: "Site",
    legendNameFormatter: shortenName,
  },
  {
    id: "item",
    name: "Item",
  },
  {
    id: "item-category",
    name: "Item Category",
  },
];

/* VALIDATION FUNCTIONS */

function validateStartDate(startDate) {
  return startDate.length > 0;
}

function validateEndDate(endDate) {
  return endDate.length > 0;
}

function validateStartEndDateDiff(startDate, endDate) {
  if (endDate.length > 0) {
    if (endDate === startDate) {
      return false; // if they are the same, they are not different
    } else {
      return true; // they are different
    }
  }
}

/**
 *
 * @param {Array} selectedCategoriesItems
 * @param {Array} selectedCategories
 */
function validateSelectedCategories(
  selectedCategoriesItems,
  selectedCategories
) {
  return selectedCategoriesItems.length > 0 || selectedCategories.length > 0;
}

/**
 *
 * @param {string} series
 */
function validateSeries(series) {
  return series.length > 0;
}

function CustomReports({ access_token, selectedSiteIds }) {
  // hooks to make sure these fields are filled in
  const [wasGoClicked, setWasGoClicked] = useState(false);
  // we don't want the warnings to appear until after a submission attempt
  const [formValid, setFormValid] = useState(false);
  const [isStartDateValid, setisStartDateValid] = useState(false);
  const [isEndDateValid, setisEndDateValid] = useState(false);
  const [startEndDiff, setStartEndDiff] = useState(false);
  const [isSelectedCategoriesValid, setisSelectedCategoriesValid] = useState(
    false
  );
  const [loadingCustomReport, setLoadingCustomReport] = useState(false);

  const [startDate, setStartDate] = useState("");
  function onSelectStartDate(e) {
    const startDate = e.target.value;
    setStartDate(startDate);
    if (startDate > endDate) {
      // start date can never be bigger than end date
      setEndDate(startDate);
    }
  }

  const [endDate, setEndDate] = useState("");
  function onSelectEndDate(e) {
    const endDate = e.target.value;
    setEndDate(endDate);
    if (endDate.length > startDate.length) {
      setStartDate(endDate);
    } else if (
      endDate.charAt(0) === startDate.charAt(0) &&
      endDate < startDate
    ) {
      setStartDate(endDate);
    }
  }

  const [groupTimeBy, setGroupTimeBy] = useState(GROUP_TIME_BY[0].id);
  function onChangeGroupTimeBy(e) {
    setGroupTimeBy(e.target.value);
  }

  const [xTicksInterval, setXTicksInterval] = useState();
  useEffect(() => {
    if (groupTimeBy === GROUP_TIME_BY[1].id) {
      const interval = dateRangeToTimeIntervalMonth(
        new Date(startDate),
        new Date(endDate)
      );
      setXTicksInterval(() => interval);
    } else if (startDate.length > 0 && endDate.length > 0) {
      const interval = dateRangeToTimeInterval(
        new Date(startDate),
        new Date(endDate)
      );

      setXTicksInterval(() => interval);
    }
  }, [startDate, endDate, groupTimeBy]);

  const [timeInterval, setTimeInterval] = useState(() => d3.timeDay);
  useEffect(() => {
    if (groupTimeBy === GROUP_TIME_BY[1].id) {
      setTimeInterval(() => d3.timeMonth);
    } else if (startDate.length > 0 && endDate.length > 0) {
      setTimeInterval(() => d3.timeDay);
    }
  }, [startDate, endDate, groupTimeBy]);

  /**
   * @type {[SelectedCategoryItem[], (s: SelectedCategoryItem[]) => null]}
   */
  const [selectedCategoriesItems, setSelectedCategoriesItems] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const [value, setValue] = useState(VALUES[0]);

  function onSelectValue(e) {
    const id = parseInt(e.target.value);
    const value = VALUES.find(v => v.id === id);

    if (value !== undefined) {
      setValue(value);
    }
  }

  const [valueUnits, setValueUnits] = useState(VALUE_UNITS[0]);

  function onSelectValueUnits(e) {
    const id = parseInt(e.target.value);
    const valueUnits = VALUE_UNITS.find(v => v.id === id);

    if (valueUnits !== undefined) {
      setValueUnits(valueUnits);
    }
  }

  // START SERIES
  const [series, setSeries] = useState(SERIES_OPTIONS[0]);

  function onSelectSeries(e) {
    const id = e.target.value;
    const series = SERIES_OPTIONS.find(s => s.id === id);

    if (series) {
      setSeries(series);
    }
  }
  // END SERIES

  const [chartData, setChartData] = useState();
  const [showChart, setShowChart] = useState();
  const [stacked, setStacked] = useState(false);
  /**
   * @type {[LineChartConfig, (c: LineChartConfig) => null]}
   */
  const [chartConfig, setChartConfig] = useState({});

  useEffect(() => {
    /**
     * @type {LineChartConfig}
     */
    let lineChartConfig = {};

    if (valueUnits) {
      lineChartConfig.yTickArguments = valueUnits.yTickArguments;

      lineChartConfig.yFormatter = valueUnits.yFormatter;
    }

    if (series && series.legendNameFormatter) {
      lineChartConfig.legendNameFormatter = series.legendNameFormatter;
    }

    setChartConfig(lineChartConfig);
  }, [valueUnits, series]);

  function toggleStacked() {
    setStacked(!stacked);
  }

  useEffect(() => {
    function validateForm() {
      const isStartDateValid = validateStartDate(startDate);
      const isEndDateValid = validateEndDate(endDate);
      const isStartEndDateDiffValid = validateStartEndDateDiff(
        startDate,
        endDate
      );
      const isSelectedCategoriesValid = validateSelectedCategories(
        selectedCategoriesItems,
        selectedCategories
      );
      const isSeriesValid = validateSeries(series.id);

      const formValid =
        isStartDateValid &&
        isEndDateValid &&
        isStartEndDateDiffValid &&
        isSelectedCategoriesValid &&
        isSeriesValid;

      // check start date
      setisStartDateValid(validateStartDate(startDate));
      // check end date
      setisEndDateValid(validateEndDate(endDate));
      setStartEndDiff(validateStartEndDateDiff(startDate, endDate));
      // check item Categories date
      setisSelectedCategoriesValid(
        validateSelectedCategories(selectedCategoriesItems, selectedCategories)
      );
      setFormValid(formValid);
    }

    validateForm();
  }, [startDate, endDate, selectedCategories, selectedCategoriesItems, series]);

  const [customReportOptions, setCustomReportOptions] = useState({});
  useEffect(() => {
    setCustomReportOptions({
      startDate,
      endDate,
      groupBy: groupTimeBy,
      items: selectedCategoriesItems.map(s => s.item.id),
      categories: selectedCategories.map(c => c.id),
      value: value.name.toLowerCase(),
      series: series.id,
      allsites: selectedSiteIds.length === 0,
      siteids: selectedSiteIds,
    });
  }, [
    startDate,
    endDate,
    groupTimeBy,
    selectedCategoriesItems,
    selectedCategories,
    value,
    series,
    selectedSiteIds,
  ]);

  // call when go is clicked AND when reload options change (but only after initial load)
  async function fetchReport(formValid, options, access_token) {
    if (formValid) {
      try {
        setLoadingCustomReport(true);
        const cd = await getCustomReport(access_token, {
          ...options,
        });
        setChartData(cd);
        setShowChart(true);
      } catch (e) {
        console.error("Error loading chart data:", e);
      }
      setLoadingCustomReport(false);
    }
  }

  useEffect(() => {
    if (showChart) {
      fetchReport(formValid, customReportOptions, access_token);
    }
  }, [customReportOptions, formValid, access_token, showChart]);

  function onClickGo() {
    if (formValid) {
      setShowChart(true);
    }
    setWasGoClicked(true);
  }

  /* Validation messages */

  function endDateError(startToEndDifference, endDateValidation) {
    // pass in variables instead
    if (!startToEndDifference && endDateValidation) {
      // only when there is a string but it's the same string
      return (
        <div className="end-date-error">
          {" "}
          End Date Has Same Date as Start Date!{" "}
        </div>
      );
    } else if (!endDateValidation) {
      return (
        <div className="end-date-error"> End Date Has Missing Fields! </div>
      );
    }
  }

  async function onClickCSV() {
    if (formValid) {
      try {
        setLoadingCustomReport(true);
        await getCustomReportCSV(access_token, {
          ...customReportOptions,
        });
      } catch (e) {
        console.error("Error loading chart data:", e);
      }
      setLoadingCustomReport(false);
    }
  }

  function onClickBack() {
    setChartData(undefined);
    setShowChart(false);
  }

  return (
    <div className="custom-reports-wrapper">
      <h1>Custom Reports {loadingCustomReport && <LoadingSpinner />} </h1>
      {!showChart && !loadingCustomReport && (
        <div className="custom-report-form-container">
          {!showChart && (
            <>
              <div className="date-container">
                <div>
                  <label>
                    Start Date<span className="required">*</span>
                  </label>
                  <input
                    value={startDate}
                    onChange={onSelectStartDate}
                    type="date"
                    max={"9999-12-31"}
                    maxLength={4}
                  />
                  {wasGoClicked && !isStartDateValid && (
                    <div className="start-date-error">
                      {" "}
                      Start Date Has Missing Fields!{" "}
                    </div>
                  )}
                </div>
                <div>
                  <label>
                    End Date<span className="required">*</span>
                  </label>
                  <input
                    value={endDate}
                    onChange={onSelectEndDate}
                    type="date"
                    max={"9999-12-31"}
                    maxLength={4}
                  />
                  {wasGoClicked && endDateError(startEndDiff, isEndDateValid)}
                </div>
              </div>

              <div className="group-by-container">
                <div>
                  <label>
                    Group Time By<span className="required">*</span>
                  </label>
                  <select value={groupTimeBy} onChange={onChangeGroupTimeBy}>
                    {GROUP_TIME_BY.map(g => (
                      <option key={g.id} value={g.id}>
                        {g.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <ItemCategories
                selectedCategories={selectedCategories}
                setSelectedCategories={setSelectedCategories}
                selectedCategoriesItems={selectedCategoriesItems}
                setSelectedCategoriesItems={setSelectedCategoriesItems}
                access_token={access_token}
              />
              {wasGoClicked && !isSelectedCategoriesValid && (
                <div className="item-categories-error">
                  {" "}
                  Item Categories Has Missing Fields!{" "}
                </div>
              )}

              <div className="value-container">
                <div>
                  <label>Value</label>
                  <select value={value.id} onChange={onSelectValue}>
                    {VALUES.map(v => (
                      <option key={v.id} value={v.id}>
                        {v.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label>Value Units</label>
                  <select value={valueUnits.id} onChange={onSelectValueUnits}>
                    {VALUE_UNITS.map(v => (
                      <option key={v.id} value={v.id}>
                        {v.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="series-container">
                <div>
                  <label>Series (data grouping)</label>
                  <select value={series.id} onChange={onSelectSeries}>
                    {SERIES_OPTIONS.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="go-button-container">
                <button onClick={onClickGo}>Go {">"}</button>
              </div>
            </>
          )}
        </div>
      )}
      {showChart && (
        <>
          <div className="custom-report-graph-container">
            <div>
              <button onClick={onClickBack}>{"<"} Back</button>
            </div>
            <div className="range-and-csv-container">
              <div>
                Date Range: <strong>{startDate}</strong> -{" "}
                <strong>{endDate}</strong>
              </div>
              <div>
                <button onClick={onClickCSV}>
                  <span>
                    <Icons name="csv" />
                  </span>{" "}
                  Export Report (CSV)
                </button>
              </div>
            </div>
            <div className="items-value-series-container">
              <div>
                <label>Selected Categories/Items</label>
                <div className="item-listing-box graph-box">
                  {selectedCategoriesItems.map(ci => (
                    <div key={ci.id}>{ci.id}</div>
                  ))}
                  {selectedCategories.map(c => (
                    <div key={c.id}>{allCategoryName(c)}</div>
                  ))}
                </div>
              </div>
              <div className="value-and-series-container">
                <div>
                  Value: <strong>{value.name}</strong>
                </div>
                <div>
                  Series: <strong>{series.name}</strong>
                </div>
              </div>
            </div>
          </div>
          <div className="stacked-option-container">
            <label>Stacked</label>
            <input type="checkbox" checked={stacked} onChange={toggleStacked} />
          </div>
          {chartData && (
            <div
              className={`line-chart ${loadingCustomReport ? "loading" : ""}`}
            >
              <LineChartCore
                data={chartData.data}
                loading={false}
                stacked={stacked}
                selectedGroupsIds={series === "site" ? selectedSiteIds : []}
                config={chartConfig}
                timeInterval={timeInterval}
                xTicksInterval={xTicksInterval}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default connect(function(state) {
  return {
    access_token: state.auth.auth.access_token,
    selectedSiteIds: state.sites.selectedSiteIds,
  };
})(CustomReports);
