import { createDataActions } from "utils/storeUtils";
import * as d3 from "d3";
/**
 * @typedef {import("../../utils/storeUtils").FilterConfigOption} FilterConfigOption
 */

/**
 * @type {FilterConfigOption[]}
 */
const HISTORICAL_CAPTURE_RATE_CHART_FILTERS = [
  {
    title: "Last 30 Days",
    options: {
      period: 30,
      period_unit: "day",
    },
    timeInterval: d3.timeDay,
    xTickFormat: d3.timeFormat("%m/%d/%y"),
    xTicksInterval: d3.timeDay.filter(d => d3.timeDay.count(0, d) % 7 === 0),
  },
  {
    title: "Last 3 Months",
    options: {
      period: 3,
      period_unit: "month",
    },
    timeInterval: d3.timeMonth,
    xTickFormat: d3.timeFormat("%m/%y"),
    xTicksInterval: d3.timeMonth.filter(
      d => d3.timeMonth.count(0, d) % 1 === 0
    ),
  },
  {
    title: "Last 6 Months",
    options: {
      period: 6,
      period_unit: "month",
    },
    timeInterval: d3.timeMonth,
    xTickFormat: d3.timeFormat("%m//%y"),
    xTicksInterval: d3.timeMonth.filter(
      d => d3.timeMonth.count(0, d) % 1 === 0
    ),
  },
  {
    title: "Last 12 Months",
    options: {
      period: 12,
      period_unit: "month",
    },
    timeInterval: d3.timeMonth,
    xTickFormat: d3.timeFormat("%m/%y"),
    xTicksInterval: d3.timeMonth.filter(
      d => d3.timeMonth.count(0, d) % 3 === 0
    ),
  },
  {
    title: "Last 2 Years",
    options: {
      period: 24,
      period_unit: "month",
    },
    timeInterval: d3.timeMonth,
    xTickFormat: d3.timeFormat("%m/%y"),
    xTicksInterval: d3.timeMonth.filter(
      d => d3.timeMonth.count(0, d) % 3 === 0
    ),
  },
];

export const { actions, actionCreators, reducer } = createDataActions(
  "historical_capture_rate_chart",
  HISTORICAL_CAPTURE_RATE_CHART_FILTERS
);
