import { fetchAndDispatch } from "../utils/apiUtils";
/**
 *
 * @param {string} accessToken Auth0 Access Token
 */
export async function getCustomerNotification(
  accessToken,
  { notificationType, ordering, search, page, pageSize }
) {
  let notificationString = "";
  if (notificationType !== "all") {
    notificationString = `&notification_type=${notificationType}`;
  }
  let orderingString = "";
  if (ordering !== "") {
    orderingString = `&ordering=${ordering}`;
  }
  let searchString = "";
  if (search !== "") {
    searchString = `&search=${search}`;
  }
  let pageSizeString = "";
  if (pageSize) {
    pageSizeString = `&page_size=${pageSize}`;
  }

  const data = await fetchAndDispatch(
    `${process.env.REACT_APP_API_URL}/customernotifications/?${notificationString}${orderingString}${searchString}&page=${page}${pageSizeString}`,
    {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return data;
}
