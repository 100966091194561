import React from "react";
import PropTypes from "prop-types";
import { baseComponentPropTypes } from "utils/utils";
import "./totalTable.scss";
import { get } from "lodash";
import TitleComponent from "../titleComponent";
import SiteSelectedAndFiltered from "../siteSelectedAndFilter";

class TotalTable extends SiteSelectedAndFiltered {
  // Override the Super Function.
  // TotalTable already has the data for all
  // Sites.
  componentDidUpdate() {}

  render() {
    const { title, filters, selectedFilterId, config, info } = this.props;
    const data = super.getSelectedSitesData();
    const { sort } = this.state;

    const totalColumns = config.columns
      .slice(1)
      .map(col => (col.totalFormatter ? col : ""));

    let sortedData = data;

    if (sort !== undefined) {
      sortedData = this.getSortedData(data);
    }

    return (
      <div className="table-container">
        <TitleComponent
          title={title}
          filters={filters}
          info={info}
          selectedFilterId={selectedFilterId}
          onChangeFilter={(id, dateRange, groupBy) =>
            super.onChangeFilter(id, dateRange, groupBy)
          }
          onClickTitle={this.requestData.bind(this)}
        />
        <table className="table">
          <thead>
            <tr>
              {config.columns.map((col, i) => {
                let onClick = undefined;
                let cn = "";
                let sortArrow = "";

                if (col.sortable) {
                  onClick = () => this.toggleSort(col);
                  cn = "sortable-column";

                  if (sort && sort.name === col.name) {
                    if (sort.sort === "asc") {
                      sortArrow = "▲";
                    } else if (sort.sort === "desc") {
                      sortArrow = "▼";
                    }
                  }
                }

                return (
                  <th className={cn} onClick={onClick} key={i}>
                    {col.name} {sortArrow}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {sortedData &&
              sortedData.map(row => {
                return (
                  <tr key={get(row, config.rowIdPath)} className="body-row">
                    {config.columns.map((col, i) => {
                      const value = get(row, col.path);
                      return (
                        <td key={i}>
                          {col.formatter ? col.formatter(value) : value}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            <tr className="total-row">
              <td>TOTAL</td>
              {totalColumns.map((col, i) => {
                let total =
                  data && col.path
                    ? data.reduce((total, row) => total + get(row, col.path), 0)
                    : undefined;
                return (
                  <td key={i}>{total ? col.totalFormatter(total) : ""}</td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

TotalTable.propTypes = {
  config: PropTypes.shape({
    columns: PropTypes.array.isRequired,
    rowIdPath: PropTypes.array.isRequired,
    valuePath: PropTypes.array,
    totalFormatter: PropTypes.func,
  }).isRequired,
  ...baseComponentPropTypes,
};

export default TotalTable;
