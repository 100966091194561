import { fetchAndDispatch } from "../utils/apiUtils";

/**
 * @typedef {Object} User
 * @property {number} id 98,
 * @property {string} password "",
 * @property {boolean} is_staff true,
 * @property {string | null} last_login null,
 * @property {boolean} is_superuser true,
 * @property {string} username "auth0.5eea7a3cc70eb0001938becb",
 * @property {string} first_name "",
 * @property {string} last_name "",
 * @property {boolean} is_active true,
 * @property {string} date_joined "2020-10-12T16:23:18.165545",
 * @property {any[]} groups [],
 * @property {any[]} user_permissions []
 * @property {string} email "test4@zerrtech.com",
 */

/**
 * @typedef {Object} PartialUser
 * @property {number} id 98,
 * @property {string} [password] "",
 * @property {boolean} [is_staff] true,
 * @property {string | null} [last_login] null,
 * @property {boolean} [is_superuser] true,
 * @property {string} [username] "auth0.5eea7a3cc70eb0001938becb",
 * @property {string} [first_name] "",
 * @property {string} [last_name] "",
 * @property {boolean} [is_active] true,
 * @property {string} [date_joined] "2020-10-12T16:23:18.165545",
 * @property {any[]} [groups] [],
 * @property {any[]} [user_permissions] []
 * @property {string} [email] "test4@zerrtech.com",
 */

/**
 *
 * @param {User} user
 */
export function isAdmin(user) {
  return user.is_staff && user.is_superuser;
}

/**
 *
 * @param {string} accessToken Auth0 Access Token
 * @param {User} user
 */
export async function createUser(accessToken, profile) {
  const data = await fetchAndDispatch(
    `${process.env.REACT_APP_API_URL}/users/`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(profile),
    }
  );

  return data;
}

/**
 *
 * @param {string} accessToken Auth0 Access Token
 * @param {PartialUser} user
 */
export async function updateUser(accessToken, user) {
  const data = await fetchAndDispatch(
    `${process.env.REACT_APP_API_URL}/users/${user.id}/`,
    {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(user),
    }
  );

  return data;
}

export async function loadUsers(accessToken) {
  const data = await fetchAndDispatch(
    `${process.env.REACT_APP_API_URL}/users/`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return data;
}

export async function deleteUser(accessToken, userId) {
  const data = await fetchAndDispatch(
    `${process.env.REACT_APP_API_URL}/users/${userId}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return data;
}
