import React from "react";
import "../dashboard.scss";
import { connect } from "react-redux";
import MonthlyKPIChart from "components/monthlyKpiChart";

function MonthlyKPI({ access_token }) {
  return (
    <div className="dashboard-outer monthly-kpi-page">
      <div className="dashboard-inner">
        <MonthlyKPIChart access_token={access_token} />
      </div>
    </div>
  );
}

export default connect(function(state) {
  return {
    access_token: state.auth.auth.access_token,
  };
})(MonthlyKPI);
