import { takeLatest, put, select } from "redux-saga/effects";
import {
  selectAuth,
  checkLoggedIn,
  generateSelectDataState,
  generateSelectedSiteIds,
} from "utils/utils";
import { actions, actionCreators } from "./reducers";
import { WashSalesAPI } from "../../api";
import "../../api/dataTypes";
import { getAPIOptionsFromDataState } from "utils/filterUtils";

function mapChart(data) {
  // sort sites by code (name)
  const sortedDataBySite = data.sort((a, b) =>
    a.site.name > b.site.name ? 1 : -1
  );
  return sortedDataBySite.map(site => {
    return {
      ...site,
      data: site.data
        .map(d => {
          const dateTokens = d.date.split("-");
          const year = parseInt(dateTokens[0], 10);
          const month = parseInt(dateTokens[1], 10) - 1;
          const day = dateTokens[2] ? parseInt(dateTokens[2], 10) : 1;
          return {
            ...d,
            date: new Date(year, month, day, 0, 0, 0),
          };
        })
        .sort((a, b) => {
          if (a.date < b.date) {
            return -1;
          } else if (a.date > b.date) {
            return 1;
          } else {
            return 0;
          }
        }),
    };
  });
}

function* requestData(action) {
  // check logged in
  const auth = yield select(selectAuth);
  const loggedIn = checkLoggedIn(auth);

  if (loggedIn) {
    try {
      const dataState = yield select(generateSelectDataState("washSales"));
      const selectedSiteIds = yield select(generateSelectedSiteIds());
      /**
       * @type {StandardInfoDataList}
       */
      const washSales = yield WashSalesAPI.getWashSales(auth.access_token, {
        type: "chart",
        ...getAPIOptionsFromDataState(dataState, selectedSiteIds),
      });
      yield put(
        actionCreators.dataLoaded({
          ...washSales,
          data: mapChart(washSales.data),
        })
      );
    } catch (e) {
      yield put(actionCreators.dataFailed(e.toString()));
    }
  }
}

function* onSetFilterId(action) {
  yield put(actionCreators.requestData());
}

function* setFilterSaga() {
  yield takeLatest(actions.SET_FILTER_ID, onSetFilterId);
}

function* requestDataSaga() {
  yield takeLatest(actions.REQUEST_DATA, requestData);
}

export const SAGAS = [requestDataSaga(), setFilterSaga()];
