import React from "react";
import { connect } from "react-redux";
import { actionCreators } from "../../store/historicalChurnRateChart";
import LineChart from "../lineChart";
import "./historicalChurnRateChart.scss";

const formatter = v => {
  return (
    (v * 100).toLocaleString("en", {
      useGrouping: true,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }) + "%"
  );
};

class RechargeTicketAvgChart extends React.Component {
  render() {
    const {
      data,
      requestData,
      dataLoaded,
      dataFailed,
      filters,
      selectedFilterId,
      setFilterId,
      loading,
      error,
      sites,
    } = this.props;
    return (
      <div
        className={`dashboard-widget
        ${error ? "error" : ""}
        ${loading ? "loading" : ""} arm-sales-container`}
      >
        <LineChart
          requestData={requestData}
          dataLoaded={dataLoaded}
          dataFailed={dataFailed}
          title="HISTORICAL CHURN RATE"
          filters={filters}
          selectedFilterId={selectedFilterId}
          setFilterId={setFilterId}
          {...data}
          config={{
            yFormatter: formatter,
            yTickArguments: [3, "%"],
          }}
          sites={sites}
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    data: state.historicalChurnRateChart,
    lastUpdated: state.historicalChurnRateChart.lastUpdated,
    filters: state.historicalChurnRateChart.filters,
    selectedFilterId: state.historicalChurnRateChart.selectedFilterId,
    loading: state.historicalChurnRateChart.loading,
    error: state.historicalChurnRateChart.error,
    sites: state.sites,
  }),
  dispatch => ({
    requestData: options => dispatch(actionCreators.requestData(options)),
    dataLoaded: () => dispatch(actionCreators.dataLoaded()),
    dataFailed: () => dispatch(actionCreators.dataFailed()),
    setFilterId: id => dispatch(actionCreators.setFilterId(id)),
  })
)(RechargeTicketAvgChart);
