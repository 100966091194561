import React from "react";
import { connect } from "react-redux";
import { actionCreators } from "../../store/washMixRechargeTable";
import RankedTable from "../rankedTable";
import * as d3 from "d3";

const formatter = v => {
  return v.toLocaleString("en", {
    useGrouping: true,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

class WashMixRechargeTable extends React.Component {
  render() {
    const {
      data,
      requestData,
      dataLoaded,
      dataFailed,
      filters,
      selectedFilterId,
      setFilterId,
      loading,
      error,
      sites,
    } = this.props;

    return (
      <div
        className={`dashboard-widget
        ${error ? "error" : ""}
        ${loading ? "loading" : ""} wash-mix-recharge-container`}
      >
        <RankedTable
          requestData={requestData}
          dataLoaded={dataLoaded}
          dataFailed={dataFailed}
          title="Wash Mix Recharge"
          filters={filters}
          selectedFilterId={selectedFilterId}
          setFilterId={setFilterId}
          config={{
            columns: [
              {
                name: "Package",
                path: ["wash_type"],
                sortable: true,
                formatter,
              },
              {
                name: "Count",
                path: ["wash_count"],
                sortable: true,
              },
              {
                name: "%",
                path: ["pct"],
                sortable: true,
                formatter: d3.format(".2%"),
              },
            ],
            rowIdPath: ["wash_type"],
            totalFormatter: formatter,
          }}
          sites={sites}
          {...data}
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    data: state.washMixRechargeTable,
    lastUpdated: state.washMixRechargeTable.lastUpdated,
    filters: state.washMixRechargeTable.filters,
    selectedFilterId: state.washMixRechargeTable.selectedFilterId,
    loading: state.washMixRechargeTable.loading,
    error: state.washMixRechargeTable.error,
    sites: state.sites,
  }),
  dispatch => ({
    requestData: options => dispatch(actionCreators.requestData(options)),
    dataLoaded: () => dispatch(actionCreators.dataLoaded()),
    dataFailed: () => dispatch(actionCreators.dataFailed()),
    setFilterId: id => dispatch(actionCreators.setFilterId(id)),
  })
)(WashMixRechargeTable);
