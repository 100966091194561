import { createDataActions } from "utils/storeUtils";

/**
 * @typedef {import("../../utils/storeUtils").FilterConfigOption} FilterConfigOption
 */

/**
 * @type {FilterConfigOption[]}
 */
const CHURN_RATE_BY_WASH_PACKAGE_TABLE = [
  {
    title: "Last Month",
    options: {
      period: 30,
      period_unit: "day",
    },
  },
];

export const { actions, actionCreators, reducer } = createDataActions(
  "churn_rate_by_wash_package_table",
  CHURN_RATE_BY_WASH_PACKAGE_TABLE
);
