import React, { useState, useEffect, useCallback } from "react";
import * as ProfileAPI from "../../api/profiles";
import * as SitesAPI from "../../api/sites";
import { connect } from "react-redux";
import { cloneDeep, indexOf } from "lodash";
import AlertProfile from "./alertProfile";
import "./alerts.scss";
/**
 * @typedef {import("../../api/profiles").Profile} Profile
 */

/**
 * @typedef {import("../../api/profiles").AlertProfile} AlertProfile
 */

/**
 *
 * @param {object} props
 * @param {string} props.access_token
 */
function Alerts({ access_token }) {
  // START COMPONENT STATE
  /**
   * Local state profiles list and setter function
   * @type [Profile[], (p: Profile) => null]
   */
  const [profiles, setProfiles] = useState([]);
  const [activeProfile, setActiveProfile] = useState("");
  const [saveStatus, setSaveStatus] = useState({
    saveText: "Save Profile",
    saveClass: "button-default",
    saveEnabled: true,
  });
  // END COMPONENT STATE

  function updateSaveStatus(status) {
    let saveText = "Save Changes";
    let saveClassName = "button-default";
    let saveEnabled = true;
    if (status === "saving") {
      saveText = "Saving Changes";
      saveClassName = "button-saving";
      saveEnabled = false;
    }
    if (status === "saved") {
      saveText = "Saved Changes";
      saveClassName = "button-saved";
      saveEnabled = false;
    }

    let saveInfo = {
      saveText: saveText,
      saveClass: saveClassName,
      saveEnabled: saveEnabled,
    };

    setSaveStatus(saveInfo);

    return saveInfo;
  }

  function toggleProfile(profileId) {
    if (profileId === activeProfile) {
      setActiveProfile(null);
      return;
    }
    setActiveProfile(profileId);
  }

  // START COMPONENT CALLBACKS

  async function addProfile() {
    const profile = await ProfileAPI.addProfile(access_token, {
      first_name: "First",
      last_name: "Last",
      email: "dev@sudscreative.com",
      phone_number: "5005550006",
    });

    profile.alert_profile = await addAlertProfile(profile);
    profile.alert_profile.frequencies = await addAlertFrequencies(profile);
    profile.alert_profile.locations = await addAlertLocations(profile);
    await updateLocalProfiles(profile);
    toggleProfile(profile.id);
    return profile;
  }
  /**
   *
   * @param {Profile} profile
   */
  async function updateProfile(profile) {
    updateSaveStatus("saving");
    const p = await ProfileAPI.updateProfile(access_token, profile);
    if (p.alert_profile !== null && p.alert_profile !== undefined) {
      await addAlertProfile(p, profile);
    }
    updateSaveStatus("saved");
    setTimeout(() => {
      updateSaveStatus("save");
    }, 1500);
    return p;
  }

  async function updateLocalProfiles(profile) {
    const currentProfiles = cloneDeep(profiles);
    let profileIndex = currentProfiles.findIndex(p => p.id === profile.id);
    if (profileIndex === -1) {
      currentProfiles.push(profile);
    } else {
      currentProfiles[profileIndex] = profile;
    }
    setProfiles(currentProfiles);
    return currentProfiles;
  }

  /**
   *
   * @param {Profile} profile
   */
  async function deleteProfile(profile) {
    await ProfileAPI.deleteProfile(access_token, profile.id);
    const currentProfiles = cloneDeep(profiles);
    let profileIndex = currentProfiles.findIndex(p => p.id === profile.id);
    currentProfiles.splice(profileIndex, 1);
    setProfiles(currentProfiles);
  }

  /**
   *
   * @param {Profile} profile
   */
  async function addAlertProfile(profile, oldProfile) {
    let alertProfileEmail = true;
    let alertProfileText = true;
    let alertProfileAllLocations = true;
    let alertProfileFrequencies = [
      {
        id: null,
        frequency: "Hourly",
        checked: true,
      },
      {
        id: null,
        frequency: "Daily",
        checked: true,
      },
      {
        id: null,
        frequency: "Weekly",
        checked: true,
      },
      {
        id: null,
        frequency: "Monthly",
        checked: true,
      },
    ];
    let alertProfileLocations = [];

    if (oldProfile) {
      alertProfileEmail = oldProfile.alert_profile.do_email;
      alertProfileText = oldProfile.alert_profile.do_text;
      alertProfileAllLocations = oldProfile.alert_profile.do_all_locations;
      alertProfileFrequencies = oldProfile.alert_profile.frequencies;
      alertProfileLocations = oldProfile.alert_profile.locations;
    }

    if (profile.alert_profile !== null && profile.alert_profile !== undefined) {
      const success = await ProfileAPI.deleteAlertProfile(
        access_token,
        profile.alert_profile.id
      );
      if (!success) {
        return;
      }
    }
    const alert_profile = await ProfileAPI.addAlertProfile(access_token, {
      do_all_locations: alertProfileAllLocations,
      do_email: alertProfileEmail,
      do_text: alertProfileText,
      profile: profile.id,
    });

    alert_profile.frequencies = alertProfileFrequencies;
    alert_profile.locations = alertProfileLocations;

    if (alert_profile !== undefined) {
      if (oldProfile !== undefined) {
        oldProfile.alert_profile.frequencies.map(async frequency => {
          if (frequency.checked) {
            await addAlertProfileFrequency(
              alert_profile.id,
              frequency.frequency
            );
          }
        });
        oldProfile.alert_profile.locations.map(async site => {
          if (site.checked) {
            await addAlertProfileLocation(alert_profile.id, site.site_code);
          }
        });
      }
    }
    profile.alert_profile = alert_profile;
    await updateLocalProfiles(profile);
    return alert_profile;
  }

  /**
   *
   * @param {number} alert_profile_id
   */
  async function addAlertProfileLocation(alert_profile_id, site_code) {
    const alert_profile_location = await ProfileAPI.addAlertProfileLocation(
      access_token,
      {
        site_code: site_code,
        alert_profile: alert_profile_id,
      }
    );

    return alert_profile_location;
  }

  async function addAlertLocations(profile) {
    const sites = await SitesAPI.getSites(access_token);
    let locations = [...profile.alert_profile.locations];
    // TODO: Refactor
    sites.forEach(site => {
      let siteMatched = false;
      if (locations.length > 0) {
        locations.forEach(prof => {
          if (site.code === prof.site_code) {
            prof.checked = true;
            siteMatched = true;
          }
        });
      }
      if (!siteMatched) {
        locations.push({
          id: null,
          site_code: site.code,
          checked: false,
        });
      }
    });
    return locations.sort(sortLocations);
  }

  const addAlertLocationsCallback = useCallback(addAlertLocations, []);

  /**
   *
   * @param {number} alert_profile_id
   */
  async function addAlertProfileFrequency(
    alert_profile_id,
    frequencyValue = "DAILY"
  ) {
    const alert_profile_frequency = await ProfileAPI.addAlertProfileFrequency(
      access_token,
      {
        frequency: frequencyValue.toUpperCase(),
        alert_profile: alert_profile_id,
      }
    );

    return alert_profile_frequency;
  }

  async function addAlertFrequencies(profile) {
    const frequencies = ["Hourly", "Daily", "Weekly", "Monthly"];
    // TODO: Refactor
    frequencies.forEach(frequency => {
      let frequencyMatched = false;
      if (profile.alert_profile.frequencies.length > 0) {
        profile.alert_profile.frequencies.forEach(prof => {
          if (frequency.toUpperCase() === prof.frequency.toUpperCase()) {
            prof.checked = true;
            frequencyMatched = true;
          }
        });
      }
      if (!frequencyMatched) {
        profile.alert_profile.frequencies.push({
          id: null,
          frequency: frequency,
          checked: false,
        });
      }
    });
    return profile.alert_profile.frequencies.sort(sortFrequencies);
  }

  const addAlertFrequenciesCallback = useCallback(addAlertFrequencies, []);

  function sortProfiles(a, b) {
    if (a.first_name > b.first_name) return 1;
    if (a.first_name < b.first_name) return -1;
    return 0;
  }

  function sortLocations(a, b) {
    if (a.site_code > b.site_code) return 1;
    if (a.site_code < b.site_code) return -1;
    return 0;
  }

  function sortFrequencies(a, b) {
    const frequencies = ["hourly", "daily", "weekly", "monthly"];
    let aIndex = indexOf(frequencies, a.frequency.toLowerCase());
    let bIndex = indexOf(frequencies, b.frequency.toLowerCase());

    if (aIndex > bIndex) return 1;
    if (aIndex < bIndex) return -1;
    return 0;
  }

  function handleProfileChange(profile) {
    // replace profile and re-set all profiles
    let profileList = cloneDeep(profiles);
    let profileIndex = profileList.findIndex(p => p.id === profile.id);
    profileList[profileIndex] = profile;
    setProfiles(profileList);
  }

  const handleSave = async profile => {
    await updateProfile(profile);
  };

  function cancelProfile(profile) {
    window.location.reload();
  }
  // END COMPONENT CALLBACKS

  // START COMPONENT EFFECTS

  useEffect(
    function() {
      async function getAndSetProfiles() {
        return ProfileAPI.getProfiles(access_token).then(function(profiles) {
          if (profiles !== undefined) {
            profiles = profiles.sort(sortProfiles);
            profiles.forEach(async profile => {
              const alertFrequencies = await addAlertFrequenciesCallback(
                profile
              );
              profile.alert_profile.frequencies = alertFrequencies;
              const alertLocations = await addAlertLocationsCallback(profile);
              profile.alert_profile.locations = alertLocations;
            });
            setProfiles(profiles);
          }
        });
      }

      getAndSetProfiles();
      // Only run this function on first render
    },
    [access_token, addAlertFrequenciesCallback, addAlertLocationsCallback]
  );

  // END COMPONENT EFFECTS

  return (
    <div className="alerts-profile">
      <div className="inner">
        <div className="alerts-profile-intro">
          <h2>
            Simply click on a name below to view and/or edit that person’s alert
            settings. Use the “Add new user” button to add a new person and
            configure their alerts.
          </h2>
          <hr />
        </div>
        <div>
          <h2>CURRENT USERS:</h2>
          {profiles.map(profile => (
            <AlertProfile
              key={profile.id}
              profile={profile}
              isActive={activeProfile === profile.id}
              onSaveProfile={profile => handleSave(profile)}
              onCancelProfile={profile => cancelProfile(profile)}
              onDeleteProfile={profile => deleteProfile(profile)}
              onClickProfile={profileId => toggleProfile(profileId)}
              onChangeProfile={profile => handleProfileChange(profile)}
              saveStatus={saveStatus}
            />
          ))}
        </div>
        <div className="alerts-profile-add">
          <p>Need to add a new user?</p>
          <button onClick={addProfile}>
            <span className="indicator">+</span> Add New User
          </button>
        </div>
      </div>
    </div>
  );
}

export default connect(function(state) {
  return {
    access_token: state.auth.auth.access_token,
  };
})(Alerts);
