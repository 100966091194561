/**
 * @typedef {import("../../utils/storeUtils").Action} Action
 */

export const SET_VERSION = "SET_VERSION";

/**
 *
 * @param {string} version
 * @returns {Action}
 */
export function setVersion(version) {
  return {
    type: SET_VERSION,
    payload: version,
  };
}
