import React from "react";
import { connect } from "react-redux";
import Icons from "../../components/icons";
import { login } from "../../store/auth";
import "./login.scss";

class Login extends React.Component {
  render() {
    const { loginFn } = this.props;
    return (
      <div id="login">
        <div className="login main-content">
          <div className="login inner-wrapper">
            <Icons name="logo" />
            <p className="mt-5 mb-4">
              Welcome! Please click the{" "}
              <strong className="highlight">Login</strong> button to see your
              dashboard.
            </p>
            <div className="login-button">
              <button onClick={loginFn}>Log In</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, dispatch => ({
  loginFn: () => dispatch(login()),
}))(Login);
