import { stringifyQueryParams, fetchAndDispatch } from "../utils/apiUtils";

/**
 * @typedef {object} GetChurnRateOptions
 * @property {"chart" | "interval" | "single"} type
 */

/**
 *
 * @param {string} accessToken Auth0 Access Token
 * @param {GetChurnRateOptions} options
 */
export async function getChurnRate(accessToken, options = {}) {
  const query = stringifyQueryParams(options);

  const data = await fetchAndDispatch(
    `${process.env.REACT_APP_API_URL}/churnrate/?${query}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return data;
}
