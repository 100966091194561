import { stringifyQueryParams, fetchAndDispatch } from "../utils/apiUtils";
/**
 *
 * @param {string} accessToken Auth0 Access Token
 * @param {string} washMixType The ending tag to wash mix endpoint. Options("sold", "retail", or "recharge")
 */
export async function getWashMix(accessToken, washMixType, options = {}) {
  const query = stringifyQueryParams(options);
  const washMixEndingTagOptions = ["sold", "retail", "recharge"];

  if (washMixEndingTagOptions.indexOf(washMixType) === -1) {
    console.error(`/wash_mix_${washMixType} is not a valid endpoint.`);
  } else {
    const data = await fetchAndDispatch(
      `${process.env.REACT_APP_API_URL}/wash_mix_${washMixType}/?${query}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return data;
  }
}

export async function getWashMixRecharge(accessToken, options = {}) {
  const query = stringifyQueryParams(options);

  const data = await fetchAndDispatch(
    `${process.env.REACT_APP_API_URL}/wash_mix_recharge/?${query}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return data.wash_mix_recharge;
}

/**
 *
 * @param {string} accessToken Auth0 Access Token
 */
export async function getWashMixSold(accessToken, options = {}) {
  const query = stringifyQueryParams(options);

  const data = await fetchAndDispatch(
    `${process.env.REACT_APP_API_URL}/wash_mix_sold/?${query}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return data.wash_mix_sold;
}

/**
 *
 * @param {string} accessToken Auth0 Access Token
 */
export async function getWashMixRetail(accessToken, options = {}) {
  const query = stringifyQueryParams(options);

  const data = await fetchAndDispatch(
    `${process.env.REACT_APP_API_URL}/wash_mix_retail/?${query}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return data.wash_mix_retail;
}
