import * as d3 from "d3"; // eslint-disable-line no-unused-vars
import { whichDataType } from "../api";
import { GROUP_BY } from "./filterUtils";
/**
 * @typedef {object} Action
 * @property {string} type
 * @property {any} payload
 */

/**
 * @typedef {import("../utils/filterUtils").DateRange} DateRange
 */

/**
 * @typedef {object} FilterConfigOption
 * @property {string} title
 * @property {BaseParams} options
 * @property {d3.TimeInterval} [xTicks] d3 ticks function `d3.timeDay.every(1)`
 * @property {string} [xTickFormat]
 * @property {(d: any) => boolean} [xTickFilter]
 * @property {d3.CountableTimeInterval} [timeInterval]
 */

/**
 * @typedef {Object} BaseParams
 * @property {number} [period]
 * @property {'day' | 'week' | 'week_to_date' | 'month_full' | 'month' | 'year' | 'hour'} [period_unit]
 * @property {(today: Date?) => DateRange} [getDateRange]
 * @property {DateRange} [date_range]
 * @property {string} [group_by]
 */

/**
 *
 * @typedef {Object} BaseState
 * @property {boolean} loading: false,
 * @property {Date} [lastUpdated] null,
 * @property {object[]} [data] null,
 * @property {string} [info] null,
 * @property {string} [error] null,
 * @property {FilterConfigOption[]} filters,
 * @property {FilterConfigOption} [filter] undefined,
 * @property {number} [selectedFilterId] filters.length > 0 ? 0 : undefined,
 * @property {{ start_date: Date, end_date: Date }} dateRange
 * @property {string} groupBy: GROUP_BY.month.id,
 */

/**
 *
 * @param {string} basename Prefix used in Redux store. Snake case preferred: `your_data_type`
 * @param {FilterConfigOption[]} filters
 */
export function createDataActions(basename, filters = []) {
  const basenameUpper = basename.toUpperCase();
  const actions = {
    REQUEST_DATA: `${basenameUpper}_REQUEST_DATA`,
    DATA_LOADED: `${basenameUpper}_DATA_LOADED`,
    DATA_FAILED: `${basenameUpper}_DATA_FAILED`,
    SET_FILTER_ID: `${basenameUpper}_SET_FILTER_ID`,
    SET_DATE_RANGE: `${basenameUpper}_SET_DATE_RANGE`,
    GENERATE_CSV: `${basenameUpper}_GENERATE_CSV`,
  };

  const actionCreators = {
    /**
     *
     * @param {BaseParams} options
     */
    requestData: function requestData(options) {
      return {
        type: actions.REQUEST_DATA,
        payload: options,
      };
    },
    dataLoaded: function dataLoaded(data) {
      return {
        type: actions.DATA_LOADED,
        payload: data,
      };
    },
    dataFailed: function dataFailed(error) {
      return {
        type: actions.DATA_FAILED,
        payload: error,
      };
    },
    /**
     *
     * @param {number} selectedFilterId
     * @param {DateRange} [dateRange]
     */
    setFilterId: function setFilterId(selectedFilterId, dateRange, groupBy) {
      let payload = {
        selectedFilterId,
      };

      if (dateRange) {
        payload["dateRange"] = dateRange;
      }

      if (groupBy) {
        payload["groupBy"] = groupBy;
      }

      return {
        type: actions.SET_FILTER_ID,
        payload,
      };
    },
    generateCSV: function requestData(options) {
      return {
        type: actions.GENERATE_CSV,
        payload: options,
      };
    },
    /**
     *
     * @param {DateRange} date_range
     */
    setDateRange: function setDateRange(date_range) {
      return {
        type: actions.SET_DATE_RANGE,
        payload: date_range,
      };
    },
  };

  /**
   * @type {BaseState}
   */
  const defaultState = {
    loading: false,
    lastUpdated: null,
    data: null,
    info: null,
    error: null,
    filters,
    /**
     * @type {FilterConfigOption}
     */
    filter: undefined,
    selectedFilterId: filters.length > 0 ? 0 : undefined,
    dateRange: {
      start_date: new Date(),
      end_date: new Date(),
    },
    groupBy: GROUP_BY.month.id,
  };

  /**
   *
   * @param {BaseState} state
   * @param {*} action
   * @returns {BaseState}
   */
  const reducer = (state = defaultState, action) => {
    switch (action.type) {
      case actions.REQUEST_DATA:
        return {
          ...state,
          loading: true,
        };
      case actions.DATA_LOADED:
        const which = whichDataType(action.payload);
        let info = null;
        let data = undefined;
        if (which === "StandardInfoDataList") {
          info = action.payload.info;
          data = action.payload.data;
        } else {
          data = action.payload;
        }

        return {
          ...state,
          info,
          data,
          loading: false,
          error: undefined,
          lastUpdated: Date.now(),
        };
      case actions.DATA_FAILED:
        return {
          ...state,
          data: undefined,
          loading: false,
          error: action.payload,
        };
      case actions.SET_FILTER_ID:
        let filter = { ...state.filters[action.payload.selectedFilterId] };
        let options = { ...filter.options } || {};
        // Some filters (like custom date range) will instead send a dateRange instead of having period/period_unit options
        // Update the filter to include them
        if (action.payload.hasOwnProperty("dateRange")) {
          options = {
            ...options,
            ...action.payload.dateRange,
          };
        }

        if (action.payload.hasOwnProperty("groupBy")) {
          options = {
            ...options,
            group_by: action.payload.groupBy,
          };
        }

        filter.options = options;

        return {
          ...state,
          ...action.payload,
          filter,
        };
      case actions.SET_DATE_RANGE:
        return {
          ...state,
          dateRange: action.payload,
        };
      default:
        return state;
    }
  };
  return {
    actions,
    actionCreators,
    reducer,
  };
}
