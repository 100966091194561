import React from "react";
import { connect } from "react-redux";
import { actionCreators } from "../../store/customersByStatus";
import RankedTable from "../rankedTable";

const columns = [
  {
    name: "Status",
    path: ["name"],
    sortable: true,
  },
  {
    name: "Customers",
    path: ["customers"],
    sortable: true,
  },
];

class CustomersByStatus extends React.Component {
  render() {
    const {
      data,
      requestData,
      dataLoaded,
      dataFailed,
      filters,
      selectedFilterId,
      setFilterId,
      loading,
      error,
      sites,
    } = this.props;

    return (
      <div
        className={`dashboard-widget
        ${error ? "error" : ""}        
        ${loading ? "loading" : ""} cc-chart-wrapper`}
      >
        <RankedTable
          requestData={requestData}
          dataLoaded={dataLoaded}
          dataFailed={dataFailed}
          title="Customers In Problem Statuses"
          filters={filters}
          selectedFilterId={selectedFilterId}
          setFilterId={setFilterId}
          config={{
            columns: columns,
            rowIdPath: ["status"],
          }}
          sites={sites}
          {...data}
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    data: state.customersByStatus,
    lastUpdated: state.customersByStatus.lastUpdated,
    filters: state.customersByStatus.filters,
    selectedFilterId: state.customersByStatus.selectedFilterId,
    loading: state.customersByStatus.loading,
    error: state.customersByStatus.error,
    sites: state.sites,
  }),
  dispatch => ({
    requestData: options => dispatch(actionCreators.requestData(options)),
    dataLoaded: () => dispatch(actionCreators.dataLoaded()),
    dataFailed: () => dispatch(actionCreators.dataFailed()),
    setFilterId: id => dispatch(actionCreators.setFilterId(id)),
  })
)(CustomersByStatus);
