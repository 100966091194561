import React from "react";
import { get } from "lodash";

export default class SiteSelectedAndFiltered extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sort: undefined,
    };
  }

  toggleSort(col) {
    const { sort } = this.state;

    if (!sort || sort.name !== col.name || sort.sort === undefined) {
      // Only one sort allowed at a time so we overwrite the sort object
      this.setState(state => ({
        sort: {
          ...col,
          sort: "desc",
        },
      }));
    } else if (sort.sort === "desc") {
      this.setState(state => ({
        sort: {
          ...col,
          sort: "asc",
        },
      }));
    } else {
      this.setState(state => ({
        sort: {
          ...col,
          sort: undefined,
        },
      }));
    }
  }

  getSortedData(data) {
    const { sort } = this.state;
    let sortedData = data;
    if (sort.sort === "asc") {
      sortedData = sortedData.sort((a, b) => {
        const av = get(a, sort.path);
        const bv = get(b, sort.path);
        if (av < bv) return -1;
        if (av > bv) return 1;

        return 0;
      });
    } else if (sort.sort === "desc") {
      sortedData = sortedData.sort((a, b) => {
        const av = get(a, sort.path);
        const bv = get(b, sort.path);
        if (av < bv) return 1;
        if (av > bv) return -1;

        return 0;
      });
    }

    return sortedData;
  }

  componentDidMount() {
    this.requestData();
  }

  componentDidUpdate(prevProps) {
    if (this.haveSelectedSitesChanged(prevProps)) {
      this.requestData();
    }
  }

  haveSelectedSitesChanged(prevProps) {
    const { sites } = this.props;
    // Compare selectedSiteIds. If they are different,
    // we need to refetch data.
    let hasEqualSiteIds = true;
    if (
      sites.selectedSiteIds.length !== prevProps.sites.selectedSiteIds.length
    ) {
      hasEqualSiteIds = false;
    } else {
      for (let i = 0; i < sites.selectedSiteIds.length; i++) {
        const siteId = sites.selectedSiteIds[i];
        const prevSiteId = prevProps.sites.selectedSiteIds[i];
        if (siteId !== prevSiteId) {
          hasEqualSiteIds = false;
          break;
        }
      }
    }

    return !hasEqualSiteIds;
  }

  requestData() {
    this.props.requestData();
  }

  /**
   *
   * @param {number} selectedFilterId
   * @param {import("../../utils/filterUtils").DateRange} [dateRange]
   * @param {string} [groupBy]
   */
  onChangeFilter(selectedFilterId, dateRange, groupBy) {
    this.props.setFilterId(selectedFilterId, dateRange, groupBy);
  }

  getSelectedSitesData() {
    const {
      data,
      sites: { selectedSiteIds },
    } = this.props;

    if (data && selectedSiteIds && selectedSiteIds.length > 0) {
      if (!data.filter) {
        console.log("no filter, what is data:");
        console.log(data);
        console.log("sites:", selectedSiteIds);
        console.trace();
      }
      return data.filter(datum => selectedSiteIds.indexOf(datum.site.id) > -1);
    } else {
      return data;
    }
  }
}
