import { takeLatest, put, select } from "redux-saga/effects";
import {
  selectAuth,
  checkLoggedIn,
  generateSelectDataState,
  generateSelectedSiteIds,
} from "utils/utils";
import { actions, actionCreators } from "./reducers";
import { WashMixAPI } from "../../api";
import { getAPIOptionsFromDataState } from "utils/filterUtils";

function mapTrend(washSales) {
  return washSales.sort((b, a) => {
    if (a.wash_count < b.wash_count) {
      return -1;
    } else if (a.wash_count > b.wash_count) {
      return 1;
    } else {
      return 0;
    }
  });
}

function* requestData(action) {
  // check logged in
  const auth = yield select(selectAuth);
  const loggedIn = checkLoggedIn(auth);

  if (loggedIn) {
    try {
      const dataState = yield select(
        generateSelectDataState("washMixRechargeTable")
      );
      const selectedSiteIds = yield select(generateSelectedSiteIds());
      const options = getAPIOptionsFromDataState(dataState, selectedSiteIds);

      const washMixRechargeSuffix = "recharge";

      const washCounts = yield WashMixAPI.getWashMix(
        auth.access_token,
        washMixRechargeSuffix,
        {
          type: "table",
          ...options,
        }
      );

      yield put(
        actionCreators.dataLoaded({
          ...washCounts,
          data: mapTrend(washCounts.wash_mix_recharge),
        })
      );
    } catch (e) {
      yield put(actionCreators.dataFailed(e.toString()));
    }
  }
}

function* requestDataSaga() {
  yield takeLatest(actions.REQUEST_DATA, requestData);
}

function* onSetFilterId(action) {
  yield put(actionCreators.requestData());
}

function* setFilterSaga() {
  yield takeLatest(actions.SET_FILTER_ID, onSetFilterId);
}

export const SAGAS = [requestDataSaga(), setFilterSaga()];
