import { SAGAS as AUTH_SAGAS } from "./auth";
import { SAGAS as WASH_SALES_SAGAS } from "./washSales";
import { SAGAS as WASH_SALES_TABLE_SAGAS } from "./washSalesTable";
import { SAGAS as ARM_SALES_LEADERBOARD_SAGAS } from "./armSalesLeaderboard";
import { SAGAS as ARM_SALES_TABLE_SAGAS } from "./armSalesTable";
import { SAGAS as ARM_SALES_CHART_SAGAS } from "./armSalesChart";
import { SAGAS as CAPTURE_RATE_SAGAS } from "./captureRate";
import { SAGAS as CHURN_RATE_SAGAS } from "./churnRate";
import { SAGAS as CHURN_RATE_INTERVAL_SAGAS } from "./churnRateInterval";
import { SAGAS as RECHARGE_SAGAS } from "./recharge";
import { SAGAS as NET_MEMBERS_SAGAS } from "./netMembers";
import { SAGAS as TOTAL_DEPOSITS_SAGAS } from "./totalDepositsInterval";
import { SAGAS as SITES_SAGAS } from "./sites";
import { SAGAS as INFO_SAGAS } from "./info";
import { SAGAS as RECHARGE_TICKET_AVG_SAGAS } from "./rechargeTicketAvgChart";
import { SAGAS as SINGLE_WASH_TICKET_AVG_CHART_SAGAS } from "./singleWashTicketAvgChart";
import { SAGAS as SINGLE_WASH_TICKET_AVG_SAGAS } from "./singleWashTicketAvg";
import { SAGAS as GROSS_TICKET_PER_CAR_SAGAS } from "./grossTicketPerCarChart";
import { SAGAS as CHURN_RATE_BY_WASH_PACKAGE_SAGAS } from "./churnRateByWashPackageTable";
import { SAGAS as WASH_MIX_RECHARGE_SAGAS } from "./washMixRechargeTable";
import { SAGAS as WASH_MIX_SOLD_SAGAS } from "./washMixSoldTable";
import { SAGAS as WASH_MIX_RETAIL_SAGAS } from "./washMixRetailTable";
import { SAGAS as CHANGE_PASSWORD_SAGAS } from "./changePassword";
import { SAGAS as CANCELLATIONS_SAGAS } from "./cancellations";
import { SAGAS as HISTORICAL_CHURN_RATE_CHART_SAGAS } from "./historicalChurnRateChart";
import { SAGAS as HISTORICAL_CAPTURE_RATE_CHART_SAGAS } from "./historicalCaptureRateChart";
import { SAGAS as CURRENT_MONTH_CHURN_PACE_TABLE_SAGAS } from "./currentMonthChurnPaceTable";
import { SAGAS as MONTHLY_KPI_TABLE_SAGAS } from "./monthlyKPITable";
import { SAGAS as CC_STATUS_CHANGED_TABLE_SAGAS } from "./ccStatusChangedTable";
import { SAGAS as CC_STATUS_LIST_DECLINED_SAGAS } from "./creditCardStatusListDeclined";
import { SAGAS as CC_STATUS_LIST_EXPIRED_SAGAS } from "./creditCardStatusListExpired";
import { SAGAS as CC_STATUS_LIST_EXPIRING_SAGAS } from "./creditCardStatusListExpiring";
import { SAGAS as CUSTOMERS_BY_STATUS_SAGAS } from "./customersByStatus";

const sagas = [
  ...AUTH_SAGAS,
  ...WASH_SALES_SAGAS,
  ...WASH_SALES_TABLE_SAGAS,
  ...ARM_SALES_LEADERBOARD_SAGAS,
  ...ARM_SALES_TABLE_SAGAS,
  ...ARM_SALES_CHART_SAGAS,
  ...CAPTURE_RATE_SAGAS,
  ...CHURN_RATE_SAGAS,
  ...CHURN_RATE_INTERVAL_SAGAS,
  ...RECHARGE_SAGAS,
  ...NET_MEMBERS_SAGAS,
  ...TOTAL_DEPOSITS_SAGAS,
  ...SITES_SAGAS,
  ...INFO_SAGAS,
  ...RECHARGE_TICKET_AVG_SAGAS,
  ...SINGLE_WASH_TICKET_AVG_CHART_SAGAS,
  ...SINGLE_WASH_TICKET_AVG_SAGAS,
  ...GROSS_TICKET_PER_CAR_SAGAS,
  ...CHURN_RATE_BY_WASH_PACKAGE_SAGAS,
  ...WASH_MIX_RECHARGE_SAGAS,
  ...WASH_MIX_SOLD_SAGAS,
  ...WASH_MIX_RETAIL_SAGAS,
  ...CHANGE_PASSWORD_SAGAS,
  ...CANCELLATIONS_SAGAS,
  ...HISTORICAL_CHURN_RATE_CHART_SAGAS,
  ...HISTORICAL_CAPTURE_RATE_CHART_SAGAS,
  ...CURRENT_MONTH_CHURN_PACE_TABLE_SAGAS,
  ...MONTHLY_KPI_TABLE_SAGAS,
  ...CC_STATUS_CHANGED_TABLE_SAGAS,
  ...CC_STATUS_LIST_DECLINED_SAGAS,
  ...CC_STATUS_LIST_EXPIRED_SAGAS,
  ...CC_STATUS_LIST_EXPIRING_SAGAS,
  ...CUSTOMERS_BY_STATUS_SAGAS,
];

export default sagas;
