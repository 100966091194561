import moment from "moment";
import * as d3 from "d3";

/**
 * @typedef {import("./storeUtils").BaseParams} BaseParams
 */

/**
 * @typedef {import("./storeUtils").FilterConfigOption} FilterConfigOption
 */

/**
 * @typedef {Object} DateRange
 * @property {string} start_date
 * @property {string} end_date
 */

/**
 * @returns {DateRange}
 */
function lastXDaysRange(today = new Date(), days) {
  const start_moment = moment(today);
  start_moment.subtract(days - 1, "days");
  start_moment.startOf("day");

  const end_moment = moment(today);
  end_moment.endOf("day");

  return {
    start_date: start_moment.toISOString(),
    end_date: end_moment.toISOString(),
  };
}

/**
 * @returns {DateRange}
 */
export function last7DaysRange(today = new Date()) {
  return lastXDaysRange(today, 7);
}

/**
 * @returns {DateRange}
 */
export function last30DaysRange(today = new Date()) {
  return lastXDaysRange(today, 30);
}

/**
 * @returns {DateRange}
 */
export function last90DaysRange(today = new Date()) {
  return lastXDaysRange(today, 90);
}

/**
 * @returns {DateRange}
 */
function lastXMonthsRange(today = new Date(), months) {
  const start_moment = moment(today);
  start_moment.subtract(months, "months");
  start_moment.startOf("month");

  const end_moment = moment(today);
  end_moment.endOf("day");

  return {
    start_date: start_moment.toISOString(),
    end_date: end_moment.toISOString(),
  };
}

/**
 * @returns {DateRange}
 */
export function last12MonthsRange(today = new Date()) {
  return lastXMonthsRange(today, 12);
}

export const GROUP_BY = {
  day: {
    id: "day",
    value: "YYYY-MM-DD",
    name: "Day (YYYY-MM-DD)",
    xTicksInterval: d3.timeDay.every(7),
    xTickFormat: d3.timeFormat("%m/%d/%y"),
  },
  // week: {
  //   id: "week",
  //   value: "YYYY-WW",
  //   name: "Week (YYYY-WW)",
  //   xTicksInterval: d3.timeWeek.every(1),
  //   xTickFormat: d3.timeFormat("%m/%d/%y"),
  //   xTickFilter: (d) => d3.timeDay.count(0, d) % 15 === 0,
  // },
  month: {
    id: "month",
    value: "YYYY-MM",
    name: "Month (YYYY-MM)",
    timeInterval: d3.timeMonth,
    xTicksInterval: d3.timeMonth.every(1),
    xTickFormat: d3.timeFormat("%m/%y"),
  },
  // year: {
  //   id: "year",
  //   value: "YYYY",
  //   name: "Year (YYYY)",
  //   timeInterval: d3.timeYear,
  //   xTicksInterval: d3.timeYear.every(1),
  //   xTickFormat: d3.timeFormat("%y"),
  //   xTickFilter: (d) => d3.timeYear.count(0, d) % 1 === 0,
  // },
};

/**
 * Used by API function with custom dates
 * @param {BaseParams} payload
 * @returns {Object}
 */
export function getAPIOptionsFromPayload(payload, dataState) {
  if (payload) {
    if (
      payload.hasOwnProperty("period") &&
      payload.hasOwnProperty("period_unit")
    ) {
      return {
        period: payload.period,
        period_unit: payload.period_unit,
      };
    } else if (
      payload.hasOwnProperty("group_by") &&
      payload.hasOwnProperty("getDateRange")
    ) {
      return {
        ...payload.getDateRange(),
        group_by: payload.group_by,
      };
    } else if (dataState) {
      return {
        start_date: moment(dataState.dateRange.start_date).toISOString(),
        end_date: moment(dataState.dateRange.end_date).toISOString(),
        group_by: (GROUP_BY[dataState.groupBy] || GROUP_BY.month).value,
      };
    }
  } else if (dataState) {
    return {
      start_date: moment(dataState.dateRange.start_date).toISOString(),
      end_date: moment(dataState.dateRange.end_date).toISOString(),
      group_by: (GROUP_BY[dataState.groupBy] || GROUP_BY.month).value,
    };
  }

  return undefined;
}

/**
 *
 * @param {FilterConfigOption} filter
 * @returns
 */
export function getAPIOptionsFromDataState(dataState = {}, siteIds = []) {
  let filter = dataState.filter;
  if (!filter) {
    filter = dataState.filters[dataState.selectedFilterId];
  }

  let options = { ...filter.options } || {};

  if (options.hasOwnProperty("start_date")) {
    options.start_date = moment(options.start_date).toISOString();
  }
  if (options.hasOwnProperty("end_date")) {
    options.end_date = moment(options.end_date).toISOString();
  }
  if (options.hasOwnProperty("group_by")) {
    options.group_by = (GROUP_BY[options.group_by] || GROUP_BY.month).value;
  }

  options.siteid = siteIds;

  return options;
}
