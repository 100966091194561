import React from "react";
import { connect } from "react-redux";
import { actionCreators } from "../../store/monthlyKPITable";
import RankedTable from "../rankedTable";
import ExportCSVButton from "../exportCsvButton";
import "./monthlyKPI.scss";

const dateFormatter = v => {
  return v.toLocaleString("en", {
    month: "long",
    year: "numeric",
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
};

const moneyFormatter = v => {
  if (v != null) {
    return (
      "$" +
      v.toLocaleString("en", {
        useGrouping: true,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  }
};

const percentageFormatter = v => {
  if (v != null) {
    return v.toLocaleString("en", {
      useGrouping: true,
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
};

class MonthlyKPIChart extends React.Component {
  render() {
    const {
      data,
      requestData,
      dataLoaded,
      dataFailed,
      filters,
      selectedFilterId,
      setFilterId,
      loading,
      error,
      sites,
      generateCSV,
    } = this.props;

    async function onClickCSV() {
      generateCSV();
    }

    let columnLegend = null;
    if (data.data != null) {
      columnLegend = data.data.columnlegend;
    }

    return (
      <div
        className={`dashboard-widget 
          ${error ? "error" : ""}        
          ${loading ? "loading" : ""} monthly-kpi-container`}
      >
        <div className="monthly-kpi-table">
          <RankedTable
            requestData={requestData}
            dataLoaded={dataLoaded}
            dataFailed={dataFailed}
            title="Monthly KPI"
            filters={filters}
            selectedFilterId={selectedFilterId}
            setFilterId={setFilterId}
            loading={loading}
            config={{
              columns: [
                {
                  name: "DATE",
                  path: ["date"],
                  sortable: true,
                  formatter: dateFormatter,
                },
                {
                  name: "WASH SALES QUANTITY",
                  path: ["wash_sales_quantity"],
                  sortable: true,
                },
                {
                  name: "WASH SALES AMOUNT",
                  path: ["wash_sales_amount"],
                  sortable: true,
                  formatter: moneyFormatter,
                },
                {
                  name: "ARM REDEEMED QUANTITY",
                  path: ["redeemed_quantity"],
                  sortable: true,
                },
                {
                  name: "ARM REDEEMED AMOUNT",
                  path: ["redeemed_amount"],
                  sortable: true,
                  formatter: moneyFormatter,
                },
                {
                  name: "DISCOUNTED QUANTITY",
                  path: ["discounted_quantity"],
                  sortable: true,
                },
                {
                  name: "DISCOUNTED AMOUNT",
                  path: ["discounted_amount"],
                  sortable: true,
                  formatter: moneyFormatter,
                },
                {
                  name: "PREPAID REDEEMED QUANTITY",
                  path: ["prepaid_redeem_quantity"],
                  sortable: true,
                },
                {
                  name: "PREPAID REDEEMED AMOUNT",
                  path: ["prepaid_redeem_amount"],
                  sortable: true,
                  formatter: moneyFormatter,
                },
                {
                  name: "ARM SALES QUANTITY",
                  path: ["arm_sales_quantity"],
                  sortable: true,
                },
                {
                  name: "ARM SALES AMOUNT",
                  path: ["arm_sales_amount"],
                  sortable: true,
                  formatter: moneyFormatter,
                },
                {
                  name: "ARM RECHARGE QUANTITY",
                  path: ["arm_recharge_quantity"],
                  sortable: true,
                },
                {
                  name: "ARM RECHARGE AMOUNT",
                  path: ["arm_recharge_amount"],
                  sortable: true,
                  formatter: moneyFormatter,
                },
                {
                  name: "AVERAGE ARM AMOUNT",
                  path: ["average_arm"],
                  sortable: true,
                  formatter: moneyFormatter,
                },
                {
                  name: "REVENUE (Total Deposits)",
                  path: ["revenue"],
                  sortable: true,
                  formatter: moneyFormatter,
                },
                {
                  name: "RETAIL WASHES",
                  path: ["retail_quantity"],
                  sortable: true,
                },
                {
                  name: "RETAIL AMOUNT",
                  path: ["retail_amount"],
                  sortable: true,
                  formatter: moneyFormatter,
                },
                {
                  name: "AVERAGE COST OF TICKET",
                  path: ["average_ticket_cost"],
                  sortable: true,
                  formatter: moneyFormatter,
                },
                {
                  name: "DISCONTINUED",
                  path: ["discontinued_quantity"],
                  sortable: true,
                },
                {
                  name: "TERMINATED",
                  path: ["terminated_quantity"],
                  sortable: true,
                },
                {
                  name: "SWITCHED",
                  path: ["switched_quantity"],
                  sortable: true,
                },
                {
                  name: "CAPTURE RATE",
                  path: ["capture_rate"],
                  sortable: true,
                  formatter: percentageFormatter,
                },
                {
                  name: "NET MEMBER COUNT",
                  path: ["net_member_count"],
                  sortable: true,
                },
                {
                  name: "CHURN RATE",
                  path: ["churn_rate"],
                  sortable: true,
                  formatter: percentageFormatter,
                },
              ],
              rowIdPath: ["date"],
            }}
            sites={sites}
            {...data.data}
          />
        </div>
        <ExportCSVButton onClickButton={onClickCSV} />
        <div className="column-legend">
          <div className="column-legend-title">
            Column Information and Calculations:
          </div>
          <div className="small">
            Any categories/items below come from SiteWatch DB, may be named
            differently than you see them in SiteWatch Web GSR.
          </div>
          <div>
            <table className="table monthly-kpi-legend-table">
              <thead>
                <tr>
                  <th>KPI</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {columnLegend &&
                  columnLegend.map((legend, i) => {
                    return (
                      <tr key={legend.name.replace(/_/g, " ").toUpperCase()}>
                        <td>{legend.name.replace(/_/g, " ").toUpperCase()}</td>
                        <td>{legend.value}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    data: state.monthlyKPITable,
    lastUpdated: state.monthlyKPITable.lastUpdated,
    filters: state.monthlyKPITable.filters,
    selectedFilterId: state.monthlyKPITable.selectedFilterId,
    loading: state.monthlyKPITable.loading,
    error: state.monthlyKPITable.error,
    sites: state.sites,
  }),
  dispatch => ({
    requestData: options => dispatch(actionCreators.requestData(options)),
    dataLoaded: () => dispatch(actionCreators.dataLoaded()),
    dataFailed: () => dispatch(actionCreators.dataFailed()),
    setFilterId: id => dispatch(actionCreators.setFilterId(id)),
    generateCSV: () => dispatch(actionCreators.generateCSV()),
  })
)(MonthlyKPIChart);
