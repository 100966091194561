import { createDataActions } from "utils/storeUtils";

/**
 * @typedef {import("../../utils/storeUtils").FilterConfigOption} FilterConfigOption
 */

/**
 * @type {FilterConfigOption[]}
 */
const CAPTURE_RATE_FILTERS = [
  {
    title: "Last 14 Days",
    options: {
      period: 14,
      period_unit: "day",
    },
  },
  {
    title: "Last 28 Days",
    options: {
      period: 28,
      period_unit: "day",
    },
  },
  // Yesterday
  // This Month
  // This Week
  // This Quarter
];

export const { actions, actionCreators, reducer } = createDataActions(
  "capture_rate",
  CAPTURE_RATE_FILTERS
);
