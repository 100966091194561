import React, { Component } from "react";
import PropTypes from "prop-types";
import "./intervalTooltip.scss";

class IntervalTooltip extends Component {
  render() {
    const {
      currentDate,
      pastDate,
      label,
      value,
      changeFormatted,
      changeRaw,
    } = this.props;

    return (
      <div className="interval-tooltip-container">
        <div>
          <span className="date">
            {currentDate} vs {pastDate}
          </span>
        </div>
        <div>
          <span className="text">{label}</span>
          <span className="value">{value}</span>
          <span
            className={`change ${changeRaw >= 0 ? "positive" : "negative"}`}
          >
            {changeFormatted}
          </span>
        </div>
      </div>
    );
  }
}

IntervalTooltip.propTypes = {
  currentDate: PropTypes.string.isRequired,
  pastDate: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  changeFormatted: PropTypes.string.isRequired,
  changeRaw: PropTypes.number.isRequired,
};

export default IntervalTooltip;
