import { createDataActions } from "utils/storeUtils";
import * as d3 from "d3";

/**
 * @typedef {import("../../utils/storeUtils").FilterConfigOption} FilterConfigOption
 */

/**
 * @type {FilterConfigOption[]}
 */
const ARM_SALES_CHART_FILTERS = [
  {
    title: "Last 7 Days",
    options: {
      period: 7,
      period_unit: "day",
    },
    xTickFormat: d3.timeFormat("%m/%d/%y"),
    xTicksInterval: d3.timeDay.filter(d => d3.timeDay.count(0, d) % 1 === 0),
  },
  {
    title: "Last 30 Days",
    options: {
      period: 30,
      period_unit: "day",
    },
    xTickFormat: d3.timeFormat("%m/%d/%y"),
    xTicksInterval: d3.timeDay.filter(d => d3.timeDay.count(0, d) % 7 === 0),
  },
  {
    title: "Last 90 Days",
    options: {
      period: 90,
      period_unit: "day",
    },
    xTickFormat: d3.timeFormat("%m/%d/%y"),
    xTicksInterval: d3.timeDay.filter(d => d3.timeDay.count(0, d) % 15 === 0),
  },
  {
    title: "Last 12 Months",
    options: {
      period: 12,
      period_unit: "month",
    },
    timeInterval: d3.timeMonth,
    xTickFormat: d3.timeFormat("%m/%y"),
    xTicksInterval: d3.timeMonth.filter(
      d => d3.timeMonth.count(0, d) % 3 === 0
    ),
  },
  {
    title: "Custom Dates",
    custom: true,
  },
];

export const { actions, actionCreators, reducer } = createDataActions(
  "arm_sales_chart",
  ARM_SALES_CHART_FILTERS
);
