import { fetchAndDispatch, stringifyQueryParams } from "../utils/apiUtils";
import { downloadBlob } from "./reports";

/**
 *
 * @param {Array} status array of status codes
 *
 */
function stringifyStatusParams(status) {
  let query = Object.keys(status).map(k => {
    if (Array.isArray(status[k])) {
      return status[k]
        .map(l => encodeURIComponent(k) + "=" + encodeURIComponent(l))
        .join("&");
    } else return encodeURIComponent(k) + "=" + encodeURIComponent(status[k]);
  });
  return query.join();
}

/**
 *
 * @param {string} accessToken Auth0 Access Token
 * @param {string} acceptString Response Type (csv or json)
 *
 */
export async function getCreditCardStatusList(accessToken, options) {
  const query = stringifyQueryParams(options);
  const requestURL = `${process.env.REACT_APP_API_URL}/credit-card-status-list/?${query}`;

  const data = await fetchAndDispatch(requestURL, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return data;
}

export async function getCreditCardStatusListCSV(accessToken, options) {
  const query = stringifyQueryParams(options);
  const requestURL = `${process.env.REACT_APP_API_URL}/credit-card-status-list/?${query}`;
  const data = await fetchAndDispatch(
    requestURL,
    {
      headers: {
        Accept: "text/csv",
        Authorization: `Bearer ${accessToken}`,
      },
    },
    {
      json: false,
    }
  );

  const blob = await data.blob();
  downloadBlob(blob, `churn-burner-status-${options.status}-report.csv`);
  return data;
}
