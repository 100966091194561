import { ACTIONS } from "./actions";

export const INITIAL_STATE = {
  loading: false,
  message: undefined,
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.ATTEMPT_CHANGE_PASSWORD: {
      return { ...state, message: undefined, loading: true };
    }
    case ACTIONS.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        message: action.payload,
        loading: false,
      };
    }
    case ACTIONS.CHANGE_PASSWORD_FAILED: {
      return {
        ...state,
        message: action.payload,
        loading: false,
      };
    }
    default:
      return state;
  }
};
