import { AuthAPI } from "../api";
import PropTypes from "prop-types";
import get from "lodash/get";
/**
 * @typedef {import("../api/auth").UserInfo} UserInfo
 */

export function selectAccessToken(state) {
  if (state.auth.auth !== undefined) {
    return state.auth.auth.access_token;
  } else {
    return undefined;
  }
}
export function selectAuth(state) {
  if (state.auth.auth !== undefined) {
    return state.auth.auth;
  } else {
    return undefined;
  }
}

/**
 *
 * @param {string} selector
 */
export function generateSelectDataState(selector) {
  return function selectDataState(state) {
    if (state.hasOwnProperty(selector)) {
      return state[selector];
    } else {
      return undefined;
    }
  };
}

/**
 *
 */
export function generateSelectedSiteIds() {
  return function selectDataState(state) {
    return get(state, ["sites", "selectedSiteIds"], []);
  };
}

export function generateSelectedSiteSitewatchIds() {
  return function selectDataState(state) {
    const selectedSiteIds = get(state, ["sites", "selectedSiteIds"], []);
    const allSites = get(state, ["sites", "data"], []);
    if (allSites.length === 0) {
      return [];
    }
    const selectedSitewatchSiteIds = selectedSiteIds.map(i => {
      const matchingSite = allSites.find(s => s.id === i);
      if (matchingSite) {
        return matchingSite.sitewatch_id;
      } else {
        return null;
      }
    });
    // go through sites and pull out sitewatch ids
    return selectedSitewatchSiteIds;
  };
}

export function checkLoggedIn(currentAuth) {
  if (currentAuth.expires_at === null) {
    return false;
  } else {
    const expiresAt = JSON.parse(currentAuth.expires_at);
    return new Date().getTime() < expiresAt;
  }
}

/**
 *
 * @param {UserInfo} [userinfo]
 * @returns {boolean}
 */
export function checkIsStaff(userinfo) {
  return userinfo !== null && userinfo !== undefined && userinfo.is_staff;
}

export function convertHashToLoginCallback() {
  return new Promise((resolve, reject) => {
    AuthAPI.auth.parseHash((err, authResult) => {
      const { expiresIn = 0, idToken = "", accessToken = "" } = authResult;
      const expiresAt = JSON.stringify(expiresIn * 1000 + new Date().getTime());

      resolve({
        expires_at: expiresAt,
        id_token: idToken || null,
        access_token: accessToken || null,
      });
    });
  });
}

/**
 * @param {string} accessToken
 * @returns {Promise<UserInfo>} accessToken
 */
export async function getUserFromAuth(accessToken) {
  if (accessToken === null) {
    throw new Error("utils::getUserFromAuth was passed a null access token");
  } else {
    const user = await AuthAPI.getUser(accessToken);
    return user;
  }
}

export const API_URL = process.env["REACT_APP_API_URL"] || "";

export class UnauthorizedError extends Error {
  constructor(message) {
    super(message);
    this.name = "UnauthorizedError";
    this.message = message;
  }
}

export class ConflictError extends Error {
  constructor(message) {
    super(message);
    this.name = "ConflictError";
    this.message = message;
  }
}

export function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else if (response.status === 401 || response.status === 403) {
    const unauthorizedError = new UnauthorizedError(response.statusText);
    unauthorizedError.response = response;
    throw unauthorizedError;
  } else if (response.status === 409) {
    throw new ConflictError(response.statusText);
  } else {
    throw new Error(response.statusText);
  }
}

export const baseComponentPropTypes = {
  requestData: PropTypes.func.isRequired,
  dataLoaded: PropTypes.func.isRequired,
  dataFailed: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  lastUpdated: PropTypes.number,
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
    PropTypes.object,
  ]),
  error: PropTypes.string,
  filters: PropTypes.array.isRequired,
  selectedFilterId: PropTypes.number.isRequired,
  setFilterId: PropTypes.func.isRequired,
  sites: PropTypes.object,
};

/**
 *
 * @param {Date} date
 * @returns {string}
 */
export function toDateInputValue(date) {
  return (
    date.getUTCFullYear() +
    "-" +
    ("0" + (date.getUTCMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getUTCDate()).slice(-2)
  );
}

export function getStringFromDate(dateObj) {
  let year = dateObj.getFullYear();
  let month = dateObj.getMonth() + 1;
  let day = dateObj.getDate();
  let strMonth = "";
  let strDay = "";

  if (month < 10) {
    strMonth = `0${month}`;
  }

  if (day < 10) {
    strDay = `0${day}`;
  }

  return `${year}-${strMonth || month}-${strDay || day}`;
}

export function getTodayDate() {
  let today = new Date();
  return getStringFromDate(today);
}

export function getNumDaysAgo(numDays) {
  let daysAgoDate = new Date();
  daysAgoDate.setDate(daysAgoDate.getDate() - numDays);
  return getStringFromDate(daysAgoDate);
}

export function getThirtyDaysAgo() {
  return getNumDaysAgo(30);
}

export function getNumDaysFromNow(numDays) {
  let daysFromDate = new Date();
  daysFromDate.setDate(daysFromDate.getDate() + numDays);
  return getStringFromDate(daysFromDate);
}

export function getThirtyDaysFromNow() {
  return getNumDaysFromNow(30);
}
