import React, { Component } from "react";
import PropTypes from "prop-types";
import "./tooltip.scss";

class Tooltip extends Component {
  render() {
    const {
      date,
      siteData,
      formatSite,
      formatValue,
      showTotal,
      totalValue,
    } = this.props;
    return (
      <div className="tooltip-container">
        <div className="tooltip-date">{date}</div>
        {showTotal && <div className="tooltip-total-value">{totalValue}</div>}
        {siteData.map(site => {
          return (
            <div className="tooltip-row" key={site.id}>
              <div className="tooltip-site-name">{formatSite(site)}</div>
              <div className="tooltip-value">{formatValue(site.value)}</div>
            </div>
          );
        })}
      </div>
    );
  }
}

Tooltip.propTypes = {
  date: PropTypes.string.isRequired,
  siteData: PropTypes.array.isRequired,
  formatSite: PropTypes.func.isRequired,
  formatValue: PropTypes.func.isRequired,
  showTotal: PropTypes.bool.isRequired,
  totalValue: PropTypes.string.isRequired,
};

export default Tooltip;
