import { createDataActions } from "utils/storeUtils";

/**
 * @typedef {import("../../utils/storeUtils").FilterConfigOption} FilterConfigOption
 */

/**
 * @type {FilterConfigOption[]}
 */
const NET_MEMBERS_FILTERS = [
  // {
  //   title: "Week to date",
  //   options: {
  //     period: 1,
  //     period_unit: "week_to_date"
  //   }
  // },
  {
    title: "Last 30 Days",
    options: {
      period: 30,
      period_unit: "day",
    },
  },
];

export const { actions, actionCreators, reducer } = createDataActions(
  "net_members",
  NET_MEMBERS_FILTERS
);
