import { stringifyQueryParams, fetchAndDispatch } from "../utils/apiUtils";
import { downloadBlob } from "./reports";
/**
 *
 * @param {string} accessToken Auth0 Access Token
 */
export async function getMonthlyKPI(accessToken, options = {}) {
  const query = stringifyQueryParams(options);
  const data = await fetchAndDispatch(
    `${process.env.REACT_APP_API_URL}/monthly_kpi/?${query}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return data;
}

export async function getMonthlyKPICSV(accessToken, options = {}) {
  var today = new Date().toISOString().split("T")[0];
  const query = stringifyQueryParams(options);
  const data = await fetchAndDispatch(
    `${process.env.REACT_APP_API_URL}/monthly_kpi/?${query}`,
    {
      headers: {
        Accept: "text/csv",
        Authorization: `Bearer ${accessToken}`,
      },
    },
    {
      json: false,
    }
  );
  const blob = await data.blob();
  downloadBlob(
    blob,
    `Monthly-KPI-Report-${today}-${options["period"]}-${options["period_unit"]}.csv`
  );
  return data;
}
