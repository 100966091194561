import { takeLatest, put, select } from "redux-saga/effects";
import { ACTIONS, loginCallback, setUser } from "./actions";
import { AuthAPI } from "../../api";
import {
  convertHashToLoginCallback,
  getUserFromAuth,
  selectAuth,
  checkLoggedIn,
} from "utils/utils";

function attemptLogin() {
  AuthAPI.auth.authorize();
}

function* loginCallbackSaga() {
  const auth0 = yield convertHashToLoginCallback();
  yield put(loginCallback(auth0));

  const user = yield getUserFromAuth(auth0.access_token);
  yield put(setUser(user));

  window.location = "/";
}

function* initSaga() {
  // check logged in
  const auth = yield select(selectAuth);
  const loggedIn = checkLoggedIn(auth);

  if (loggedIn) {
    const user = yield getUserFromAuth(auth.access_token);
    yield put(setUser(user));
  }
  yield loggedIn;
}

function attemptLogout() {
  // we need to call the logout endpoint at Auth0 and return to the app
  window.location = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/v2/logout?returnTo=${process.env.REACT_APP_SITE_URL}&client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}`;
}

function* login() {
  yield takeLatest(ACTIONS.LOGIN, attemptLogin);
}

function* takeLoginCallback() {
  yield takeLatest(ACTIONS.REQUEST_LOGIN_CALLBACK, loginCallbackSaga);
}

function* takeInit() {
  yield takeLatest(ACTIONS.INIT, initSaga);
}

function* logout() {
  yield takeLatest(ACTIONS.LOGOUT, attemptLogout);
}

export const SAGAS = [login(), takeLoginCallback(), takeInit(), logout()];
