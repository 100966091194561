import { takeLatest, put, select } from "redux-saga/effects";
import {
  ACTIONS,
  changePasswordSuccess,
  changePasswordFailed,
} from "./actions";
import { ChangePasswordAPI } from "../../api";
import { selectAuth, checkLoggedIn } from "utils/utils";

function goToChangePasswordSaga() {
  window.location = "/changepassword";
}

function* attemptChangePasswordSaga() {
  // check logged in
  const auth = yield select(selectAuth);
  const loggedIn = checkLoggedIn(auth);

  if (loggedIn) {
    try {
      const data = yield ChangePasswordAPI.changePassword(auth.access_token);

      let parsedData = {};
      try {
        parsedData = JSON.parse(data);
      } catch (e) {}

      if (parsedData.error) {
        throw new Error(parsedData.error);
      }

      yield put(changePasswordSuccess(data));
    } catch (e) {
      yield put(changePasswordFailed(e.toString()));
    }
  }
}

function* takeGoToChangePassword() {
  yield takeLatest(ACTIONS.GO_TO_CHANGE_PASSWORD, goToChangePasswordSaga);
}

function* takeAttemptChangePassword() {
  yield takeLatest(ACTIONS.ATTEMPT_CHANGE_PASSWORD, attemptChangePasswordSaga);
}

export const SAGAS = [takeGoToChangePassword(), takeAttemptChangePassword()];
