import { SET_VERSION } from "./actions";

/**
 * @typedef {import("../../utils/storeUtils").Action} Action
 */

/**
 * @typedef {object} VersionState
 * @property {string} version
 */

/**
 * @type {VersionState}
 */
const DEFAULT_STATE = {
  version: undefined,
};

/**
 *
 * @param {VersionState} state
 * @param {Action} action
 * @returns {VersionState}
 */
export function reducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET_VERSION: {
      return {
        ...state,
        version: action.payload,
      };
    }
    default:
      return state;
  }
}
