import React from "react";
import { connect } from "react-redux";
import { actionCreators } from "../../store/info";

class Info extends React.Component {
  componentDidMount() {
    this.props.requestData();
  }

  render() {
    const { data } = this.props;

    return (
      <div>
        {data && data.data && (
          <div>
            <span className="info-title">Last Modified:</span>
            <br />
            {data.data.last_modified.format("MMMM Do YYYY")}
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  state => ({
    data: state.info,
  }),
  dispatch => ({
    requestData: options => dispatch(actionCreators.requestData(options)),
  })
)(Info);
