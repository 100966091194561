import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { reducer as authReducer, init } from "./auth";
import { reducer as washSalesReducer } from "./washSales";
import { reducer as washSalesTableReducer } from "./washSalesTable";
import { reducer as armSalesLeaderboardReducer } from "./armSalesLeaderboard";
import { reducer as armSalesTableReducer } from "./armSalesTable";
import { reducer as armSalesChartReducer } from "./armSalesChart";
import { reducer as captureRateReducer } from "./captureRate";
import { reducer as churnRateReducer } from "./churnRate";
import { reducer as churnRateIntervalReducer } from "./churnRateInterval";
import { reducer as rechargeReducer } from "./recharge";
import { reducer as netMembersReducer } from "./netMembers";
import { reducer as totalDepositsIntervalReducer } from "./totalDepositsInterval";
import { reducer as sitesReducer } from "./sites";
import { reducer as infoReducer } from "./info";
import { reducer as versionReducer } from "./version";
import { reducer as rechargeTicketAvgReducer } from "./rechargeTicketAvgChart";
import { reducer as singleWashTicketAvgChartReducer } from "./singleWashTicketAvgChart";
import { reducer as singleWashTicketAvgReducer } from "./singleWashTicketAvg";
import { reducer as grossTicketPerCarChartReducer } from "./grossTicketPerCarChart";
import { reducer as churnRateByWashPackageTableReducer } from "./churnRateByWashPackageTable";
import { reducer as washMixRechargeTableReducer } from "./washMixRechargeTable";
import { reducer as washMixSoldTableReducer } from "./washMixSoldTable";
import { reducer as washMixRetailTableReducer } from "./washMixRetailTable";
import { reducer as changePasswordReducer } from "./changePassword";
import { reducer as cancellationsReducer } from "./cancellations";
import { reducer as historicalChurnRateChartReducer } from "./historicalChurnRateChart";
import { reducer as historicalCaptureRateChartReducer } from "./historicalCaptureRateChart";
import { reducer as currentMonthChurnPaceTableReducer } from "./currentMonthChurnPaceTable";
import { reducer as monthlyKPITableReducer } from "./monthlyKPITable";
import { reducer as ccStatusChangedTableReducer } from "./ccStatusChangedTable";
import { reducer as creditCardStatusListDeclinedReducer } from "./creditCardStatusListDeclined";
import { reducer as creditCardStatusListExpiredReducer } from "./creditCardStatusListExpired";
import { reducer as creditCardStatusListExpiringReducer } from "./creditCardStatusListExpiring";
import { reducer as customersByStatusReducer } from "./customersByStatus";
import sagas from "./sagas";

function* rootSaga() {
  yield all([...sagas]);
}

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  auth: authReducer,
  washSales: washSalesReducer,
  washSalesTable: washSalesTableReducer,
  armSalesLeaderboard: armSalesLeaderboardReducer,
  armSalesTable: armSalesTableReducer,
  armSalesChart: armSalesChartReducer,
  captureRate: captureRateReducer,
  churnRate: churnRateReducer,
  churnRateInterval: churnRateIntervalReducer,
  recharge: rechargeReducer,
  netMembers: netMembersReducer,
  totalDepositsInterval: totalDepositsIntervalReducer,
  sites: sitesReducer,
  info: infoReducer,
  version: versionReducer,
  rechargeTicketAvgChart: rechargeTicketAvgReducer,
  singleWashTicketAvgChart: singleWashTicketAvgChartReducer,
  singleWashTicketAvg: singleWashTicketAvgReducer,
  grossTicketPerCarChart: grossTicketPerCarChartReducer,
  churnRateByWashPackageTable: churnRateByWashPackageTableReducer,
  washMixRechargeTable: washMixRechargeTableReducer,
  washMixSoldTable: washMixSoldTableReducer,
  washMixRetailTable: washMixRetailTableReducer,
  cancellations: cancellationsReducer,
  changePassword: changePasswordReducer,
  historicalChurnRateChart: historicalChurnRateChartReducer,
  historicalCaptureRateChart: historicalCaptureRateChartReducer,
  currentMonthChurnPaceTable: currentMonthChurnPaceTableReducer,
  monthlyKPITable: monthlyKPITableReducer,
  ccStatusChangedTable: ccStatusChangedTableReducer,
  creditCardStatusListDeclined: creditCardStatusListDeclinedReducer,
  creditCardStatusListExpired: creditCardStatusListExpiredReducer,
  creditCardStatusListExpiring: creditCardStatusListExpiringReducer,
  customersByStatus: customersByStatusReducer,
});

const persistConfig = {
  key: "root",
  whitelist: ["sites"],
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

// TODO - dispatch init auth
store.dispatch(init());

export { store, persistor };
