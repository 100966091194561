import React from "react";
import "./loadingSpinner.scss";

function LoadingSpinner() {
  return (
    <div className="loading-spinner">
      <div className="rect1"></div>
      <div className="rect2"></div>
      <div className="rect3"></div>
      <div className="rect4"></div>
      <div className="rect5"></div>
    </div>
  );
}

export default LoadingSpinner;
