import { createDataActions } from "utils/storeUtils";

/**
 * @typedef {import("../../utils/storeUtils").FilterConfigOption} FilterConfigOption
 */

/**
 * @type {FilterConfigOption[]}
 */
const CC_STATUS_CHANGED_TABLE_FILTERS = [
  {
    title: "Last 7 Days",
    options: {
      period: 7,
      period_unit: "day",
    },
  },
  {
    title: "Last 30 Days",
    options: {
      period: 30,
      period_unit: "day",
    },
  },
  {
    title: "Last 90 Days",
    options: {
      period: 90,
      period_unit: "day",
    },
  },
  {
    title: "Last 12 Months",
    options: {
      period: 12,
      period_unit: "month",
    },
  },
  // {
  //   title: "Last 24 Hours",
  //   options: {
  //     period: 24,
  //     period_unit: "hour"
  //   }
  // }
];

export const { actions, actionCreators, reducer } = createDataActions(
  "cc_status_changed_table",
  CC_STATUS_CHANGED_TABLE_FILTERS
);
