import { createDataActions } from "utils/storeUtils";

/**
 * @typedef {import("../../utils/storeUtils").FilterConfigOption} FilterConfigOption
 */

const commonOptions = {
    group_by: "YYYY-MM-DD",
};

/**
 * @type {FilterConfigOption[]}
 */
const CUSTOMERS_BY_STATUS_FILTERS = [
    {
        title: "Last 30 Days",
        options: {
            period: 30,
            period_unit: "day",
            ...commonOptions,
        },
    },
    {
        title: "Last 90 Days",
        options: {
            period: 90,
            period_unit: "day",
            ...commonOptions,
        },
    },
    {
        title: "Last 12 Months",
        options: {
            period: 12,
            period_unit: "month",
            ...commonOptions,
        },
    },
];

export const { actions, actionCreators, reducer } = createDataActions(
    "customers_by_status",
    CUSTOMERS_BY_STATUS_FILTERS
);
