import React, { Component } from "react";
import { connect } from "react-redux";
import { actionCreators } from "../../store/totalDepositsInterval";
import IntervalChart from "../intervalChart";
import * as d3 from "d3";
import "./totalDepositsInterval.scss";
import SingleContainerComponent from "../singleContainerComponent";

const yTickFormatter = v => {
  return "$" + d3.format("~s")(v);
};

const valueFormatter = v => {
  return (
    "$" +
    v.toLocaleString("en", {
      useGrouping: true,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  );
};

const changeFormatter = v => {
  return (
    (v * 100).toLocaleString("en", {
      useGrouping: true,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }) + "%"
  );
};

class TotalDepositsInterval extends Component {
  render() {
    const {
      data,
      requestData,
      dataLoaded,
      dataFailed,
      filters,
      selectedFilterId,
      setFilterId,
      loading,
      error,
      sites,
    } = this.props;

    return (
      <div
        className={`dashboard-widget
        ${error ? "error" : ""}        
        ${loading ? "loading" : ""} churn-rate-container`}
      >
        <SingleContainerComponent
          requestData={requestData}
          dataLoaded={dataLoaded}
          dataFailed={dataFailed}
          title="INTERVAL CHARTS"
          filters={filters}
          selectedFilterId={selectedFilterId}
          setFilterId={setFilterId}
          component={IntervalChart}
          componentData={{
            ...data,
            info:
              "Total Deposits is on the Sitewatch Web GSR as the Total to Account For.  Interval chart shows current period (solid) vs previous period (dashed).",
            valueChangeTitle: "Total Deposits",
            toolTipLabel: "Total Deposits",
          }}
          config={{
            valueFormatter,
            changeFormatter,
            yTickFormatter,
            yValueFormatter: valueFormatter,
          }}
          sites={sites}
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    data: state.totalDepositsInterval,
    lastUpdated: state.totalDepositsInterval.lastUpdated,
    filters: state.totalDepositsInterval.filters,
    selectedFilterId: state.totalDepositsInterval.selectedFilterId,
    loading: state.totalDepositsInterval.loading,
    error: state.totalDepositsInterval.error,
    sites: state.sites,
  }),
  dispatch => ({
    requestData: options => dispatch(actionCreators.requestData(options)),
    dataLoaded: () => dispatch(actionCreators.dataLoaded()),
    dataFailed: () => dispatch(actionCreators.dataFailed()),
    setFilterId: (selectedFilterId, dateRange, groupBy) =>
      dispatch(
        actionCreators.setFilterId(selectedFilterId, dateRange, groupBy)
      ),
  })
)(TotalDepositsInterval);
