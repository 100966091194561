import { createDataActions } from "utils/storeUtils";

/**
 * @typedef {import("../../utils/storeUtils").FilterConfigOption} FilterConfigOption
 */

/**
 * @type {FilterConfigOption[]}
 */
const ARM_SALES_TABLE_FILTERS = [
  {
    title: "Last 7 Days",
    options: {
      period: 7,
      period_unit: "day",
    },
  },
  {
    title: "Last 30 Days",
    options: {
      period: 30,
      period_unit: "day",
    },
  },
];

export const { actions, actionCreators, reducer } = createDataActions(
  "arm_sales_table",
  ARM_SALES_TABLE_FILTERS
);
