import React from "react";
import PropTypes from "prop-types";
import "./stackedCheckbox.scss";

function StackedCheckbox(props) {
  const { value, onChangeValue } = props;

  return (
    <div>
      <label className="stacked-label">
        <input
          type="checkbox"
          className="stacked-checkbox"
          onChange={e => onChangeValue(e.target.checked)}
          value={value}
        />
        Stacked
      </label>
    </div>
  );
}

StackedCheckbox.propTypes = {
  value: PropTypes.bool.isRequired,
  onChangeValue: PropTypes.func.isRequired,
};

export default StackedCheckbox;
