import React from "react";
import "../dashboard.scss";
import ArmSalesLeaderboard from "components/armSalesLeaderboard";
import ArmSalesTable from "components/armSalesTable";
import ArmSalesChart from "components/armSalesChart";
import Recharge from "components/recharge";
import NetMembers from "components/netMembers";
import RechargeTicketAvgChart from "components/rechargeTicketAvgChart";
import Cancellations from "../../components/cancellations";

class Dashboard extends React.Component {
  render() {
    return (
      <div className="dashboard-outer">
        <div className="dashboard-inner">
          <div className="dashboard-item-3">
            <ArmSalesChart />
          </div>
          <div className="dashboard-item-3">
            <ArmSalesTable />
          </div>
        </div>
        <div className="dashboard-inner">
          <div className="dashboard-item-3">
            <Cancellations />
          </div>
          <div className="dashboard-item-3 stacked-outer">
            <div className="stacked-inner">
              <div className="dashboard-item-2">
                <Recharge />
              </div>
              <div className="dashboard-item-2">
                <NetMembers />
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-inner">
          <div className="dashboard-item-3">
            <RechargeTicketAvgChart />
          </div>
          <div className="dashboard-item-3">
            <ArmSalesLeaderboard />
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
