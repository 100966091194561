import { takeLatest, put, select } from "redux-saga/effects";
import {
  selectAuth,
  checkLoggedIn,
  generateSelectDataState,
  generateSelectedSiteIds,
} from "utils/utils";
import { actions, actionCreators } from "./reducers";
import { ArmSalesLeaderboardAPI } from "../../api";
import { getAPIOptionsFromDataState } from "utils/filterUtils";

function* requestData(action) {
  // check logged in
  const auth = yield select(selectAuth);
  const loggedIn = checkLoggedIn(auth);
  const dataState = yield select(
    generateSelectDataState("armSalesLeaderboard")
  );
  const selectedSiteIds = yield select(generateSelectedSiteIds());
  const options = getAPIOptionsFromDataState(dataState, selectedSiteIds);

  if (loggedIn) {
    try {
      const armSales = yield ArmSalesLeaderboardAPI.getArmSalesLeaderboard(
        auth.access_token,
        {
          type: "chart",
          ...options,
        }
      );
      yield put(
        actionCreators.dataLoaded({
          ...armSales,
          data: armSales.data,
        })
      );
    } catch (e) {
      yield put(actionCreators.dataFailed(e.toString()));
    }
  }
}

function* requestDataSaga() {
  yield takeLatest(actions.REQUEST_DATA, requestData);
}

function* onSetFilterId(action) {
  yield put(actionCreators.requestData());
}

function* setFilterSaga() {
  yield takeLatest(actions.SET_FILTER_ID, onSetFilterId);
}

export const SAGAS = [requestDataSaga(), setFilterSaga()];
