import React from "react";
import { withRouter } from "react-router-dom";
import "./sidebar.scss";
import { checkLoggedIn, checkIsStaff } from "utils/utils";
import { connect } from "react-redux";
import Icons from "./../icons";
import Info from "../info";

/**
 * @typedef {object} RouteConfig
 * @property {string} name
 * @property {string} route
 * @property {boolean} [staffOnly]
 * @property {string} [icon]
 * @property {string} [tag]
 * @property {boolean} loggedInOnly
 */

/**
 *
 * @param {object} props
 * @param {object} props.history
 * @param {object} props.location
 * @param {object} props.match
 * @param {boolean} props.isStaff
 */
function Sidebar({ loggedIn, history, location, isStaff }) {
  function goTo(route) {
    history.push(route);
  }

  const currentYear = new Date().getFullYear();

  /**
   * @type {RouteConfig[]}
   */
  const routes = [
    {
      name: "Sales",
      route: "/sales",
      icon: "dollar",
      loggedInOnly: true,
    },
    {
      name: "Members",
      route: "/members",
      icon: "members",
      loggedInOnly: true,
    },
    {
      name: "Churn & Capture",
      route: "/churn-and-capture",
      icon: "cycle",
      loggedInOnly: true,
    },
    {
      name: "Packages",
      route: "/packages",
      icon: "package",
      loggedInOnly: true,
    },
  ];

  if (
    process.env.REACT_APP_DO_CHURN_BURNER &&
    process.env.REACT_APP_DO_CHURN_BURNER.toLowerCase() === "true"
  ) {
    routes.push({
      name: "ChurnBurner",
      route: "/cc-status",
      icon: "fire",
      color: "teal",
      tag: "Premium",
      loggedInOnly: true,
    });
  }

  if (
    process.env.REACT_APP_DO_MONTHLY_KPI &&
    process.env.REACT_APP_DO_MONTHLY_KPI.toLowerCase() === "true"
  ) {
    routes.push({
      name: "Monthly KPI",
      route: "/monthly-kpi",
      icon: "report",
      loggedInOnly: true,
    });
  }

  routes.push(
    ...[
      {
        name: "Reports",
        route: "/reports",
        icon: "report",
        loggedInOnly: true,
      },
      {
        name: "Custom Reports",
        route: "/custom-reports",
        icon: "customReports",
        loggedInOnly: true,
      },
      {
        name: "Alerts",
        route: "/alerts",
        staffOnly: true,
        icon: "alert",
        loggedInOnly: true,
      },
      {
        name: "Users",
        route: "/users",
        staffOnly: true,
        icon: "member",
        loggedInOnly: true,
      },
    ]
  );

  if (
    process.env.REACT_APP_DO_CUSTOMER_NOTIFICATIONS &&
    process.env.REACT_APP_DO_CUSTOMER_NOTIFICATIONS.toLowerCase() === "true"
  ) {
    routes.push({
      name: "Notifications",
      route: "/customer_notifications",
      icon: "notifications",
      tag: "NEW",
      color: "red",
      loggedInOnly: true,
    });
  }

  routes.push({
    name: "Changelog",
    route: "/changelog",
    icon: "changelog",
    loggedInOnly: true,
  });

  function showRoute(r) {
    if (r.loggedInOnly && !loggedIn) {
      return null;
    }
    return (
      !r.hasOwnProperty("staffOnly") ||
      (r.staffOnly === true && isStaff === true) ||
      !r.loggedInOnly
    );
  }

  return (
    <div className="dashboard-sidebar">
      <div className="sidebar-nav">
        <div className="sidebar-logo" onClick={() => goTo("/")}>
          <Icons name="darkLogo" />
        </div>
        {routes.map(
          r =>
            showRoute(r) && (
              <div
                className="sidebar-item"
                onClick={() => goTo(r.route)}
                role="button"
                key={r.name}
              >
                <div
                  className={`sidebar-item-content ${
                    location.pathname === r.route ? "selected" : ""
                  }`}
                >
                  <span className="sidebar-item-icon">
                    <Icons name={r.icon} />
                  </span>
                  <span className="sidebar-item-title">{r.name}</span>
                  {r.tag && (
                    <span className={`sidebar-item-tag ${r.color || ""}`}>
                      {r.tag}
                    </span>
                  )}
                </div>
              </div>
            )
        )}
      </div>
      <div className="sidebar-footer">
        {loggedIn && (
          <div className="sidebar-footer-callout">
            <Info />
          </div>
        )}
        <p>©{currentYear} Suds Creative by DRB Systems</p>
      </div>
    </div>
  );
}
export default withRouter(
  connect(state => {
    return {
      loggedIn: checkLoggedIn(state.auth.auth),
      isStaff: checkIsStaff(state.auth.user),
    };
  })(Sidebar)
);
