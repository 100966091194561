import React from "react";
import { connect } from "react-redux";
import { actionCreators } from "../../store/armSalesLeaderboard";
import RankedTable from "../rankedTable";
import "./armSalesLeaderboard.scss";

const formatter = v => {
  return (
    "$" +
    v.toLocaleString("en", {
      useGrouping: true,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  );
};

class ArmSalesLeaderboard extends React.Component {
  render() {
    const {
      data,
      requestData,
      dataLoaded,
      dataFailed,
      filters,
      selectedFilterId,
      setFilterId,
      loading,
      error,
      sites,
    } = this.props;

    return (
      <div
        className={`dashboard-widget
        ${error ? "error" : ""}        
        ${loading ? "loading" : ""} arm-sales-leaderboard-container`}
      >
        <RankedTable
          requestData={requestData}
          dataLoaded={dataLoaded}
          dataFailed={dataFailed}
          title="ARM SALES LEADERBOARD"
          filters={filters}
          selectedFilterId={selectedFilterId}
          setFilterId={setFilterId}
          config={{
            columns: [
              {
                name: "Name",
                path: ["employee", "name"],
              },
              {
                name: "Sales",
                path: ["sales"],
                sortable: true,
                formatter,
              },
              {
                name: "Count",
                path: ["quantity"],
                sortable: true,
              },
            ],
            rowIdPath: ["employee", "id"],
            valuePath: ["sales"],
            showRank: true,
          }}
          sites={sites}
          {...data}
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    data: state.armSalesLeaderboard,
    lastUpdated: state.armSalesLeaderboard.lastUpdated,
    filters: state.armSalesLeaderboard.filters,
    selectedFilterId: state.armSalesLeaderboard.selectedFilterId,
    loading: state.armSalesLeaderboard.loading,
    error: state.armSalesLeaderboard.error,
    sites: state.sites,
  }),
  dispatch => ({
    requestData: options => dispatch(actionCreators.requestData(options)),
    dataLoaded: () => dispatch(actionCreators.dataLoaded()),
    dataFailed: () => dispatch(actionCreators.dataFailed()),
    setFilterId: id => dispatch(actionCreators.setFilterId(id)),
  })
)(ArmSalesLeaderboard);
