import React, { useState } from "react";
import PropTypes from "prop-types";
import FilterSelectModal from "./filterSelectModal";
import "./filterSelect.scss";
import Modal from "react-modal";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    zIndex: 9000,
  },
};

function FilterSelect(props) {
  const {
    filters,
    selectedFilterId,
    onChangeFilter,
    dateRange,
    groupBy,
  } = props;

  const [show, setShow] = useState(false);

  const toggleShow = () => setShow(!show);

  const selectedFilter =
    filters.find((filter, i) => i === selectedFilterId) || "";

  const onModalSave = (filterId, newDateRange, groupBy) => {
    onChangeFilter(filterId, newDateRange, groupBy);
  };

  return (
    <div className="filter-select-container">
      <button className="pure-button filter-select" onClick={toggleShow}>
        {selectedFilter && selectedFilter.title} <span className="arrow-down" />
      </button>
      <Modal
        isOpen={show}
        onRequestClose={toggleShow}
        contentLabel="Filters"
        style={customStyles}
      >
        <FilterSelectModal
          onClose={toggleShow}
          onSave={onModalSave}
          selectedFilterId={selectedFilterId}
          filters={filters}
          dateRange={dateRange}
          groupBy={groupBy}
        />
      </Modal>
    </div>
  );
}

FilterSelect.propTypes = {
  filters: PropTypes.array.isRequired,
  selectedFilterId: PropTypes.number.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
};

export default FilterSelect;
