import { createDataActions } from "utils/storeUtils";

/**
 * @typedef {import("../../utils/storeUtils").FilterConfigOption} FilterConfigOption
 */

const commonOptions = {
  status: [120],
  ordering: "-date",
  expiring_soon: false,
  notification_type: "cc_declined",
  group_by: "YYYY-MM-DD",
  limit: 50,
};

/**
 * @type {FilterConfigOption[]}
 */
const CC_STATUS_LIST_FILTERS = [
  {
    title: "Last 3 Days",
    options: {
      period: 3,
      period_unit: "day",
      ...commonOptions,
    },
  },
  {
    title: "Last 7 Days",
    options: {
      period: 7,
      period_unit: "day",
      ...commonOptions,
    },
  },
  {
    title: "Last 30 Days",
    options: {
      period: 30,
      period_unit: "day",
      ...commonOptions,
    },
  },
  // {
  //     title: "Last 90 Days",
  //     options: {
  //         period: 90,
  //         period_unit: "day",
  //         ...commonOptions,
  //     },
  // },
  // {
  //     title: "Last 12 Months",
  //     options: {
  //         period: 12,
  //         period_unit: "month",
  //         ...commonOptions,
  //     },
  // },
];

export const { actions, actionCreators, reducer } = createDataActions(
  "credit_card_status_list_declined",
  CC_STATUS_LIST_FILTERS
);
