import React from "react";
import ExportCSVButton from "../exportCsvButton";
import { connect } from "react-redux";
import { actionCreators as actionCreatorsDeclined } from "../../store/creditCardStatusListDeclined";
import { actionCreators as actionCreatorsExpired } from "../../store/creditCardStatusListExpired";
import { actionCreators as actionCreatorsExpiring } from "../../store/creditCardStatusListExpiring";

import RankedTable from "../rankedTable";
import "./creditCardStatusList.scss";

const expiringSoonChartTitle = "Expiring Soon";

const expiringSoonColumns = [
  {
    name: "Name",
    path: ["name"],
    sortable: true,
  },
  {
    name: "Email",
    path: ["email"],
    sortable: true,
    checkmark: true,
  },
  {
    name: "Phone",
    path: ["phone"],
    sortable: true,
    checkmark: true,
  },
  {
    name: "Plan Expires",
    path: ["expires"],
    sortable: true,
  },
  {
    name: "Card Expiration",
    path: ["expiration"],
    sortable: true,
  },
  {
    name: "Notified?",
    path: ["notified_date"],
    sortable: true,
  },
];

const expiredChartTitle = "Expired";
const expiredColumns = [
  {
    name: "Name",
    path: ["name"],
    sortable: true,
  },
  {
    name: "Email",
    path: ["email"],
    sortable: true,
    checkmark: true,
  },
  {
    name: "Phone",
    path: ["phone"],
    sortable: true,
    checkmark: true,
  },
  {
    name: "Date",
    path: ["date"],
    sortable: true,
  },
  {
    name: "Card Expiration",
    path: ["expiration"],
    sortable: true,
  },
  {
    name: "Notified?",
    path: ["notified_date"],
    sortable: true,
  },
];

const declinedChartTitle = "Declined";
const declinedColumns = [
  {
    name: "Name",
    path: ["name"],
    sortable: true,
  },
  {
    name: "Email",
    path: ["email"],
    sortable: true,
    checkmark: true,
  },
  {
    name: "Phone",
    path: ["phone"],
    sortable: true,
    checkmark: true,
  },
  {
    name: "Decline Date",
    path: ["date"],
    sortable: true,
  },
  {
    name: "Notified?",
    path: ["notified_date"],
    sortable: true,
  },
];

const CreditCardStatusList = (props) => {
  const {
    data,
    requestData,
    dataLoaded,
    dataFailed,
    filters,
    selectedFilterId,
    setFilterId,
    loading,
    error,
    sites,
    columns,
    chartTitle,
    generateCSV
  } = props;

  async function onClickCSV() {
    try {
      generateCSV();
    } catch (e) {
      console.error("Error loading csv for CC Status:", e);
    }
  }

  return (
    <div
      className={`dashboard-widget
        ${error ? "error" : ""}        
        ${loading ? "loading" : ""} credit-card-status-list-wrapper`}
    >
      <RankedTable
        requestData={requestData}
        dataLoaded={dataLoaded}
        dataFailed={dataFailed}
        title={chartTitle}
        filters={filters}
        selectedFilterId={selectedFilterId}
        setFilterId={setFilterId}
        config={{
          columns,
          rowIdPath: ["customer_sitewatch_id"],
        }}
        sites={sites}
        {...data}
      />
      <ExportCSVButton onClickButton={onClickCSV} />
    </div>
  );
};

const CreditCardStatusListDeclinedWrapper = (props) => {
  return (
    <CreditCardStatusList {...props} columns={declinedColumns} chartTitle={declinedChartTitle} />
  );
}

const CreditCardStatusListDeclined = connect(
  state => ({
    data: state.creditCardStatusListDeclined,
    lastUpdated: state.creditCardStatusListDeclined.lastUpdated,
    filters: state.creditCardStatusListDeclined.filters,
    selectedFilterId: state.creditCardStatusListDeclined.selectedFilterId,
    loading: state.creditCardStatusListDeclined.loading,
    error: state.creditCardStatusListDeclined.error,
    sites: state.sites,
  }),
  dispatch => ({
    requestData: options => dispatch(actionCreatorsDeclined.requestData(options)),
    generateCSV: options => dispatch(actionCreatorsDeclined.generateCSV(options)),
    dataLoaded: () => dispatch(actionCreatorsDeclined.dataLoaded()),
    dataFailed: () => dispatch(actionCreatorsDeclined.dataFailed()),
    setFilterId: id => dispatch(actionCreatorsDeclined.setFilterId(id)),
  })
)(CreditCardStatusListDeclinedWrapper);


const CreditCardStatusListExpiredWrapper = (props) => {
  return (
    <CreditCardStatusList {...props} columns={expiredColumns} chartTitle={expiredChartTitle} />
  );
}

const CreditCardStatusListExpired = connect(
  state => ({
    data: state.creditCardStatusListExpired,
    lastUpdated: state.creditCardStatusListExpired.lastUpdated,
    filters: state.creditCardStatusListExpired.filters,
    selectedFilterId: state.creditCardStatusListExpired.selectedFilterId,
    loading: state.creditCardStatusListExpired.loading,
    error: state.creditCardStatusListExpired.error,
    sites: state.sites,
  }),
  dispatch => ({
    requestData: options => dispatch(actionCreatorsExpired.requestData(options)),
    generateCSV: options => dispatch(actionCreatorsExpired.generateCSV(options)),
    dataLoaded: () => dispatch(actionCreatorsExpired.dataLoaded()),
    dataFailed: () => dispatch(actionCreatorsExpired.dataFailed()),
    setFilterId: id => dispatch(actionCreatorsExpired.setFilterId(id)),
  })
)(CreditCardStatusListExpiredWrapper);

const CreditCardStatusListExpiringWrapper = (props) => {
  return (
    <CreditCardStatusList {...props} columns={expiringSoonColumns} chartTitle={expiringSoonChartTitle} />
  );
}

const CreditCardStatusListExpiring = connect(
  state => ({
    data: state.creditCardStatusListExpiring,
    lastUpdated: state.creditCardStatusListExpiring.lastUpdated,
    filters: state.creditCardStatusListExpiring.filters,
    selectedFilterId: state.creditCardStatusListExpiring.selectedFilterId,
    loading: state.creditCardStatusListExpiring.loading,
    error: state.creditCardStatusListExpiring.error,
    sites: state.sites,
  }),
  dispatch => ({
    requestData: options => dispatch(actionCreatorsExpiring.requestData(options)),
    generateCSV: options => dispatch(actionCreatorsExpiring.generateCSV(options)),
    dataLoaded: () => dispatch(actionCreatorsExpiring.dataLoaded()),
    dataFailed: () => dispatch(actionCreatorsExpiring.dataFailed()),
    setFilterId: id => dispatch(actionCreatorsExpiring.setFilterId(id)),
  })
)(CreditCardStatusListExpiringWrapper);

export {
  CreditCardStatusListDeclined,
  CreditCardStatusListExpired,
  CreditCardStatusListExpiring,
};