import { ACTIONS } from "./actions";
import * as Sentry from "@sentry/browser";

export const INITIAL_STATE = {
  auth: {
    access_token: localStorage.getItem("access_token"),
    expires_at: localStorage.getItem("expires_at"),
    id_token: localStorage.getItem("id_token"),
  },
  user: null,
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.LOGIN: {
      return state;
    }
    case ACTIONS.LOGIN_CALLBACK: {
      const { access_token, id_token, expires_at } = action.payload;

      localStorage.setItem("access_token", access_token);
      localStorage.setItem("id_token", id_token);
      localStorage.setItem("expires_at", expires_at);

      return {
        ...state,
        auth: {
          ...state.auth,
          access_token,
          id_token,
          expires_at,
        },
      };
    }
    case ACTIONS.REQUEST_LOGIN_CALLBACK: {
      return state;
    }
    case ACTIONS.LOGOUT: {
      localStorage.removeItem("access_token");
      localStorage.removeItem("id_token");
      localStorage.removeItem("expires_at");

      return {
        ...state,
        auth: {
          access_token: null,
          expires_at: null,
          id_token: null,
        },
        user: null,
      };
    }
    case ACTIONS.SET_USER: {
      Sentry.setUser(action.payload);
      return {
        ...state,
        user: action.payload,
      };
    }
    case ACTIONS.INIT: {
      return state;
    }
    default:
      return state;
  }
};
