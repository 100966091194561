import React from "react";
import PropTypes from "prop-types";
import FilterSelect from "../filterSelect";
import "./titleComponent.scss";
import InfoTooltip from "../infoTooltip";

function TitleComponent(props) {
  const {
    title,
    filters,
    selectedFilterId,
    onChangeFilter,
    onClickTitle,
    info,
    dateRange,
    groupBy,
    selectComponent,
  } = props;

  return (
    <div className="title-block">
      <div className="title-tooltip-wrapper">
        <h3 className="title" onClick={onClickTitle}>
          {title}
        </h3>
        {info && <InfoTooltip info={info} />}
      </div>
      <div className="title-block-options">
        {props.children}
        {selectComponent && selectComponent}
        {filters && (
          <FilterSelect
            filters={filters}
            selectedFilterId={selectedFilterId}
            onChangeFilter={onChangeFilter}
            dateRange={dateRange}
            groupBy={groupBy}
          />
        )}
      </div>
    </div>
  );
}

TitleComponent.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.string,
  filters: PropTypes.array,
  selectedFilterId: PropTypes.number.isRequired,
  onChangeFilter: PropTypes.func,
  onClickTitle: PropTypes.func.isRequired,
  dateRange: PropTypes.shape({
    start_date: PropTypes.instanceOf(Date),
    end_date: PropTypes.instanceOf(Date),
  }),
};

export default TitleComponent;
