import { takeLatest, put, select } from "redux-saga/effects";
import { selectAuth, checkLoggedIn } from "utils/utils";
import { actions, actionCreators } from "./reducers";
import { ChurnRateAPI } from "../../api";

function formateDate(d) {
  const dateTokens = d.date.split("-");
  const year = parseInt(dateTokens[0], 10);
  const month = parseInt(dateTokens[1], 10) - 1;
  const day = parseInt(dateTokens[2], 10);
  return {
    ...d,
    date: new Date(year, month, day, 0, 0, 0),
  };
}

function sortData(a, b) {
  if (a.date < b.date) {
    return -1;
  } else if (a.date > b.date) {
    return 1;
  } else {
    return 0;
  }
}

function mapChart(churnRate) {
  return {
    ...churnRate,
    current: churnRate.current.map(formateDate).sort(sortData),
    past: churnRate.past.map(formateDate).sort(sortData),
  };
}

function* requestData(action) {
  // check logged in
  const auth = yield select(selectAuth);
  const loggedIn = checkLoggedIn(auth);

  if (loggedIn) {
    const churnRate = mapChart(
      yield ChurnRateAPI.getChurnRate(auth.access_token, {
        type: "interval",
        ...action.payload,
      })
    );
    yield put(actionCreators.dataLoaded(churnRate));
  }
}

function* requestDataSaga() {
  yield takeLatest(actions.REQUEST_DATA, requestData);
}

export const SAGAS = [requestDataSaga()];
