import React from "react";
import "../dashboard.scss";
import { connect } from "react-redux";
import CustomersByStatus from "../../components/customersByStatus/";
import {
  CreditCardStatusListDeclined,
  CreditCardStatusListExpired,
  CreditCardStatusListExpiring,
} from "../../components/creditCardStatusList";
import CCStatusChangedTable from "../../components/ccStatusChangedTable";

function CreditCardStatus({ access_token }) {

  return (
    <div className="dashboard-outer credit-card-status-page">
      <div className="dashboard-inner">
        <div className="dashboard-item">
          <CCStatusChangedTable />
        </div>
      </div>
      <div className="dashboard-inner">
        <div className="dashboard-item-2">
          <CustomersByStatus access_token={access_token} />
        </div>
        <div className="dashboard-item-2">
          <CreditCardStatusListDeclined
          />
        </div>
      </div>
      <div className="dashboard-inner">
        <div className="dashboard-item-2">
          <CreditCardStatusListExpired
          />
        </div>
        <div className="dashboard-item-2">
          <CreditCardStatusListExpiring
          />
        </div>
      </div>
    </div>
  );
}

export default connect(function (state) {
  return {
    access_token: state.auth.auth.access_token,
  };
})(CreditCardStatus);
