import { takeLatest, put, select } from "redux-saga/effects";
import {
    selectAuth,
    checkLoggedIn,
    generateSelectDataState,
    generateSelectedSiteIds,
} from "utils/utils";
import { actions, actionCreators } from "./reducers";
import { CreditCardStatusAPI } from "../../api";
import { getAPIOptionsFromDataState } from "utils/filterUtils";

const STATUSES_TO_SHOW = [21, 27, 29, 110, 120, 130];

function* requestData(action) {
    // check logged in
    const auth = yield select(selectAuth);
    const loggedIn = checkLoggedIn(auth);
    const dataState = yield select(
        generateSelectDataState("customersByStatus")
    );
    const selectedSiteIds = yield select(generateSelectedSiteIds());
    const options = getAPIOptionsFromDataState(dataState, selectedSiteIds);

    if (loggedIn) {
        try {

            const statusData = yield CreditCardStatusAPI.getCreditCardStatus(
                auth.access_token,
                {
                    ...options
                }
            );

            const filteredStatusData = statusData.filter(v => STATUSES_TO_SHOW.includes(v.status))

            yield put(
                actionCreators.dataLoaded({
                    info: "Count of customers who are in a problem status.  Count of only the most recent status per customer.  For example, you pick Last 90 Days, we look to see the most recent status for a customer in past 90 days, if they declined but are now good, they are not in a bad status and will not show up on the list.",
                    data: filteredStatusData,
                })
            );
        } catch (e) {
            yield put(actionCreators.dataFailed(e.toString()));
        }
    }
}

function* requestDataSaga() {
    yield takeLatest(actions.REQUEST_DATA, requestData);
}

function* onSetFilterId(action) {
    yield put(actionCreators.requestData());
}

function* setFilterSaga() {
    yield takeLatest(actions.SET_FILTER_ID, onSetFilterId);
}

export const SAGAS = [requestDataSaga(), setFilterSaga()];
