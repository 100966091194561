import React, { Component } from "react";
import { ReactComponent as AlertIcon } from "./ico_alerts.svg";
import { ReactComponent as BellIcon } from "./ico_bell.svg";
import { ReactComponent as ChangelogIcon } from "./ico_changelog.svg";
import { ReactComponent as CheckIcon } from "./ico_check.svg";
import { ReactComponent as CSVIcon } from "./ico_csv.svg";
import { ReactComponent as CustomeReportsIcon } from "./ico_custom_reports.svg";
import { ReactComponent as CycleIcon } from "./ico_cycle.svg";
import { ReactComponent as DarkLogoIcon } from "./ico_dark_logo.svg";
import { ReactComponent as DollarIcon } from "./ico_dollar_sign.svg";
import { ReactComponent as DownCaretIcon } from "./ico_down_caret.svg";
import { ReactComponent as FireIcon } from "./ico_fire.svg";
import { ReactComponent as LoginIcon } from "./ico_login.svg";
import { ReactComponent as LogoIcon } from "./ico_logo.svg";
import { ReactComponent as MemberIcon } from "./ico_member.svg";
import { ReactComponent as MembersIcon } from "./ico_members.svg";
import { ReactComponent as NotificationsIcon } from "./ico_notifications.svg";
import { ReactComponent as PackageIcon } from "./ico_package.svg";
import { ReactComponent as ReportIcon } from "./ico_reports.svg";

class Icons extends Component {
  icons = {
    alert: AlertIcon,
    bell: BellIcon,
    changelog: ChangelogIcon,
    check: CheckIcon,
    csv: CSVIcon,
    customReports: CustomeReportsIcon,
    cycle: CycleIcon,
    darkLogo: DarkLogoIcon,
    dollar: DollarIcon,
    downCaret: DownCaretIcon,
    fire: FireIcon,
    package: PackageIcon,
    logo: LogoIcon,
    member: MemberIcon,
    members: MembersIcon,
    notifications: NotificationsIcon,
    login: LoginIcon,
    report: ReportIcon,
  };

  render() {
    const IconName = this.icons[this.props.name];
    if (IconName) {
      return <IconName />;
    }
    return null;
  }
}

export default Icons;
