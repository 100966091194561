import React, { useState, useEffect, useRef } from "react";
import { get, cloneDeep, findIndex } from "lodash";
import "./alerts.scss";
/**
 * @typedef {import("../../api/profiles").Profile} Profile
 */

/**
 * @typedef {import("../../api/profiles").AlertProfile} AlertProfile
 */

/**
 *
 * @param {object} props
 * @param {Profile} props.profile
 * @param {bool} props.isActive
 * @param {function} props.onSaveProfile
 * @param {function} props.onCancelProfile
 * @param {function} props.onDeleteProfile
 * @param {function} props.onChangeProfile
 * @param {function} props.onClickProfile
 * @param {object} props.saveStatus
 */
function AlertProfileComponent({
  profile,
  isActive,
  onSaveProfile,
  onCancelProfile,
  onDeleteProfile,
  onChangeProfile,
  onClickProfile,
  saveStatus,
}) {
  const [profileHeight, setProfileHeight] = useState("0px");
  const profileForm = useRef(null);

  // if this toggles between active and not active, do the transition
  useEffect(
    function() {
      // if going not active to active, set height
      if (isActive) {
        //Add 20px to scrollheight to account for padding
        setProfileHeight(`${profileForm.current.scrollHeight + 20}px`);
      } else {
        setProfileHeight("0px");
      }
    },
    [isActive]
  );

  const handleProfileFieldChange = profile => event => {
    const newProfile = cloneDeep(profile);
    newProfile[event.currentTarget.name] = event.currentTarget.value;
    onChangeProfile(newProfile);
  };

  const handleAlertProfileFrequencyChange = profile => event => {
    let newProfile = cloneDeep(profile);
    let frequencyIndex = findIndex(newProfile.alert_profile.frequencies, {
      frequency: event.target.name,
    });

    newProfile.alert_profile.frequencies[frequencyIndex].checked =
      event.target.checked;

    onChangeProfile(newProfile);
  };

  const handleAlertProfileLocationChange = profile => event => {
    let newProfile = cloneDeep(profile);
    let locationIndex = findIndex(newProfile.alert_profile.locations, {
      site_code: event.target.name,
    });
    newProfile.alert_profile.locations[locationIndex].checked =
      event.target.checked;

    onChangeProfile(newProfile);
  };

  const handleAlertProfileAllLocationsChange = profile => event => {
    let newProfile = cloneDeep(profile);
    newProfile.alert_profile.do_all_locations = !newProfile.alert_profile
      .do_all_locations;
    onChangeProfile(newProfile);
  };

  const handleAlertProfileTextChange = profile => event => {
    let newProfile = cloneDeep(profile);
    newProfile.alert_profile.do_text = !newProfile.alert_profile.do_text;
    onChangeProfile(newProfile);
  };

  const handleAlertProfileEmailChange = profile => event => {
    let newProfile = cloneDeep(profile);
    newProfile.alert_profile.do_email = event.target.checked;
    onChangeProfile(newProfile);
  };

  const onSaveForm = profile => async event => {
    event.preventDefault();
    await onSaveProfile(profile);
  };
  return (
    <div className="alerts-profile-users" key={profile.id}>
      <h3
        className={`alerts-profile-users-name ${
          isActive ? "active-profile-link" : ""
        }`}
        onClick={() => onClickProfile(profile.id, profileForm)}
      >
        {profile.first_name} {profile.last_name} <span className="arrow-down" />
      </h3>
      <form
        className={`alerts-profile-form ${isActive ? "active-profile" : ""}`}
        onSubmit={onSaveForm(profile)}
        ref={profileForm}
        style={{ maxHeight: `${isActive ? profileHeight : "0"}` }}
      >
        <div className="field">
          <label htmlFor="first_name">
            <span>First Name:</span>
            <input
              type="text"
              name="first_name"
              placeholder="First Name"
              value={profile.first_name}
              onChange={handleProfileFieldChange(profile)}
              required
            />
          </label>
        </div>
        <div className="field">
          <label htmlFor="last_name">
            <span>Last Name:</span>
            <input
              type="text"
              name="last_name"
              placeholder="Last Name"
              value={profile.last_name}
              onChange={handleProfileFieldChange(profile)}
              required
            />
          </label>
        </div>
        <div className="field">
          <label htmlFor="first_name">
            <span>Email Address:</span>
            <input
              type="email"
              name="email"
              placeholder="Email Address"
              value={profile.email}
              onChange={handleProfileFieldChange(profile)}
              required
            />
          </label>
        </div>
        <div className="field">
          <label htmlFor="phone_number">
            <span>Phone Number: (2085555555)</span>
            <input
              type="tel"
              name="phone_number"
              placeholder=""
              pattern="[0-9]{10}"
              maxLength="10"
              minLength="10"
              required={profile.alert_profile.do_text}
              value={profile.phone_number}
              onChange={handleProfileFieldChange(profile)}
            />
          </label>
        </div>
        )
        <div className="field field-full">
          <label>
            <span>Locations to receive alerts from:</span>
          </label>
          <div className="checkbox-list">
            <div className="checkbox-wrapper">
              <input
                type="checkbox"
                name="do-all-locations"
                onChange={handleAlertProfileAllLocationsChange(profile)}
                checked={get(profile.alert_profile, "do_all_locations")}
              />
              <label htmlFor="do-all-locations">All Locations</label>
            </div>
          </div>
          <div>
            <p>OR:</p>
          </div>
          <div className="checkbox-list">
            {profile.alert_profile.locations.map(site => {
              return (
                site.checked !== undefined &&
                site.site_code && (
                  <div className="checkbox-wrapper" key={site.site_code}>
                    <input
                      type="checkbox"
                      name={site.site_code}
                      onChange={handleAlertProfileLocationChange(profile)}
                      checked={site.checked}
                      defaultValue={site.checked}
                      disabled={get(profile.alert_profile, "do_all_locations")}
                    />
                    <label htmlFor={site.site_code}>{site.site_code}</label>
                  </div>
                )
              );
            })}
          </div>
        </div>
        <div className="field">
          <label htmlFor="">
            <span>Alert type:</span>
          </label>
          <div className="checkbox-list">
            <div className="checkbox-wrapper">
              <input
                type="checkbox"
                name="alert-text"
                onChange={handleAlertProfileTextChange(profile)}
                checked={get(profile.alert_profile, "do_text")}
              />
              <label htmlFor="alert-text">Text</label>
            </div>
            <div className="checkbox-wrapper">
              <input
                type="checkbox"
                name="alert-email"
                onChange={handleAlertProfileEmailChange(profile)}
                checked={get(profile.alert_profile, "do_email")}
              />
              <label htmlFor="alert-email">Email</label>
            </div>
          </div>
        </div>
        <div className="field field-frequency">
          <label htmlFor="">
            <span>Alert frequency:</span>
          </label>
          <div className="checkbox-list">
            {profile.alert_profile.frequencies.map(frequency => {
              return (
                frequency.checked !== undefined &&
                frequency.frequency && (
                  <div className="checkbox-wrapper" key={frequency.frequency}>
                    <input
                      type="checkbox"
                      name={frequency.frequency}
                      onChange={handleAlertProfileFrequencyChange(profile)}
                      checked={frequency.checked}
                      defaultValue={frequency.checked}
                    />
                    <label htmlFor={frequency.frequency}>
                      {frequency.frequency.toLowerCase()}
                    </label>
                  </div>
                )
              );
            })}
          </div>
        </div>
        <div className="alerts-profile-users-actions field field-full">
          <div className="alerts-profile-users-button-group">
            <button
              type="submit"
              className={saveStatus.saveClass}
              disabled={!saveStatus.saveEnabled}
            >
              {saveStatus.saveText}
            </button>
            <span>or</span>
            <button
              type="button"
              className="alerts-profile-users-cancel-button bare"
              onClick={() => onCancelProfile()}
            >
              cancel
            </button>
          </div>
          <button
            type="button"
            className="alerts-profile-users-delete-button bare"
            onClick={() => onDeleteProfile(profile)}
          >
            Delete Profile
          </button>
        </div>
      </form>
    </div>
  );
}

export default AlertProfileComponent;
