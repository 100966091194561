import { takeLatest, put, select } from "redux-saga/effects";
import { selectAuth, checkLoggedIn } from "utils/utils";
import { InfoAPI } from "../../api";
import { actions, actionCreators } from "./reducers";
import moment from "moment";

/**
 * @typedef {object} Info
 * @property {moment} last_modified
 */

function* requestData(action) {
  // check logged in
  const auth = yield select(selectAuth);
  const loggedIn = checkLoggedIn(auth);

  if (loggedIn) {
    try {
      const apiInfo = yield InfoAPI.getInfo(auth.access_token);
      /**
       * @type {Info}
       */
      const info = {
        ...apiInfo,
        last_modified: moment(apiInfo.last_modified),
      };
      yield put(actionCreators.dataLoaded(info));
    } catch (e) {
      yield put(actionCreators.dataFailed(e.toString()));
    }
  }
}

function* requestDataSaga() {
  yield takeLatest(actions.REQUEST_DATA, requestData);
}

export const SAGAS = [requestDataSaga()];
