import { createDataActions } from "utils/storeUtils";

/**
 * @typedef {import("../../utils/storeUtils").FilterConfigOption} FilterConfigOption
 */

/**
 * @type {FilterConfigOption[]}
 */
const CURRENT_MONTH_CHURN_PACE_TABLE = [
  {
    title: "Current Month",
    options: {
      period: 1,
      period_unit: "month",
    },
  },
];

export const { actions, actionCreators, reducer } = createDataActions(
  "current_month_churn_pace_table",
  CURRENT_MONTH_CHURN_PACE_TABLE
);
