import React from "react";
import { connect } from "react-redux";
import { login, logout } from "../../store/auth";
import { goToChangePassword } from "../../store/changePassword";
import { checkLoggedIn } from "utils/utils";
import SelectSite from "../selectSite";
import Icons from "./../icons";
import "./header.scss";

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDropdown: false,
    };
  }

  toggleDropdown() {
    this.setState(state => ({
      showDropdown: !state.showDropdown,
    }));
  }

  render() {
    const {
      loginFn,
      logoutFn,
      goToChangePasswordFn,
      auth,
      loggedIn,
    } = this.props;
    const { showDropdown } = this.state;

    let selectSite = null;
    let selectSiteLabel = null;
    if (loggedIn) {
      selectSiteLabel = (
        <span className="select-site-label">Select location(s):</span>
      );
      selectSite = <SelectSite />;
    }

    let loginButton = null;
    if (loggedIn) {
      loginButton = null;
    } else {
      loginButton = (
        <div className="login-button">
          <button onClick={loginFn}>
            <span>
              <Icons name="login" />
            </span>
            Log In
          </button>
        </div>
      );
    }

    const dropdown =
      loggedIn && showDropdown ? (
        <div className="menu">
          <div className="menu-inner">
            <ul className="menu-list">
              <li className="menu-item">
                <button onClick={goToChangePasswordFn}>Change Password</button>
              </li>
              <li className="menu-item">
                <button className="button-logout" onClick={logoutFn}>
                  Log Out
                </button>
              </li>
            </ul>
          </div>
        </div>
      ) : null;
    let userComponent = null;
    if (auth.user !== null && auth.user !== undefined) {
      userComponent = (
        <span className="user-component" onClick={() => this.toggleDropdown()}>
          <div className="user-avatar">
            <img
              src={auth.user.picture}
              className="user-img"
              alt={auth.user.nickname}
            />
          </div>
          <div className="username-outer">
            <span className="username-inner">{auth.user.email}</span>
          </div>
        </span>
      );
    }
    return (
      <nav className="header">
        <div className="header-inner">
          <div className="nav-left">
            {selectSiteLabel}
            {selectSite}
          </div>
          <div className="nav-right flex-space-between">
            <div className="login-component">
              {userComponent}
              {loginButton}
              {dropdown}
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default connect(
  state => {
    return {
      auth: state.auth,
      loggedIn: checkLoggedIn(state.auth.auth),
    };
  },
  dispatch => ({
    loginFn: () => dispatch(login()),
    logoutFn: () => dispatch(logout()),
    goToChangePasswordFn: () => dispatch(goToChangePassword()),
  })
)(Header);
