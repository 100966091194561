import React, { useEffect, useState, useCallback } from "react";
import { CustomerNotificationAPI } from "../../api/index";
import RankedTableCore from "../rankedTable/rankedTableCore";
import LoadingSpinner from "../loadingSpinner";
import "../rankedTable/rankedTable.scss";

function CustomerNotificationsList({ access_token }) {
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [customerNotificationList, setCustomerNotificationList] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(1);
  const [pageNext, setPageNext] = useState(null);
  const [pagePrev, setPagePrev] = useState(null);
  const defaultOrdering = "-notification_timestamp";
  const chartTitle = "Customer Notifications";

  const formatter = v => {
    return v.toLocaleString("en", {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  };

  const customerNotificationColumns = [
    {
      name: "Name",
      path: ["customer", "name"],
      sortable: true,
    },
    {
      name: "Notification Type",
      path: ["notification_type"],
      sortable: true,
    },
    {
      name: "Time",
      path: ["notification_timestamp"],
      sortable: true,
      formatter,
    },
  ];

  const groupCustomersByNotifications = [
    {
      id: "all",
      name: "All",
    },
    {
      id: "cc_declined",
      name: "CC Declined",
    },
    {
      id: "cc_expired",
      name: "CC Expired",
    },
    {
      id: "cc_expiring",
      name: "CC Expiring",
    },
    {
      id: "plan_discontinued",
      name: "Plan Discontinued",
    },
    {
      id: "plan_terminated",
      name: "Plan Terminated",
    },
    {
      id: "oil_changed",
      name: "Oil Change",
    },
  ];

  const [groupNotificationsBy, setGroupNotificationsBy] = useState(
    groupCustomersByNotifications[0].id
  );

  function onChangeGroupTimeBy(e) {
    setGroupNotificationsBy(e.target.value);
  }

  async function loadData() {
    setLoadingStatus(true);
    let notificationResponse = {};
    try {
      notificationResponse = await CustomerNotificationAPI.getCustomerNotification(
        access_token,
        {
          notificationType: groupNotificationsBy,
          ordering: defaultOrdering,
          search: searchName,
          page,
          pageSize: 15,
        }
      );
      let notificationData = notificationResponse["results"];
      setPageNext(notificationResponse["next"]);
      setPagePrev(notificationResponse["previous"]);
      formatDate(notificationData);
      setCustomerNotificationList(notificationData);
      setLoadingStatus(false);
    } catch (error) {
      setLoadingStatus(false);
      console.log(error);
    }
  }

  const loadDataCallback = useCallback(loadData, [
    access_token,
    groupNotificationsBy,
    searchName,
    page,
  ]);

  const onChange = e => {
    setFilter(e.target.value);
  };

  const onPrev = e => {
    if (pagePrev) setPage(page - 1);
  };

  const onNext = e => {
    if (pageNext) setPage(page + 1);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setSearchName(filter);
  };

  // GET CC status objects
  useEffect(() => {
    loadDataCallback();
  }, [loadDataCallback]);

  const formatDate = Customers => {
    Customers.map(customer => {
      return (customer["notification_timestamp"] = new Date(
        customer["notification_timestamp"] + "Z"
      ));
    });
    return Customers;
  };

  return (
    <div className="dashboard-widget notifications-chart-wrapper">
      {loadingStatus && <LoadingSpinner />}
      {!loadingStatus && (
        <div className="table-container">
          <div className="title-block">
            <h3 className="title" onClick={loadDataCallback}>
              {chartTitle}
            </h3>
          </div>
          <div className="form-group">
            <div className="notification-status-container">
              <label htmlFor="notifications-status">Status:</label>
              <select
                id="notifications-status"
                className="notifications-chart-select"
                value={groupNotificationsBy}
                onChange={onChangeGroupTimeBy}
              >
                {groupCustomersByNotifications.map(g => (
                  <option key={g.id} value={g.id}>
                    {g.name}
                  </option>
                ))}
              </select>
            </div>

            <form onSubmit={e => handleSubmit(e)} className="filter-form">
              <div className="filter-fields-container">
                <label htmlFor="filter-customers">Search:</label>
                <input
                  id="filter-customers"
                  type="text"
                  placeholder="Filter Customers..."
                  className="filter-input"
                  onChange={onChange}
                  value={filter}
                />
                <input type="submit" value="Filter" className="filter-submit" />
              </div>
            </form>
          </div>
          <RankedTableCore
            data={customerNotificationList}
            config={{
              columns: customerNotificationColumns,
              rowIdPath: ["customer", "sitewatch_id"],
            }}
          />
          <div className="paging-btn-container">
            <button
              className="paging-btn prev"
              onClick={onPrev}
              disabled={!pagePrev}
            >
              Prev
            </button>
            <button
              className="paging-btn next"
              onClick={onNext}
              disabled={!pageNext}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CustomerNotificationsList;
