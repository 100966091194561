import { takeLatest, put, select } from "redux-saga/effects";
import {
  selectAuth,
  checkLoggedIn,
  generateSelectDataState,
  generateSelectedSiteIds,
} from "utils/utils";
import { actions, actionCreators } from "./reducers";
import { ChurnPaceAPI } from "../../api";
import { getAPIOptionsFromDataState } from "utils/filterUtils";

function mapTrend(washSales) {
  return washSales.sort((b, a) => {
    if (a.rate < b.rate) {
      return -1;
    } else if (a.rate > b.rate) {
      return 1;
    } else {
      return 0;
    }
  });
}

function* requestData(action) {
  // check logged in
  const auth = yield select(selectAuth);
  const loggedIn = checkLoggedIn(auth);

  if (loggedIn) {
    try {
      const dataState = yield select(
        generateSelectDataState("currentMonthChurnPaceTable")
      );
      const selectedSiteIds = yield select(generateSelectedSiteIds());
      const options = getAPIOptionsFromDataState(dataState, selectedSiteIds);

      const churnPace = yield ChurnPaceAPI.getChurnPace(auth.access_token, {
        type: "table",
        ...options,
      });

      yield put(
        actionCreators.dataLoaded({
          ...churnPace,
          data: mapTrend(churnPace.data),
        })
      );
    } catch (e) {
      yield put(actionCreators.dataFailed(e.toString()));
    }
  }
}

function* requestDataSaga() {
  yield takeLatest(actions.REQUEST_DATA, requestData);
}

function* onSetFilterId(action) {
  yield put(actionCreators.requestData());
}

function* setFilterSaga() {
  yield takeLatest(actions.SET_FILTER_ID, onSetFilterId);
}

export const SAGAS = [requestDataSaga(), setFilterSaga()];
