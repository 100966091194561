import React from "react";
import { connect } from "react-redux";
import { attemptChangePassword } from "../../store/changePassword";
import PropTypes from "prop-types";
import "./changePassword.scss";

class ChangePassword extends React.Component {
  render() {
    const { changePassword, attemptChangePasswordFn } = this.props;
    return (
      <div className="change-password">
        <div>
          If you would like to change your password, click the button below and
          an email will be sent to you with a link to change it.
        </div>
        <button
          className="pure-button change-password__button"
          onClick={attemptChangePasswordFn}
          type="button"
          disabled={changePassword.loading}
        >
          Change Password
        </button>
        <div className="change-password__message">{changePassword.message}</div>
      </div>
    );
  }
}

ChangePassword.propTypes = {
  attemptChangePasswordFn: PropTypes.func.isRequired,
  changePassword: PropTypes.object,
};

const mapStateToProps = ({ changePassword }) => ({ changePassword });
const mapDispatchToProps = dispatch => ({
  attemptChangePasswordFn: () => dispatch(attemptChangePassword()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
