import { createDataActions } from "utils/storeUtils";

const {
  actions: baseActions,
  actionCreators: baseActionCreators,
  reducer: baseReducer,
} = createDataActions("sites");

// Extend from the base actions
export const actions = {
  ...baseActions,
  SET_SELECTED_SITE_IDS: `SET_SELECTED_SITE_IDS`,
};

// Extend from the base actionCreators
export const actionCreators = {
  ...baseActionCreators,
  setSelectedSiteIds: function setSelectedSiteIds(selectedSiteIds) {
    return {
      type: actions.SET_SELECTED_SITE_IDS,
      payload: selectedSiteIds,
    };
  },
};

const defaultState = {
  loading: false,
  lastUpdated: null,
  data: null,
  error: null,
  selectedSiteIds: [],
};

// Extend from the base reducer
export const reducer = (state = defaultState, action) => {
  const baseState = baseReducer(state, action);

  switch (action.type) {
    case actions.SET_SELECTED_SITE_IDS:
      return {
        ...baseState,
        selectedSiteIds: action.payload,
      };
    default:
      return baseState;
  }
};
