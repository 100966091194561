import { createDataActions } from "utils/storeUtils";

/**
 * @typedef {import("../../utils/storeUtils").FilterConfigOption} FilterConfigOption
 */

/**
 * @type {FilterConfigOption[]}
 */
const WASH_MIX_SOLD_TABLE = [
  {
    title: "Last 30 Days",
    options: {
      period: 30,
      period_unit: "day",
    },
  },
  {
    title: "Last 12 Months",
    options: {
      period: 12,
      period_unit: "month",
    },
  },
];

export const { actions, actionCreators, reducer } = createDataActions(
  "wash_mix_sold_table",
  WASH_MIX_SOLD_TABLE
);
