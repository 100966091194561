import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import ReactDOMServer from "react-dom/server";
import * as d3 from "d3";
import d3Tip from "d3-tip";
import "./intervalChart.scss";
import IntervalTooltip from "../intervalTooltip";
import moment from "moment";
import { pctFormatter } from "utils/formatters";

class IntervalChart extends Component {
  intervalChartViz = React.createRef();

  componentDidUpdate(prevProps) {
    const { lastUpdated } = this.props;

    if (lastUpdated !== prevProps.lastUpdated) {
      // redraw
      this.draw();
    }
  }

  getDateRange(xMin, xMax, currentFilter) {
    if (currentFilter.options.period_unit === "month_full") {
      const xMaxPlus1Month = d3.timeMonth.offset(xMax, 1);
      return d3.timeMonths(xMin, xMaxPlus1Month);
    }

    const xMaxPlus1Day = d3.timeDay.offset(xMax, 1);
    return d3.timeDays(xMin, xMaxPlus1Day);
  }

  /**
   * TODO: remove this when this gets fixed.
   * http://lab.stormyourmarket.com/suds/dashboard/issues/20
   *
   * We need to add empty days for the missing dates.
   * @param {Array} data
   * @param {Date} xMin
   * @param {Date} xMax
   */
  addEmptyValues(data, xMin, xMax, currentFilter) {
    const dateRange = this.getDateRange(xMin, xMax, currentFilter);

    const newCurrentData = dateRange.map(date => {
      const datum = { date };
      // Search if a datum matching the date is in the data set
      const foundDatum = data.current.find(d => {
        return date.getTime() === d.date.getTime();
      });

      datum.value = foundDatum ? foundDatum.value : 0;

      return datum;
    });

    const translatePeriodUnit =
      currentFilter.options.period_unit === "month_full" ? "months" : "days";

    const pastXMax = moment(xMax)
      .subtract(currentFilter.options.period, translatePeriodUnit)
      .toDate();

    const pastXMin = moment(xMin)
      .subtract(currentFilter.options.period, translatePeriodUnit)
      .toDate();

    const pastDateRange = this.getDateRange(pastXMin, pastXMax, currentFilter);

    const newPastData = pastDateRange.map(date => {
      const datum = { date };
      // Search if a datum matching the date is in the data set
      const foundDatum = data.past.find(
        d => date.getTime() === d.date.getTime()
      );

      datum.value = foundDatum ? foundDatum.value : 0;

      return datum;
    });

    return { ...data, current: newCurrentData, past: newPastData };
  }

  normalizeData(data, xMin, xMax, currentFilter) {
    return this.addEmptyValues(data, xMin, xMax, currentFilter);
  }

  draw() {
    const {
      data,
      filters,
      selectedFilterId,
      config,
      toolTipLabel,
      endDate,
    } = this.props;

    const currentFilter = filters[selectedFilterId];
    const t = d3.transition().duration(500);
    const x_ticks =
      currentFilter.xTicksInterval || (d => d3.timeDay.count(0, d) % 7 === 0);

    const svgNode = ReactDOM.findDOMNode(this.intervalChartViz);
    const svg = d3.select(svgNode);
    const vizGroup = svg.select("g.viz-group");

    // makes margins easier
    const margin = { top: 50, right: 100, bottom: 50, left: 50 };
    const width = 1000 - margin.right - margin.left;
    const height = 230 - margin.top - margin.bottom;

    svg
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .attr(
        "viewBox",
        `0 0 ${width + margin.left + margin.right} ${height +
          margin.top +
          margin.bottom}`
      );

    vizGroup.attr(
      "transform",
      "translate(" + margin.left + "," + margin.top + ")"
    );

    // scales
    let xMax;
    let xMin;
    let normalizedData;
    if (currentFilter.options.period_unit === "month_full") {
      xMax = d3.max(data.current, d => d.date);
      xMin = d3.min(data.current, d => d.date);
    } else {
      // This is needed because the API doesn't return the empty values if the
      // data returned is a subset of the range we are requesting. We set today's
      // date as xMax and calculate the xMin from the filter. Then we backfill
      // any empty ones.
      //
      // TODO: remove this when this gets fixed.
      // http://lab.stormyourmarket.com/suds/dashboard/issues/20
      xMax = moment(endDate || new Date())
        .startOf("day")
        .subtract(1, "days")
        .toDate();

      xMin = moment(xMax)
        .subtract(currentFilter.options.period - 1, "days")
        .toDate();
    }

    normalizedData = this.normalizeData(data, xMin, xMax, currentFilter);

    const xScale = d3
      .scaleTime()
      .domain([xMin, xMax])
      .range([0, width]);

    const pastCurrentWidthDiff =
      xScale(normalizedData.current[0].date) -
      xScale(normalizedData.past[0].date);

    const yTickFormatter = config.yTickFormatter
      ? config.yTickFormatter
      : v => v;
    const yValueFormatter = config.yValueFormatter
      ? config.yValueFormatter
      : v => v;

    const yMin = d3.min([
      d3.min(normalizedData.current, d => d.value),
      d3.min(normalizedData.past, d => d.value),
    ]);
    const yMax = d3.max([
      d3.max(normalizedData.current, d => d.value),
      d3.max(normalizedData.past, d => d.value),
    ]);

    const line = d3
      .line()
      .x(d => xScale(d.date))
      .y(d => yScale(d.value))
      .curve(d3.curveLinear);

    const yScale = d3
      .scaleLinear()
      .domain([yMin, yMax])
      .range([height, 0]);

    vizGroup
      .select("g.x-axis")
      .attr("transform", `translate(0, ${height})`)
      .transition(t)
      .call(
        d3
          .axisBottom(xScale)
          .ticks(x_ticks)
          .tickFormat(
            currentFilter.hasOwnProperty("xTickFormat")
              ? currentFilter["xTickFormat"]
              : undefined
          )
      );

    vizGroup
      .select("g.y-axis")
      .attr("transform", `translate(${width},0)`)
      .transition(t)
      .call(
        d3
          .axisRight(yScale)
          .ticks(5)
          .tickFormat(yTickFormatter)
      );

    // CURRENT
    const currentUpdate = vizGroup
      .selectAll("path.current-data-path")
      .data([normalizedData.current]);

    currentUpdate
      .transition(t)
      .select("path.current-data-path")
      .attr("d", line);

    currentUpdate
      .enter()
      .append("path")
      .attr("class", "current-data-path")
      .attr("d", line);

    currentUpdate.transition(t).attr("d", line);
    currentUpdate.exit().remove();

    // PAST
    const pastUpdate = vizGroup
      .selectAll("path.past-data-path")
      .data([normalizedData.past]);

    pastUpdate
      .transition(t)
      .select("path.past-data-path")
      .attr("d", line);

    pastUpdate
      .enter()
      .append("path")
      .attr("class", "past-data-path")
      // The past linechart is shifted X days behind the current linechart.
      // We need to shift the past linechart to map over the current linechart.
      .attr("transform", `translate(${pastCurrentWidthDiff},0)`)
      .attr("d", line);

    pastUpdate
      .transition(t)
      .attr("d", line)
      .attr("transform", `translate(${pastCurrentWidthDiff},0)`);
    pastUpdate.exit().remove();

    /**
     *  TOOLTIP
     */

    const formatTooltipDate = d3.timeFormat("%b %_d");

    // Initialize tooltip
    const tip = d3Tip()
      .offset((d, i, a) => {
        // An offset needs to be applied because
        // the tooltip has a small overlap with hover-rect.
        // Applying a 2 pixel offset in each direction.
        if (i > a.length / 2) {
          // Tooltip is positioned 'w'
          return [0, -2];
        }
        // Tooltip is positioned 'e'
        return [0, 2];
      })
      .direction((d, i, a) => {
        // Position the tooltip on the otherside as to not
        // render the tooltip off screen.
        if (i > a.length / 2) {
          return "w";
        }
        return "e";
      })
      .html(d =>
        ReactDOMServer.renderToStaticMarkup(
          <IntervalTooltip
            currentDate={formatTooltipDate(d.current.date)}
            pastDate={formatTooltipDate(d.past.date)}
            label={toolTipLabel}
            value={yValueFormatter(d.current.value)}
            changeFormatted={pctFormatter(
              Math.round(parseFloat(d.past.value) * 100) === 0
                ? 0.0
                : (d.current.value - d.past.value) / d.past.value
            )}
            changeRaw={
              Math.round(parseFloat(d.past.value) * 100) === 0
                ? 0.0
                : (d.current.value - d.past.value) / d.past.value
            }
          />
        )
      );

    // Invoke the tip in the context of your visualization
    vizGroup.call(tip);

    // Merging is necessary because the tooltip needs information from
    // both 'current' and 'past'.
    const mergedDataPoints = normalizedData.current.map((current, i) => ({
      current,
      past: normalizedData.past[i],
    }));

    // Update, enter, exit
    const tooltipUpdate = vizGroup
      .selectAll("g.interval-tooltip-group")
      .data(mergedDataPoints);

    const hoverRectWidth = width / mergedDataPoints.length;

    const hoverGroupEnter = tooltipUpdate
      .enter()
      .append("g")
      .attr("class", "interval-tooltip-group")
      .on("mouseover", tip.show)
      .on("mouseout", tip.hide);

    hoverGroupEnter
      .append("rect")
      .attr("class", "hover-rect")
      .attr("x", d => xScale(d.current.date))
      .attr("y", 0)
      .attr("width", hoverRectWidth)
      .attr("height", height)
      .style("fill", "transparent")
      .attr("transform", `translate(-${hoverRectWidth / 2},0)`);

    hoverGroupEnter
      .append("line")
      .attr("class", "tooltip-line")
      .attr("x1", d => xScale(d.current.date))
      .attr("y1", 0)
      .attr("x2", d => xScale(d.current.date))
      .attr("y2", height)
      .attr("stroke-width", 1)
      .attr("stroke", "#333");

    hoverGroupEnter
      .append("g")
      .append("circle")
      .attr("class", "current-circle")
      .attr("r", 7)
      .attr("cy", d => yScale(d.current.value))
      .attr("cx", d => xScale(d.current.date));

    hoverGroupEnter
      .append("g")
      .append("circle")
      .attr("class", "past-circle")
      .attr("r", 7)
      .attr("cy", d => yScale(d.past.value))
      .attr("cx", d => xScale(d.past.date))
      // The past linechart is shifted X days behind the current linechart.
      // We need to shift the past linechart to map over the current linechart.
      .attr("transform", `translate(${pastCurrentWidthDiff},0)`);

    tooltipUpdate
      .transition(t)
      .select("rect.hover-rect")
      .attr("x", d => xScale(d.current.date))
      .attr("width", hoverRectWidth)
      .attr("transform", `translate(-${hoverRectWidth / 2},0)`);

    tooltipUpdate
      .transition(t)
      .select("line.tooltip-line")
      .attr("x1", d => xScale(d.current.date))
      .attr("x2", d => xScale(d.current.date));

    tooltipUpdate
      .transition(t)
      .select("circle.current-circle")
      .attr("cy", d => yScale(d.current.value))
      .attr("cx", d => xScale(d.current.date));

    tooltipUpdate
      .transition(t)
      .select("circle.past-circle")
      .attr("cy", d => yScale(d.past.value))
      .attr("cx", d => xScale(d.past.date))
      // The past linechart is shifted X days behind the current linechart.
      // We need to shift the past linechart to map over the current linechart.
      .attr("transform", `translate(${pastCurrentWidthDiff},0)`);

    tooltipUpdate.exit().remove();
  }

  render() {
    return (
      <div className="interval-chart-container">
        <svg
          className="interval-chart-viz"
          ref={ref => (this.intervalChartViz = ref)}
        >
          <g className="viz-group">
            <g className="x-axis" />
            <g className="y-axis" />
          </g>
        </svg>
      </div>
    );
  }
}

IntervalChart.propTypes = {
  config: PropTypes.shape({
    yFormatter: PropTypes.func,
  }).isRequired,
  toolTipLabel: PropTypes.string.isRequired,
  data: PropTypes.object,
  filters: PropTypes.array.isRequired,
  selectedFilterId: PropTypes.number.isRequired,
  endDate: PropTypes.instanceOf(Date),
};

export default IntervalChart;
