import React from "react";
import PropTypes from "prop-types";
import { baseComponentPropTypes } from "utils/utils";
import "./numberComponent.scss";
import TitleComponent from "../titleComponent";
import SiteSelectedAndFilter from "../siteSelectedAndFilter";

class NumberComponent extends SiteSelectedAndFilter {
  render() {
    const { data, config, title, filters, selectedFilterId, info } = this.props;
    return (
      <div className="number-component-container">
        <TitleComponent
          title={title}
          filters={filters}
          selectedFilterId={selectedFilterId}
          onChangeFilter={(id, dateRange, groupBy) =>
            this.onChangeFilter(id, dateRange, groupBy)
          }
          onClickTitle={this.requestData.bind(this)}
          info={info}
        />
        <div className="number-component-number">
          {data && config.formatter ? config.formatter(data) : data}
        </div>
      </div>
    );
  }
}

NumberComponent.propTypes = {
  config: PropTypes.shape({
    formatter: PropTypes.func,
  }),
  ...baseComponentPropTypes,
};

export default NumberComponent;
