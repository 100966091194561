import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import ReactDOMServer from "react-dom/server";
import * as d3 from "d3";
import d3Tip from "d3-tip";
import "./lineChart.scss";
import Tooltip from "../tooltip";
import { colorScheme } from "../../themes";

/**
 *
 * @param {Date} startDate
 * @param {Date} endDate
 * @returns {number}
 */
function daysBetween(startDate, endDate) {
  const diffMillis = Math.abs(endDate.getTime() - startDate.getTime());
  return diffMillis / (1000 * 60 * 60 * 24);
}

/**
 *
 * @param {Date} startDate
 * @param {Date} endDate
 * @returns {d3.AxisTimeInterval}
 */
export function dateRangeToTimeInterval(startDate, endDate) {
  const days = daysBetween(startDate, endDate);

  if (days < 10) {
    return d3.timeDay.every(1);
  } else if (days < 20) {
    return d3.timeDay.filter(d => d3.timeDay.count(0, d) % 3 === 0);
  } else if (days < 31) {
    return d3.timeDay.filter(d => d3.timeDay.count(0, d) % 5 === 0);
  } else if (days < 62) {
    return d3.timeWeek.filter(d => d3.timeWeek.count(0, d) % 1 === 0);
  } else if (days < 100) {
    return d3.timeWeek.filter(d => d3.timeWeek.count(0, d) % 2 === 0);
  } else if (days < 180) {
    return d3.timeMonth.filter(d => d3.timeMonth.count(0, d) % 1 === 0);
  } else if (days < 365) {
    return d3.timeMonth.filter(d => d3.timeMonth.count(0, d) % 2 === 0);
  } else {
    return d3.timeYear.filter(d => d3.timeYear.count(0, d) % 1 === 0);
  }
}

/**
 *
 * @param {Date} startDate
 * @param {Date} endDate
 * @returns {d3.AxisTimeInterval}
 */
export function dateRangeToTimeIntervalMonth(startDate, endDate) {
  const days = daysBetween(startDate, endDate);

  if (days < 180) {
    return d3.timeWeek.filter(d => d3.timeWeek.count(0, d) % 1 === 0);
  } else if (days < 365) {
    return d3.timeWeek.filter(d => d3.timeWeek.count(0, d) % 2 === 0);
  } else {
    return d3.timeWeek.filter(d => d3.timeWeek.count(0, d) % 4 === 0);
  }
}

/**
 *
 * @param {object} params
 * @param {Array}  params.data
 * @param {Array}  params.selectedGroupsIds,
 * @param {String} params.groupKey
 */
function getSelectedGroupsData({ data, selectedGroupsIds, groupKey }) {
  if (data && selectedGroupsIds && selectedGroupsIds.length > 0) {
    if (!data.filter) {
      console.log("no filter, what is data:");
      console.log(data);
      console.log("groups:", selectedGroupsIds);
      console.trace();
    }
    return data.filter(
      datum => selectedGroupsIds.indexOf(datum[groupKey].id) > -1
    );
  } else {
    return data;
  }
}

function getColorScale(dataLength) {
  // Give it a dumb number in case the data is empty.
  const colors = colorScheme(Math.max(dataLength, 3));
  // some of our color domains have a max number, and our data
  // could be longer than that
  // if so, let's extend the colors to match our length
  if (dataLength > colors.length) {
    colors.push(...colors);
  }

  // Copy the colors to a new array, because `reverse`
  // affects the original.
  const reverseColors = [...colors].reverse();
  return d3.scaleOrdinal(d3.range(dataLength), reverseColors);
}

/**
 *
 * @param {d3.TimeInterval} timeInterval
 * @param {Date} xMin
 * @param {Date} xMax
 * @returns {Date[]}
 */
function getDateRange(timeInterval, xMin, xMax) {
  const xMaxPlus1 = timeInterval.offset(xMax, 1);
  return timeInterval.range(xMin, xMaxPlus1);
}

/**
 * Tooltips require a mix of all the data to represent all datapoints
 * at that single date.
 * @param {array} data
 * @param {Date} xMin
 * @param {Date} xMax
 * @param {d3.TimeInterval} timeInterval
 * @param {string} groupKey
 */
function mergeDataPoints(data, xMin, xMax, timeInterval, groupKey) {
  const dateRange = getDateRange(timeInterval, xMin, xMax);

  const mergedDataPoints = [];

  // Iterate through all datapoints and stack on
  // each datapoint at the same index
  dateRange.forEach((date, index) => {
    const datum = { date, key: index, groupData: [], totalValue: 0 };

    data.forEach((groupDatum, groupIndex) => {
      const { date, value, stackedBaseValue } = groupDatum.data[index];
      datum.groupData.push({
        ...groupDatum[groupKey],
        value,
        stackedBaseValue,
        date,
        key: groupIndex,
      });

      datum.totalValue += value;
    });

    mergedDataPoints.push(datum);
  });
  return mergedDataPoints;
}

/**
 * TODO: remove this when this gets fixed.
 * http://lab.stormyourmarket.com/suds/dashboard/issues/20
 *
 * We need to add empty days for the missing dates.
 * @param {Array} data
 * @param {Date} xMin
 * @param {Date} xMax
 */
function addEmptyValues(data, xMin, xMax, timeInterval) {
  const dateRange = getDateRange(timeInterval, xMin, xMax);
  const newData = [];
  data.forEach(groupData => {
    const groupDataPoints = [];
    dateRange.forEach(date => {
      const datum = { date };
      // Search if a datum matching the date is in the data set
      const foundDatum = groupData.data.find(
        d => date.getTime() === d.date.getTime()
      );

      datum.value = foundDatum ? foundDatum.value : 0;

      groupDataPoints.push(datum);
    });

    const newSiteData = {
      ...groupData,
      data: groupDataPoints,
    };

    newData.push(newSiteData);
  });

  return newData;
}

/**
 * Add the stacked base values for each datum. This is used
 * when we are stacking the data, one chart on top of the other
 * to visualize the stacked area charts.
 * @param {Array} data
 * @param {Date} xMin
 * @param {Date} xMax
 * @param {d3.TimeInterval} timeInterval
 * @param {string} groupKey
 */
function addStackedBaseValue(data, xMin, xMax, timeInterval, groupKey) {
  // reconstruct a new data object.
  const newData = [];
  data.forEach(groupData => {
    newData.push({
      [groupKey]: { ...groupData[groupKey] },
      data: [],
    });
  });

  const dateRange = getDateRange(timeInterval, xMin, xMax);

  // Iterate through all datapoints and stack on
  // each datapoint at the same index
  dateRange.forEach((date, dataIndex) => {
    let stackedBaseValue = 0;

    data.forEach((groupData, groupDataIndex) => {
      const datum = groupData.data[dataIndex];

      newData[groupDataIndex].data.push({
        ...datum,
        stackedBaseValue,
      });

      // update stackedBaseValue
      stackedBaseValue += datum.value;
    });
  });

  return newData;
}

/**
 *
 * @param {object[]} data
 * @param {Date} xMin
 * @param {Date} xMax
 * @param {d3.TimeInterval} timeInterval
 * @param {string} groupKey
 * @returns
 */
function normalizeData(data, xMin, xMax, timeInterval, groupKey) {
  const data1 = addEmptyValues(data, xMin, xMax, timeInterval);
  const data2 = addStackedBaseValue(data1, xMin, xMax, timeInterval, groupKey);
  return data2;
}

/**
 *
 * @param {string} name
 * @returns {string}
 */
const shortenName = (name, length = 3) => {
  if (
    process.env.REACT_APP_SHORTEN_SITE &&
    process.env.REACT_APP_SHORTEN_SITE === "true"
  ) {
    return name.substring(name.length - length, name.length);
  } else {
    return name;
  }
};

/**
 *
 * @typedef {object} LineChartConfig
 * @property {(s: string) => string} [yTickArguments]
 * @property {(s: string) => string} [legendNameFormatter]
 */

/**
 *
 * @param {object} params
 * @param {boolean} params.stacked
 * @param {Array} params.data
 * @param {boolean} params.loading
 * @param {object} params.config
 * @param {object[]} params.selectedGroupsIds
 * @param {d3.TimeInterval} params.timeInterval
 * @param {d3.TimeInterval} params.xTicksInterval
 * @param {string} params.groupKey
 */
function LineChartCore(params) {
  const {
    stacked,
    data,
    loading,
    config,
    selectedGroupsIds,
    timeInterval,
    xTicksInterval,
    groupKey,
    filter = {},
  } = params;

  const lineChartViz = useRef(null);

  useEffect(() => {
    /**
     *
     * @param {object} options
     * @param {boolean} options.stacked
     * @param {Array} options.data
     * @param {d3.TimeInterval} options.timeInterval
     * @param {d3.TimeInterval} options.xTicksInterval
     * @param {object[]} options.selectedGroupsIds
     * @param {LineChartConfig} options.config
     * @param {string} options.groupKey
     * @param {object} options.filter
     */
    function draw({
      config,
      stacked,
      data: initialData,
      timeInterval,
      selectedGroupsIds,
      xTicksInterval,
      groupKey = "group",
      filter,
    }) {
      let data = getSelectedGroupsData({
        data: initialData,
        selectedGroupsIds,
        groupKey,
      });

      // const currentFilter = this.getCurrentFilter();
      const ROW_SIZE = 7;
      const MIN_ROW_SIZE = 4;
      const t = d3.transition().duration(500);

      const svgNode = ReactDOM.findDOMNode(lineChartViz.current);
      const svg = d3.select(svgNode);
      const vizGroup = svg.select("g.viz-group");

      // makes margins easier
      const legendMargin = { top: data.length < ROW_SIZE ? 50 : 70 };
      const bottomMargin = 50 + legendMargin.top;
      const margin = { top: 50, right: 50, bottom: bottomMargin, left: 50 };
      const width = 1000 - margin.right - margin.left;
      const height = 500 - margin.top - margin.bottom;
      const computedRowSize = Math.max(
        Math.ceil(data.length / 3),
        MIN_ROW_SIZE
      );
      const legendWidth = Math.floor(width / computedRowSize);

      // Color scale to use for lines and legends
      const cScale = getColorScale(data.length);

      svg
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .attr(
          "viewBox",
          `0 0 ${width + margin.left + margin.right} ${height +
            margin.top +
            margin.bottom}`
        );

      vizGroup.attr(
        "transform",
        "translate(" + margin.left + "," + margin.top + ")"
      );

      // scales
      // is invalid and min/max is undefined
      const xMin = d3.min(data, d => d3.min(d.data, d2 => d2.date));
      const xMax = d3.max(data, d => d3.max(d.data, d2 => d2.date));

      const xScale = d3
        .scaleTime()
        .domain([xMin, xMax])
        .range([0, width]);

      const yFormatter =
        config.hasOwnProperty("yFormatter") && config.yFormatter
          ? config.yFormatter
          : v => `${v}`;

      const normalizedData = normalizeData(
        data,
        xMin,
        xMax,
        timeInterval,
        groupKey
      );

      let yMin;
      let yMax;

      if (stacked) {
        yMin = 0;
        yMax = d3.max(normalizedData, d =>
          d3.max(d.data, d2 => d2.value + d2.stackedBaseValue)
        );
      } else {
        yMin = d3.min(normalizedData, d => d3.min(d.data, d2 => d2.value));
        yMax = d3.max(normalizedData, d => d3.max(d.data, d2 => d2.value));
      }

      const yScale = d3
        .scaleLinear()
        .domain([yMin, yMax])
        .range([height, 0]);

      const line = d3
        .area()
        .x(d => xScale(d.date))
        .y1(d => yScale(d.value))
        .y0(d => yScale(d.value))
        .curve(d3.curveLinear);

      const area = d3
        .area()
        .x(d => xScale(d.date))
        .y1(d => yScale(d.value + d.stackedBaseValue))
        .y0(d => yScale(d.stackedBaseValue));

      vizGroup
        .select("g.x-axis")
        .attr("transform", `translate(0, ${height})`)
        .transition(t)
        .call(
          d3
            .axisBottom(xScale)
            .ticks(xTicksInterval)
            .tickFormat(
              filter.hasOwnProperty("xTickFormat") ? filter.xTickFormat : null
            )
        );
      const yTickArguments = config.hasOwnProperty("yTickArguments")
        ? config.yTickArguments
        : [5, "s"];

      vizGroup
        .select("g.y-axis")
        .attr("transform", `translate(0, 0)`)
        .transition(t)
        .call(
          d3.axisLeft(yScale).tickArguments(yTickArguments)
          // .tickFormat(yTickFormat)
        );

      // Update, enter, exit
      const update = vizGroup
        .selectAll("g.data-path-group")
        .data(normalizedData, d => d[groupKey].id);

      update
        .select("path.data-path")
        .style("stroke", (d, i) => cScale(i))
        .style("stroke-width", 5)
        .transition(t)
        .style("fill", (d, i) => (stacked ? cScale(i) : "transparent"))
        .attr("d", d => (stacked ? area(d.data) : line(d.data)));

      update
        .enter()
        .append("g")
        .attr("class", "data-path-group")
        .append("path")
        .attr("class", "data-path")
        .style("stroke", (d, i) => cScale(i))
        .style("stroke-width", 5)
        .style("fill", (d, i) => (stacked ? cScale(i) : "transparent"))
        .transition(t)
        .attr("d", d => (stacked ? area(d.data) : line(d.data)));

      update.exit().remove();

      /**
       *  TOOLTIP
       */

      const formatTooltipDate = d3.timeFormat("%b %_d, %Y");
      const formatSite = d => d.name;

      // Initialize tooltip
      const tip = d3Tip()
        .offset([-8, 0])
        .attr("class", "d3-tip")
        .direction((d, i, a) => {
          // Position the tooltip on the otherside as to not
          // render the tooltip off screen.
          if (i > a.length / 2) {
            return "w";
          }
          return "e";
        })
        .html(d => {
          let groupData = [...d.groupData];

          if (stacked) {
            groupData = groupData.reverse();
          }

          return ReactDOMServer.renderToStaticMarkup(
            <Tooltip
              date={formatTooltipDate(d.date)}
              siteData={groupData}
              formatSite={formatSite}
              formatValue={yFormatter}
              showTotal={stacked}
              totalValue={yFormatter(d.totalValue)}
            />
          );
        });

      // Invoke the tip in the context of your visualization
      vizGroup.call(tip);

      const mergedDataPoints = mergeDataPoints(
        normalizedData,
        xMin,
        xMax,
        timeInterval,
        groupKey
      );

      // Update, enter, exit
      const tooltipData = vizGroup
        .selectAll("g.tooltip-group")
        .data(mergedDataPoints, d => d.key);

      const hoverRectWidth = width / mergedDataPoints.length;

      const tooltipEnter = tooltipData
        .enter()
        .append("g")
        .attr("class", "tooltip-group")
        .on("mouseover", tip.show)
        .on("mouseout", tip.hide);

      tooltipEnter
        .append("rect")
        .attr("class", "hover-rect")
        .attr("x", d => xScale(d.date))
        .attr("y", 0)
        .attr("width", hoverRectWidth)
        .attr("height", height)
        .style("fill", "transparent")
        .attr("transform", `translate(-${hoverRectWidth / 2},0)`);

      tooltipEnter
        .append("line")
        .attr("class", "tooltip-line")
        .attr("x1", d => xScale(d.date))
        .attr("y1", 0)
        .attr("x2", d => xScale(d.date))
        .attr("y2", height)
        .attr("stroke-width", 1)
        .attr("stroke", "#666");

      const tooltipUpdate = tooltipData.transition(t);

      tooltipUpdate
        .select("rect.hover-rect")
        .attr("x", d => xScale(d.date))
        .attr("width", hoverRectWidth)
        .attr("transform", `translate(-${hoverRectWidth / 2},0)`);

      tooltipUpdate
        .select("line.tooltip-line")
        .attr("x1", d => xScale(d.date))
        .attr("x2", d => xScale(d.date));

      tooltipData.exit().remove();

      // circles
      //
      // Reselect the tooltip-group, because on initial load,
      // nothing would have been in the selection until the 'enter'
      // was run. Selecting now will select everything from the
      // previous 'enter'.
      const circleData = vizGroup
        .selectAll("g.tooltip-group")
        .selectAll("circle.tooltip-circle")
        .data(d => d.groupData);

      circleData
        .enter()
        .append("circle")
        .attr("class", "tooltip-circle")
        .attr("r", 7)
        .attr("cy", d =>
          yScale(stacked ? d.value + d.stackedBaseValue : d.value)
        )
        .attr("cx", d => xScale(d.date));

      // Select with the vizGroup because we are using
      // nested data.
      vizGroup
        .selectAll("circle.tooltip-circle")
        .transition(t)
        .attr("cy", d =>
          yScale(stacked ? d.value + d.stackedBaseValue : d.value)
        )
        .attr("cx", d => xScale(d.date));

      circleData.exit().remove();

      /**
       * LEGEND
       */
      const legendGroup = vizGroup.select("g.legend");

      legendGroup.attr(
        "transform",
        `translate(0, ${height +
          legendMargin.top / (data.length > ROW_SIZE ? 2 : 1)})`
      );

      const legendData = legendGroup
        .selectAll("g.legend-item")
        .data(normalizedData, d => d[groupKey].id);

      // enter
      const legendEnter = legendData
        .enter()
        .append("g")
        .attr("class", "legend-item")
        .attr(
          "transform",
          (d, i) =>
            `translate(${(i % computedRowSize) * legendWidth}, ${30 *
              Math.floor(i / computedRowSize)})`
        );

      legendEnter
        .append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", 23)
        .attr("height", 15)
        .style("fill", (d, i) => cScale(i))
        .attr("transform", "translate(0,6)");

      legendEnter
        .append("text")
        .text(d => {
          // Each legend item is a fixed length of 250,
          // Long names will collide with other items.
          // Elipse the text beyond 10 chars.
          let name = d[groupKey].name;
          // if we should shorten the site name, only include last 3 chars
          if (config.legendNameFormatter) {
            name = config.legendNameFormatter(name);
          } else {
            // default to our old chart way of shortening site
            name = shortenName(name);
          }
          const maxCharLength = 20;
          if (name.length > maxCharLength) {
            // Remove one extra character because
            // the '...' only takes up the space of one character.
            return name.substring(0, maxCharLength - 1) + "...";
          } else {
            return name;
          }
        })
        .attr("font-size", "16px")
        .attr("font-family", "Arial, Helvetica, 'Lucida Grande', sans-serif")
        .attr("font-weight", "bold")
        .style("fill", (d, i) => cScale(i))
        .attr("dominant-baseline", "text-before-edge")
        .attr("transform", "translate(25,5)");

      // update
      legendData.attr(
        "transform",
        (d, i) =>
          `translate(${(i % computedRowSize) * legendWidth}, ${30 *
            Math.floor(i / computedRowSize)})`
      );

      legendData.select("rect").style("fill", (d, i) => cScale(i));

      legendData.select("text").style("fill", (d, i) => cScale(i));

      // exit
      legendData.exit().remove();
    }

    if (!loading) {
      draw({
        stacked,
        data,
        loading,
        config,
        selectedGroupsIds,
        timeInterval,
        xTicksInterval,
        groupKey,
        filter,
      });
    }
  }, [
    stacked,
    data,
    loading,
    config,
    selectedGroupsIds,
    timeInterval,
    xTicksInterval,
    groupKey,
    filter,
  ]);

  return (
    <div className="line-chart-container">
      <svg className="line-chart-viz" ref={lineChartViz}>
        <g className="viz-group">
          <g className="x-axis" />
          <g className="y-axis" />
          <g className="legend" />
        </g>
      </svg>
    </div>
  );
}

export default LineChartCore;
