import { createDataActions } from "utils/storeUtils";
import * as d3 from "d3";

/**
 * @typedef {import("../../utils/storeUtils").FilterConfigOption} FilterConfigOption
 */

/**
 * @type {FilterConfigOption[]}
 */
const TOTAL_DEPOSITS_INTERVAL_FILTERS = [
  {
    title: "Last 7 Days",
    options: {
      period: 7,
      period_unit: "day",
    },
    xTickFormat: d3.timeFormat("%m/%d/%y"),
    xTicksInterval: d3.timeDay.filter(d => d3.timeDay.count(0, d) % 2 === 0),
  },
  {
    title: "Last 14 Days",
    options: {
      period: 14,
      period_unit: "day",
    },
    xTickFormat: d3.timeFormat("%m/%d/%y"),
    xTicksInterval: d3.timeDay.filter(d => d3.timeDay.count(0, d) % 2 === 0),
  },
  {
    title: "Last 28 Days",
    options: {
      period: 28,
      period_unit: "day",
    },
    xTickFormat: d3.timeFormat("%m/%d/%y"),
    xTicksInterval: d3.timeDay.filter(d => d3.timeDay.count(0, d) % 7 === 0),
  },
  {
    title: "Last Year",
    options: {
      period: 12,
      period_unit: "month_full",
    },
    xTickFormat: d3.timeFormat("%m/%y"),
    xTicksInterval: d3.timeMonth.filter(
      d => d3.timeMonth.count(0, d) % 2 === 0
    ),
  },
];

export const { actions, actionCreators, reducer } = createDataActions(
  "total_deposits_interval",
  TOTAL_DEPOSITS_INTERVAL_FILTERS
);
