import React from "react";
import { connect } from "react-redux";
import { actionCreators } from "../../store/ccStatusChangedTable";
import RankedTable from "../rankedTable";
import "../rankedTable/rankedTable.scss";

const percentageFormatter = v => {
  if (v != null) {
    return v.toLocaleString("en", {
      useGrouping: true,
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
};

const dollarFormatter = v => {
  return (
    "$" +
    v.toLocaleString("en", {
      useGrouping: true,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  );
};

const statusNameLookup = name => {
  switch (name) {
    case "cc_expired":
      return "Expired";
    case "cc_expiring":
      return "Expiring Soon";
    case "cc_declined":
      return "Declined";
    default:
      return "Undefined";
  }
};

const customerNotificationColumns = [
  {
    name: "Status",
    path: ["status_name"],
    sortable: true,
    formatter: statusNameLookup,
  },
  {
    name: "Notifications Sent",
    path: ["total"],
    sortable: true,
  },
  {
    name: "CC Updates",
    path: ["cc_updated_count"],
    sortable: true,
  },
  {
    name: "% CC Updated",
    path: ["cc_updated_pct"],
    sortable: true,
    formatter: percentageFormatter,
  },
  {
    name: "Recharge Revenue Recaptured",
    path: ["revenue_recaptured"],
    sortable: true,
    formatter: dollarFormatter,
  },
  {
    name: "Overall Recovered",
    path: ["good_status_count"],
    sortable: true,
  },
  {
    name: "% Overall Recovered",
    path: ["good_status_pct"],
    sortable: true,
    formatter: percentageFormatter,
  },
];

class CCStatusChangedTable extends React.Component {
  render() {
    const {
      data,
      requestData,
      dataLoaded,
      dataFailed,
      filters,
      selectedFilterId,
      setFilterId,
      loading,
      error,
      sites,
    } = this.props;

    return (
      <div
        className={`dashboard-widget
        ${error ? "error" : ""}        
        ${loading ? "loading" : ""} cc-status-changed-chart-wrapper`}
      >
        <RankedTable
          requestData={requestData}
          dataLoaded={dataLoaded}
          dataFailed={dataFailed}
          title="Successful Credit Card Status Changed Customers"
          filters={filters}
          selectedFilterId={selectedFilterId}
          setFilterId={setFilterId}
          config={{
            columns: customerNotificationColumns,
            rowIdPath: ["status_name"],
            // valuePath: ["data"],
            // showRank: true,
          }}
          sites={sites}
          {...data}
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    data: state.ccStatusChangedTable,
    lastUpdated: state.ccStatusChangedTable.lastUpdated,
    filters: state.ccStatusChangedTable.filters,
    selectedFilterId: state.ccStatusChangedTable.selectedFilterId,
    loading: state.ccStatusChangedTable.loading,
    error: state.ccStatusChangedTable.error,
    sites: state.sites,
  }),
  dispatch => ({
    requestData: options => dispatch(actionCreators.requestData(options)),
    dataLoaded: () => dispatch(actionCreators.dataLoaded()),
    dataFailed: () => dispatch(actionCreators.dataFailed()),
    setFilterId: id => dispatch(actionCreators.setFilterId(id)),
  })
)(CCStatusChangedTable);
