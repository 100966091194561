import React, { useState, useEffect, useReducer } from "react";
import { useForm } from "react-hook-form";
import {
  createUser,
  loadUsers,
  deleteUser,
  isAdmin,
  updateUser,
} from "../../api/users";
import { connect } from "react-redux";
import "./users.scss";
import { ConflictError } from "utils/utils";

/**
 *
 * @param {Object} props
 * @param {(user: any) => Promise<any>} props.onSubmit
 */
function UserForm({ onSubmit }) {
  const { handleSubmit, register, errors, reset } = useForm();
  const os = values => {
    onSubmit(values).then(
      () => reset(),
      e => console.error(e)
    );
  };

  return (
    <form
      onSubmit={handleSubmit(os)}
      id="user-form"
      className="pure-form pure-form-stacked"
    >
      <fieldset>
        <legend>Add User</legend>

        <div className="pure-u-1 pure-u-md-1-3">
          <label htmlFor="email">
            <span>Email:</span>
          </label>
          <input
            id="email"
            name="email"
            className="pure-u-23-24"
            ref={register({
              required: "Required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "invalid email address",
              },
            })}
          />
          {errors.email && errors.email.message}
        </div>

        <div className="field">
          <label htmlFor="firstname">
            <span>First Name:</span>
          </label>
          <input
            id="firstname"
            name="first_name"
            className="pure-u-23-24"
            ref={register({
              required: "Required",
            })}
          />
          {errors.firstname && errors.firstname.message}
        </div>

        <div className="field">
          <label htmlFor="lastname">
            <span>Last Name:</span>
          </label>
          <input
            id="lastname"
            name="last_name"
            className="pure-u-23-24"
            ref={register({
              required: "Required",
            })}
          />
          {errors.lastname && errors.lastname.message}
        </div>
        <button type="submit" className="pure-button pure-button-primary">
          Submit
        </button>
      </fieldset>
    </form>
  );
}

/**
 *
 * @param {Object} props
 * @param {string} props.token
 * @param {Object} props.user
 */
function Users({ access_token, current_user }) {
  const [deletingUser, setDeletingUser] = useState(undefined);

  const [creatingUser, setCreatingUser] = useState(false);
  const [createUserError, setCreateUserError] = useState(undefined);

  const [{ getUsers, users }, usersDispatch] = useReducer(
    function userReducer(state, action) {
      switch (action.type) {
        case "get":
          return {
            ...state,
            getUsers: true,
          };
        case "set":
          return {
            ...state,
            getUsers: false,
            users: action.payload,
          };
        default:
          return state;
      }
    },
    {
      getUsers: true,
      users: [],
    }
  );

  /**
   *
   * @param {*} profile
   * @returns {Promise<*>}
   */
  async function onSubmit(profile) {
    if (access_token && profile) {
      setCreatingUser(true);
      return createUser(access_token, profile).then(
        user => {
          setCreatingUser(false);
          setCreateUserError(undefined);
          usersDispatch({
            type: "get",
          });
        },
        e => {
          setCreatingUser(false);
          if (e instanceof ConflictError) {
            setCreateUserError("User with that email already exists");
          } else {
            setCreateUserError(e.message);
          }

          throw e;
        }
      );
    }
  }

  async function deleteUserAndRefresh(userId) {
    setDeletingUser(userId);
    await deleteUser(access_token, userId);
    setDeletingUser(undefined);
    usersDispatch({
      type: "get",
    });
  }

  useEffect(() => {
    if (getUsers) {
      async function loadAndSetUsers() {
        const users = await loadUsers(access_token);
        usersDispatch({
          type: "set",
          payload: users,
        });
      }
      loadAndSetUsers();
    }
  }, [getUsers, access_token, usersDispatch]);

  async function onToggleAdmin(user, e) {
    try {
      const isAdminChecked = e.target.checked;

      // update is_staff and is_superuser optimistically ahead of API call to avoid click delay
      const selectedUserIndex = users.findIndex(u => u.id === user.id);
      if (selectedUserIndex > -1) {
        user.is_staff = isAdminChecked;
        user.is_superuser = isAdminChecked;
        let updatedUsers = [...users];
        updatedUsers[selectedUserIndex] = user;
        usersDispatch({
          type: "set",
          payload: updatedUsers,
        });
      }

      // update staff and superuser properties for user
      const updatedUser = await updateUser(access_token, {
        id: user.id,
        is_staff: isAdminChecked,
        is_superuser: isAdminChecked,
      });

      const existingUserIndex = users.findIndex(u => u.id === updatedUser.id);

      if (existingUserIndex > -1) {
        let updatedUsers = [...users];
        updatedUsers[existingUserIndex] = updatedUser;
        usersDispatch({
          type: "set",
          payload: updatedUsers,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <div id="user-form-container" className="container">
      <h1>Users</h1>
      <h2>Current Users</h2>
      <table className="pure-table">
        <thead>
          <tr>
            <th>Email</th>
            <th>Name</th>
            <th />
            <th>Admin</th>
          </tr>
        </thead>
        <tbody>
          {users &&
            users.map(user => (
              <tr className="text" key={user.id}>
                <td>{user.email}</td>
                <td>
                  {user.last_name}, {user.first_name}
                </td>
                <td>
                  {current_user.id !== user.id && (
                    <button
                      disabled={deletingUser === user.id}
                      onClick={() => {
                        deleteUserAndRefresh(user.id);
                      }}
                    >
                      Delete
                    </button>
                  )}
                </td>
                <td>
                  <input
                    type="checkbox"
                    checked={isAdmin(user)}
                    onChange={onToggleAdmin.bind(this, user)}
                    disabled={current_user.id === user.id}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <UserForm onSubmit={onSubmit} />

      {creatingUser && <p className="creating-text">Creating...</p>}
      {createUserError && <p className="error-text">{createUserError}</p>}
    </div>
  );
}

export default connect(state => {
  return {
    access_token: state.auth.auth.access_token,
    current_user: state.auth.user,
  };
})(Users);
