export const ACTIONS = {
  GO_TO_CHANGE_PASSWORD: "GO_TO_CHANGE_PASSWORD",
  ATTEMPT_CHANGE_PASSWORD: "ATTEMPT_CHANGE_PASSWORD",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILED: "CHANGE_PASSWORD_FAILED",
};

export function goToChangePassword() {
  return {
    type: ACTIONS.GO_TO_CHANGE_PASSWORD,
    payload: undefined,
    meta: {},
  };
}
export function attemptChangePassword() {
  return {
    type: ACTIONS.ATTEMPT_CHANGE_PASSWORD,
    payload: undefined,
    meta: {},
  };
}
export function changePasswordSuccess(message) {
  return {
    type: ACTIONS.CHANGE_PASSWORD_SUCCESS,
    payload: message,
    meta: {},
  };
}
export function changePasswordFailed(error) {
  return {
    type: ACTIONS.CHANGE_PASSWORD_FAILED,
    payload: error,
    meta: {},
  };
}
